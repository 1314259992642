interface Iobject {
  [key: string]: any
}

class LoadFiles {
    #timer = 30000

    #timeout: null | number = null

    #domen = 'https://api.psktitan.ru'

    #filesUrl = '/post/loadFiles.php'

    #downloadUrl = '/get/downloadFile.php'

    private static instance: LoadFiles

    constructor() {
      if (LoadFiles.instance) {
        return LoadFiles.instance
      }
      LoadFiles.instance = this
    }

    getDownloadLink(): string {
      return `${this.#domen}${this.#downloadUrl}`
    }

    async load(files: Iobject, nowNumFiles: number): Promise<Iobject> {
      return new Promise<Iobject>((resolve, reject) => {
        if (this.#timeout) clearTimeout(this.#timeout)
        this.#timeout = setTimeout(() => {
          throw new Error('timeout load')
        }, this.#timer)

        const formData = new FormData()
        for (let i = 0; i < files.length; i += 1) {
          const file = files[i]
          formData.append('files[]', file, file.name)
        }
        formData.append('num', String(nowNumFiles))

        fetch(`${this.#domen}${this.#filesUrl}`, {
          method: 'POST',
          body: formData,
        }).then((result: Iobject) => result.json()).then((result: Iobject) => {
          resolve(result)
        }).catch((error: Error) => {
          reject(error)
        })
      }).catch((error: Error) => {
        throw new Error(JSON.stringify(error))
      })
    }
}

export default LoadFiles
