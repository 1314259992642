
import {
  topics,
  canbanDataScheme,
  addingInit,
  gapAfter,
  dealItemNames,
  minimalFieldsList,
} from '@/conf/deals/dealsData'
import { defaultDeal } from '@/conf/deals/defaultDeal'
import { loadData, defaultAmountSearch } from '@/conf/company/companyData'
import routerAddEditDeal from '@/conf/routes/pages/addEditDeal'
import { cloneObj, getNowSecods } from '@/utils'
import { getUsers, openPopupDialog, getCanbanDeal, getInsertRequest } from '@/store/plugins/generalFunctions'
import { tables } from '@/conf/tables'
import { getHistoryReqToTable, getUnFlat } from '@/store/plugins/canban/functions'

import CrmApi from '@/services/crmApi'
import m from '@/mixins/m'
import requests from '@/mixins/requests'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Draggable from '@/components/atoms/Draggable.vue'
import CustomSelect from '@/components/atoms/CustomSelect.vue'
import CustomInput from '@/components/atoms/CustomInput.vue'
import CustomTextarea from '@/components/atoms/CustomTextarea.vue'
import AddEditCompanyContacts from '@/components/pages/AddEditCompanyContacts.vue'

const crmApi = new CrmApi()

interface Iobject {
  [key: string]: any
}

export default {
  name: 'AddEditDeal',
  mixins: [m, requests],
  components: {
    Header,
    Footer,
    Draggable,
    CustomSelect,
    CustomInput,
    AddEditCompanyContacts,
    CustomTextarea,
  },
  data(): Iobject {
    return {
      error: null,
      defaultDeal: cloneObj(defaultDeal),
      scheme: cloneObj(canbanDataScheme),
      addingInit,
      inputTimeout: null,
      inputTimer: {
        keyup: 2000,
        blur: 0,
      },
      valid: { status: false },
      allInvalid: false,
      mode: null,
      changes: new Set(),
      dealId: null,
      saveButton: {
        text: '',
        default: 'Сохранить',
        error: 'Некорректные данные',
        nochanges: 'Нет изменений',
        timeout: null,
        timer: 3000,
      },
      popup: false,
      loadData,
      showModes: {
        all: { name: 'все поля' },
        minimal: { name: 'основные поля', fields: minimalFieldsList },
      },
      showMode: null,
      edit: null,
      typeAddEdit: null,
    }
  },
  mounted(): void {
    this.edit = null
    this.typeAddEdit = null
    this.popup = false
    this.dealId = null
    this.saveButton.text = this.saveButton.default
    this.changes.clear()
    this.mode = null
    this.error = null
    this.allInvalid = false
    console.log('canbanDataScheme', canbanDataScheme)
  },
  beforeDestroy() {
    document.body.style.overflow = ''
  },
  watch: {
    popup(val: boolean): void {
      document.body.style.overflow = val === true ? 'hidden' : ''
      if (val === false && this.typeAddEdit === 'editPopup') {
        this.stopPopupSavedEdited()
      }
    },
    async getRouteData(): Promise<void> {
      if (this.getUser === null) {
        const isLogin = await this.isLogin()
        if (isLogin === false) return
      }
      if (this.$route.name === routerAddEditDeal[0].name) {
        this.mode = 'add'
        this.showMode = 'minimal'
        this.initialAddDeal()
      }
      if (this.$route.name === routerAddEditDeal[1].name) {
        this.mode = 'edit'
        this.showMode = 'all'
        this.initialEditDeal()
      }
    },
  },
  computed: {
    validOb(): Iobject {
      if (!this.defaultDeal.catId) return {}
      this.valid.status = true
      const d: Iobject = cloneObj(this.defaultDeal)
      this.validation(d, this.valid, this.allInvalid)
      return d
    },
    topics(): Iobject {
      return topics
    },
    gapAfter(): Iobject {
      return gapAfter
    },
  },
  methods: {
    setDefaultResponsibleAsAuthor(): void {
      this.defaultDeal['responsible.id'] = this.defaultDeal.author
    },
    backToDeal(): void {
      this.routeGo({ name: 'canbanDeal', params: { id: this.dealId } })
    },
    noSearch(item: string, choose: string): void {
      this.noSearchRequest(item, choose, this.scheme[item].list)
    },
    setNewCompanyContact(): void {
      this.edit = null
      this.typeAddEdit = 'addPopup'
      this.popup = true
    },
    editCompanyContact(...args: any): void {
      const { name } = args[0]
      const id = args[0].item[name]
      this.typeAddEdit = 'editPopup'
      this.edit = { name, id }
      this.popup = true
    },
    closePopup(): void {
      // openPopupDialog('Закрыть окно?').then((result: boolean) => { if (result) this.popup = false })
      this.popup = false
    },
    setShowMode(item: string): boolean {
      if (this.showMode === 'all') return true
      if (this.showModes.minimal.fields[item]) return true
      if (this.defaultDeal[item] && String(this.defaultDeal[item]).length > 0) return true
      return false
    },
    saveError(alert: Iobject): void {
      if (this.saveButton.timeout) clearTimeout(this.saveButton.timeout)
      this.saveButton.text = alert
      this.saveButton.timeout = setTimeout(() => {
        this.saveButton.text = this.saveButton.default
      }, this.saveButton.timer)
    },
    clickSaveDeal(deal: Iobject, validOb: Iobject): void {
      console.log('clickSaveDeal', this.defaultDeal)
      if (this.valid.status === false) {
        if (this.allInvalid === false) this.allInvalid = true
        this.changeDealData(deal, validOb)
        this.saveError(this.saveButton.error)
      } else {
        if (this.mode === 'add') {
          // openPopupDialog('Сохранить данные?').then((result: boolean) => {
          //   if (result !== true) return
          //   this.addDeal()
          // })
          this.addDeal()
        }
        if (this.mode === 'edit') {
          if (this.changes.size > 0) {
            // openPopupDialog('Сохранить данные?').then((result: boolean) => {
            //   if (result !== true) return
            //   this.editDeal()
            // })
            this.editDeal()
          } else {
            this.saveError(this.saveButton.nochanges)
          }
        }
      }
    },
    async editDeal(): Promise<void> {
      console.log('changes', this.changes)
      this.$store.dispatch('app/setLoader', { data: true })
      const change: Iobject[] = []
      const history: Iobject = []
      this.changes.forEach((key: string) => {
        if (dealItemNames[key]) history.push(dealItemNames[key])
        let d = this.defaultDeal[key]
        d = Array.isArray(d) ? JSON.stringify(d).replace(/null/g, '""') : d
        change.push({
          name: key,
          value: d === null ? '' : d,
        })
      })
      const historyPush = getHistoryReqToTable({
        text: `Изменения ${history.join(', ')}`,
        dealId: this.dealId,
        userId: this.getUser.data.id,
      })
      const changeRequest: Iobject = {
        actions: [
          {
            table: tables.canbanData,
            id: this.dealId,
            nameId: 'id',
            type: 'change',
            items: change,
          },
        ],
        callbacks: [
          {
            table: tables.canbanData,
            nameId: 'id',
            id: this.dealId,
            compare: '=',
          },
        ],
        datetime: getNowSecods(),
      }
      changeRequest.actions.push(historyPush)
      console.log('save', changeRequest)
      crmApi
        .sendAndGetData(changeRequest)
        .then((result: Iobject) => {
          console.log('changeRequest result', result)
          this.$store.dispatch('app/setCanbanDeal', {
            data: {
              datetime: getNowSecods(),
              data: getUnFlat(result.data[0][tables.canbanData])[0],
            },
          })
          // const answers = [
          //   { name: 'Продолжить редактирование', result: false },
          //   { name: 'Перейти к сделке', result: true },
          // ]
          // openPopupDialog('Данные сохранены', answers)
          //   .then((answer: boolean) => {
          //     if (answer === true) this.routeGo({ name: 'canbanDeal', params: { id: this.dealId } })
          //     this.changes.clear()
          //   })
          this.routeGo({ name: 'canbanDeal', params: { id: this.dealId } })
          this.changes.clear()
        })
        .catch((error: Iobject) => {
          console.warn('error changeRequest', error)
        })
        .then(() => {
          this.$store.dispatch('app/setLoader', { data: false })
        })
    },
    async addDeal(): Promise<void> {
      console.log('clickSaveDeal', this.defaultDeal)
      const insertRequest = getInsertRequest([this.defaultDeal], tables.canbanData)
      this.$store.dispatch('app/setLoader', { data: true })
      crmApi
        .sendAndGetData(insertRequest)
        .then((result: Iobject) => {
          console.log('insert canbanDeal result', result)
          try {
            const id = result.inserted[0][tables.canbanData]
            this.routeGo({ name: 'canbanDeal', params: { id } })
          } catch (err) {
            throw new Error(err as string)
          }
        })
        .catch((error: Error) => {
          console.warn('error insertCanbanDeal', JSON.stringify(error))
          const answers = [{ name: 'Ok', result: true }]
          openPopupDialog('Ошибка при сохранении сделки', answers)
        })
        .then(() => {
          this.$store.dispatch('app/setLoader', { data: false })
        })
    },
    changeDealData(deal: Iobject, validOb: Iobject, item: string | null = null, root: any, e = 'blur'): void {
      if (root !== undefined) this.changes.add(root)
      if (this.inputTimeout) clearTimeout(this.inputTimeout)
      this.inputTimeout = setTimeout(() => {
        if (!item) return
        if (this.scheme[item] && this.scheme[item].type === 'text') {
          if (e === 'blur' && deal[item] === null) deal[item] = ''
        }
        if (this.scheme[item] && this.scheme[item].type === 'tel') {
          if (e === 'blur' && validOb[item] === false) {
            if (deal[item] !== null) deal[item] = Number(deal[item])
            if (deal[item] === null) deal[item] = ''
          }
        }
      }, this.inputTimer[e])
    },
    initialEditDeal(): void {
      this.dealId = Number(this.$route.params.id)
      this.$store.dispatch('app/setLoader', { data: true })

      let numberResponses = 0
      const limitiResponses = 2

      getUsers()
        .then((result: boolean) => {
          console.log('getUsers result refresh', result)
          this.getUsers.data.forEach((user: Iobject) => {
            this.scheme['responsible.id'].list.push({
              name: `${user.firstName} ${user.lastName}`.trim(),
              value: user.id,
            })

            this.scheme.watchersData.list.push({
              name: `${user.firstName} ${user.lastName}`.trim(),
              value: user.id,
            })
          })
        })
        .catch((error: Error) => {
          console.warn('error initialEditDeal', JSON.stringify(error))
          this.error = true
        })
        .then(() => {
          numberResponses += 1
          this.removeLoader(numberResponses, limitiResponses)
        })

      getCanbanDeal(this.dealId)
        .then((result: boolean) => {
          console.log('getCanbanDeal result refresh', result)
          Object.keys(this.getCanbanDeal.data).forEach((key: string) => {
            if (defaultDeal[key] !== undefined) {
              this.defaultDeal[key] = cloneObj(this.getCanbanDeal.data[key])
            }
          })
          this.error = false
          return this.loadCompanyContactsFunc()
        })
        .catch((error: Error) => {
          console.warn('error initialEditDeal', JSON.stringify(error))
          this.error = true
        })
        .then(() => {
          numberResponses += 1
          this.removeLoader(numberResponses, limitiResponses)
        })
    },
    async loadCompanyContactsFunc(): Promise<boolean> {
      const addedIds: Iobject = {}
      Object.keys(this.loadData).forEach((key: string) => {
        addedIds[key] = []
        this.defaultDeal[key].forEach((item: Iobject) => {
          if (item[this.loadData[key].scheme]) addedIds[key].push(item[this.loadData[key].scheme])
        })
      })
      console.log('addedIds', addedIds)
      if (this.scheme[this.loadData['deal.company'].scheme].list.length > 0) return false

      let resultContacts: Iobject | null = null
      return this.loadCompanyContacts('contacts', addedIds.contacts, defaultAmountSearch)
        .then((result: Iobject) => {
          resultContacts = result.result
          const companiesId = Array.from(new Set(addedIds['deal.company'].concat(result.companiesOfConracts)))
          return this.loadCompanyContacts('deal.company', companiesId, defaultAmountSearch)
        })
        .then((result: Iobject) => {
          this.scheme[this.loadData['deal.company'].scheme].list = result.data
          if (resultContacts === null) return false
          const company: Iobject = {}
          result.data.forEach((i: Iobject) => {
            company[i.value] = i.name
          })
          const ids: Iobject = {}
          resultContacts.data.forEach((d: Iobject) => {
            d.contacts.forEach((i: Iobject) => {
              if (ids[i.id]) return
              ids[i.id] = true
              this.scheme[this.loadData.contacts.scheme].list.push(this.contactsScheme(i, company))
            })
          })
          return true
        })
        .catch((error: any) => {
          throw new Error(error as string)
        })
    },
    initialAddDeal(): void {
      this.$store.dispatch('app/setLoader', { data: true })
      getUsers()
        .then((result: boolean) => {
          console.log('getUsers result refresh', result)
          this.defaultDeal = cloneObj(defaultDeal)
          this.defaultDeal.author = this.getUser.data.id
          this.getUsers.data.forEach((user: Iobject) => {
            this.scheme['responsible.id'].list.push({
              name: `${user.firstName} ${user.lastName}`.trim(),
              value: user.id,
            })
          })
          this.setDefaultResponsibleAsAuthor()

          this.error = false
        })
        .catch((error: Error) => {
          console.warn('error initialAddDeal', JSON.stringify(error))
          this.error = true
        })
        .then(() => {
          this.$store.dispatch('app/setLoader', { data: false })
        })
    },
    stopPopupSavedEdited(...args: any): void {
      console.log('args stopPopupSavedEdited', args)
      this.scheme[this.loadData['deal.company'].scheme].list = []
      this.loadCompanyContactsFunc()
      this.popup = false
    },
    stopPopupSavedAdded(...args: any): void {
      const loadDataByTable: Iobject = {}
      Object.keys(this.loadData).forEach((i: string) => {
        loadDataByTable[this.loadData[i].table] = { scheme: this.loadData[i].scheme, field: i }
      })
      console.log('args stopPopupSavedAdded', args)
      const inserts = args[0]
      inserts.forEach((i: Iobject) => {
        const table = Object.keys(i)[0]
        if (loadDataByTable[table]) {
          const insert: Iobject = { ord: 0 }
          insert[loadDataByTable[table].scheme] = i[table]
          this.defaultDeal[loadDataByTable[table].field].push(insert)
          this.changes.add(loadDataByTable[table].field)
        }
      })

      this.scheme[this.loadData['deal.company'].scheme].list = []
      this.loadCompanyContactsFunc()
      this.popup = false
    },
    dragAnyChange(...args: any): void {
      this.changes.add(args[0])
    },
    getFieldType(item: string): string {
      if (!this.scheme[item]) return ''
      if (this.scheme[item] && this.addingInit[item]) return 'drag'
      if (this.scheme[item].list !== null) return 'select'
      if (this.scheme[item].list === null && this.scheme[item].type !== 'textarea') return 'input'
      if (this.scheme[item].list === null && this.scheme[item].type === 'textarea') return 'textarea'
      return 'input'
    },
  },
} as Iobject
