
import store from '@/store'
import m from '@/mixins/m'
import cookie from '@/mixins/cookie'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'Header',
  mixins: [m, cookie],
  components: {},
  data(): Iobject {
    return {}
  },
  computed: {},
  methods: {
    showSearch(): void {
      store.dispatch('app/setSearchShow', { data: true })

      setTimeout(() => {
        const search: HTMLElement | null = document.getElementById('search_input')
        if (!search) return
        search.focus()
      }, 0)
    },
  },
}
