import {
  purposes,
  roofs,
  materials,
  regions,
  sources,
  stageTypes,
  responsibles,
  gateTypes,
  craneWeights,
  statuses,
  addCompany,
  addContacts,
  addWatchers,
} from '@/conf/deals/selects'

interface Iobject {
  [key: string]: any
}

export const addText: Iobject = {
  'deal.company': 'Прикрепить',
  contacts: 'Прикрепить',
  default: 'Добавить',
}

export const addPlus: Iobject = ['deal.company', 'contacts']

export const topics: Iobject = [{ name: 'Общая информация' }]

export const canbanDataScheme: Iobject = {
  // Корневые характеристики
  'deal.description': {
    item: 'deal.description',
    name: 'Описание сделки',
    disabled: {
      change: {
        stage: [0, 1, 2, 3, 4, 5, 6, 7],
      },
    },
    type: 'textarea',
    'min-length': 3,
    'max-length': 255,
    simbols: 'all',
    list: null,
  },
  'deal.name': {
    item: 'deal.name',
    name: 'Название изделия *',
    changeStage: [0, 1, 2, 3, 4],
    type: 'text',
    'min-length': 3,
    'max-length': 255,
    simbols: 'all',
    list: null,
    saveValidation: true,
  },
  'deal.purpose': {
    item: 'deal.purpose',
    name: 'Тип здания',
    changeStage: [0, 1, 2, 3, 4],
    type: 'select',
    list: purposes,
  },
  'deal.roof': {
    item: 'deal.roof',
    name: 'Тип крыши',
    changeStage: [0, 1, 2, 3, 4],
    type: 'select',
    list: roofs,
  },
  'deal.size.l': {
    item: 'deal.size.l',
    name: 'Длина',
    changeStage: [0, 1, 2, 3, 4],
    type: 'tel',
    'min-value': 3,
    'max-value': 200,
    division: 0.1,
    simbols: 'digits,point',
    list: null,
    units: 'м',
  },
  'deal.size.w': {
    item: 'deal.size.w',
    name: 'Ширина',
    changeStage: [0, 1, 2, 3, 4],
    type: 'tel',
    'min-value': 3,
    'max-value': 200,
    division: 0.1,
    simbols: 'digits,point',
    list: null,
    units: 'м',
  },
  'deal.size.h': {
    item: 'deal.size.h',
    name: 'Полезная высота',
    changeStage: [0, 1, 2, 3, 4],
    type: 'tel',
    'min-value': 2,
    'max-value': 20,
    division: 0.1,
    simbols: 'digits,point',
    list: null,
    units: 'м',
  },
  'deal.material': {
    item: 'deal.material',
    name: 'Материал',
    changeStage: [0, 1, 2, 3, 4],
    type: 'select',
    list: materials,
    units: null,
  },
  'deal.region': {
    item: 'deal.region',
    changeStage: [0, 1, 2, 3, 4],
    name: 'Регион',
    type: 'select',
    list: regions,
    units: null,
  },
  'deal.city': {
    item: 'city',
    name: 'Город доставки',
    changeStage: [0, 1, 2, 3, 4],
    type: 'text',
    'min-length': 3,
    'max-length': 255,
    simbols: 'all',
    list: null,
    units: null,
  },
  'deal.price': {
    item: 'deal.price',
    name: 'Общая стоимость',
    changeStage: [0, 1, 2, 3, 4],
    type: 'tel',
    'min-value': 1000,
    'max-value': 1000000000,
    division: 0.01,
    simbols: 'digits,point',
    mask: 'price',
    list: null,
    units: 'р',
  },
  'deal.priceParts.production': {
    item: 'deal.priceParts.production',
    name: 'Стоимость производства',
    changeStage: [0, 1, 2, 3, 4],
    type: 'tel',
    'min-value': 1000,
    'max-value': 1000000000,
    division: 0.01,
    simbols: 'digits,point',
    mask: 'price',
    list: null,
    units: 'р',
  },
  'deal.priceParts.delivery': {
    item: 'deal.priceParts.delivery',
    name: 'Стоимость доставки',
    changeStage: [0, 1, 2, 3, 4],
    type: 'tel',
    'min-value': 1000,
    'max-value': 1000000000,
    division: 0.01,
    simbols: 'digits,point',
    mask: 'price',
    list: null,
    units: 'р',
  },
  'deal.priceParts.installation': {
    item: 'deal.priceParts.installation',
    name: 'Стоимость монтажа',
    changeStage: [0, 1, 2, 3, 4],
    type: 'tel',
    'min-value': 1000,
    'max-value': 1000000000,
    division: 0.01,
    simbols: 'digits,point',
    mask: 'price',
    list: null,
    units: 'р',
  },
  'deal.adress': {
    item: 'deal.adress',
    name: 'Адрес доставки',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'text',
    'min-length': 3,
    'max-length': 255,
    simbols: 'all',
    list: null,
  },
  'deal.info': {
    item: 'deal.info',
    name: 'Информация о сделке',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'textarea',
    'min-length': 0,
    'max-length': 255,
    simbols: 'all',
    list: null,
    novalid: false,
  },
  'deal.source': {
    item: 'deal.source',
    name: 'Источник сделки',
    changeStage: [0, 1, 2],
    type: 'select',
    list: sources,
  },
  'stage.type': {
    item: 'stage.type',
    name: 'Стадия',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'select',
    list: stageTypes,
    saveValidation: true,
  },
  status: {
    item: 'status',
    name: 'Статус',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'select',
    list: statuses,
  },
  'responsible.id': {
    item: 'responsible.id',
    name: 'Ответственный',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'select',
    list: responsibles,
  },

  // Корневые характеристики с массивами данных внутри
  'deal.company': {
    item: 'deal.company',
    name: 'Компания *',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'array',
    cusomClick: { text: 'Редактировать компанию', emit: 'addCompany' },
  },
  contacts: {
    item: 'contacts',
    name: 'Контакты *',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'array',
    cusomClick: { text: 'Редактировать контакт', emit: 'addContacts' },
    saveValidation: true,
  },
  'deal.additions.windows': {
    item: 'deal.additions.windows',
    name: 'Окна',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'array',
  },
  'deal.additions.doors': {
    item: 'deal.additions.doors',
    name: 'Наружные двери',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'array',
  },
  'deal.additions.gates': {
    item: 'deal.additions.gates',
    name: 'Ворота',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'array',
  },
  'deal.additions.cranes': {
    item: 'deal.additions.cranes',
    name: 'Кран-балка',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'array',
  },
  watchers: {
    item: 'watchers',
    name: 'Наблюдатели',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'array',
  },

  // contacts company
  firstName: { name: 'Имя' },
  middleName: { name: 'Отчество' },
  lastName: { name: 'Фамилия' },
  position: { name: 'Должность' },
  department: { name: 'Отдел' },
  companyName: { name: 'Название компании' },
  companyType: { name: 'Форма собственности' },
  company: { name: 'Компания контакта' },

  phone: {
    item: 'phone',
    name: 'Телефон',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'tel',
    'min-value': 10000000000,
    'max-value': 100000000000,
    division: 1,
    simbols: 'digits,point',
    list: null,
    units: '',
  },

  windowW: {
    item: 'windowW',
    name: 'Ширина окна',
    changeStage: [0, 1, 2, 3],
    type: 'tel',
    'min-value': 0.5,
    'max-value': 2,
    division: 0.1,
    simbols: 'digits,point',
    list: null,
    units: 'м',
  },
  windowH: {
    item: 'windowH',
    name: 'Высота окна',
    changeStage: [0, 1, 2, 3],
    type: 'tel',
    'min-value': 0.5,
    'max-value': 2,
    division: 0.1,
    simbols: 'digits,point',
    list: null,
    units: 'м',
  },
  windowAmount: {
    item: 'windowAmount',
    name: 'Количество окон',
    changeStage: [0, 1, 2, 3],
    type: 'tel',
    'min-value': 1,
    'max-value': 200,
    division: 1,
    simbols: 'digits,point',
    list: null,
    units: 'шт',
  },

  gateType: {
    item: 'gateType',
    name: 'Тип ворот',
    changeStage: [0, 1, 2, 3],
    type: 'select',
    list: gateTypes,
    units: '',
  },
  gateW: {
    item: 'gateW',
    name: 'Ширина ворот',
    changeStage: [0, 1, 2, 3],
    type: 'tel',
    'min-value': 2,
    'max-value': 10,
    division: 0.5,
    simbols: 'digits,point',
    list: null,
    units: 'м',
  },
  gateH: {
    item: 'gateH',
    name: 'Высота ворот',
    changeStage: [0, 1, 2, 3],
    type: 'tel',
    'min-value': 2,
    'max-value': 6,
    division: 0.5,
    simbols: 'digits,point',
    list: null,
    units: 'м',
  },
  gateAmount: {
    item: 'gateAmount',
    name: 'Количество ворот',
    changeStage: [0, 1, 2, 3],
    type: 'tel',
    'min-value': 1,
    'max-value': 10,
    division: 1,
    simbols: 'digits,point',
    list: null,
    units: 'шт',
  },

  doorW: {
    item: 'doorW',
    name: 'Ширина двери',
    changeStage: [0, 1, 2, 3],
    type: 'tel',
    'min-value': 0.8,
    'max-value': 2,
    division: 0.1,
    simbols: 'digits,point',
    list: null,
    units: 'м',
  },
  doorH: {
    item: 'doorH',
    name: 'Высота двери',
    changeStage: [0, 1, 2, 3],
    type: 'tel',
    'min-value': 2,
    'max-value': 2.5,
    division: 0.1,
    simbols: 'digits,point',
    list: null,
    units: 'м',
  },
  doorAmount: {
    item: 'doorAmount',
    name: 'Количество дверей',
    changeStage: [0, 1, 2, 3],
    type: 'tel',
    'min-value': 1,
    'max-value': 200,
    division: 1,
    simbols: 'digits,point',
    list: null,
    units: 'шт',
  },

  craneWeight: {
    item: 'craneWeight',
    name: 'Грузоподъемность',
    changeStage: [0, 1, 2, 3],
    type: 'select',
    list: craneWeights,
    units: '',
  },

  watchersData: {
    item: 'watchers',
    name: 'Наблюдатель',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'select',
    list: addWatchers,
    units: '',
  },

  addCompany: {
    item: 'addCompany',
    name: 'Компания',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'select',
    list: addCompany,
    placeholder: 'Выберите компанию',
    requestCallback: true,
  },
  addContacts: {
    item: 'addContacts',
    name: 'Контакт',
    changeStage: [0, 1, 2, 3, 4, 5, 6, 7],
    type: 'select',
    list: addContacts,
    placeholder: 'Выберите контакт',
    requestCallback: true,
    saveValidation: true,
  },
}

export const phrases: Iobject = {
  firstName: 'Имя',
  middleName: 'Отчество',
  lastName: 'Фамилия',
  positionContact: 'Должность',
  infoContact: 'Информация',
  statusContact: 'Статсуc',

  contactName: 'Название',

  windowName: 'Название',
  windowW: 'Ширина окна',
  windowH: 'Высота окна',
  windowAmount: 'Количество окон',

  gateName: 'Название ворот',
  gateType: 'Тип ворот',
  gateW: 'Ширина ворот',
  gateH: 'Высота ворот',
  gateAmount: 'Количество ворот',

  doorName: 'Название двери',
  doorW: 'Ширина двери',
  doorH: 'Высота двери',
  doorAmount: 'Количество дверей',

  craneName: 'Название',
  craneWeight: 'Грузоподъемность',

  contacts: 'Контакт',
  company: 'Компанию',
  'deal.company': 'Компанию',

  'deal.additions.windows': 'Окно',
  'deal.additions.doors': 'Дверь',
  'deal.additions.gates': 'Ворота',
  'deal.additions.cranes': 'Кран-балку',

  phones: 'Телефон',
  emails: 'Email',
  sites: 'Сайт',
  messengers: 'Месенджер',

  phone: 'Телефон',
  email: 'Email',
  site: 'Сайт',
  info: 'Информация',
  messenger: 'Месенджер',
  addContacts: 'Контакт',
  addCompany: 'Компания',
}

export const addingInit: Iobject = {
  contacts: { addContacts: null },
  phones: { phone: null, info: null },
  emails: { email: null, info: null },
  sites: { site: null, info: null },
  messengers: { messenger: null, info: null },
  'deal.company': { addCompany: null },
  'deal.additions.windows': {
    windowName: null,
    windowW: null,
    windowH: null,
    windowAmount: null,
  },
  'deal.additions.doors': {
    doorName: null,
    doorW: null,
    doorH: null,
    doorAmount: null,
  },
  'deal.additions.gates': {
    gateName: null,
    gateType: null,
    gateW: null,
    gateH: null,
    gateAmount: null,
  },
  'deal.additions.cranes': {
    craneName: null,
    craneWeight: null,
  },
  watchers: {
    watchersData: null,
  },
}

export const gapAfter: Iobject = {
  'deal.description': true,
  'deal.material': true,
  'deal.additions.cranes': true,
  'deal.adress': true,
  'deal.priceParts.installation': true,
  contacts: true,
}

export const dealItemNames: Iobject = {
  'deal.name': 'Название сделки',
  'deal.description': 'Описание сделки',

  'deal.purpose': 'Тип здания',
  'deal.roof': 'Тип крыши',
  'deal.size.l': 'Длина здания',
  'deal.size.w': 'Ширина здания',
  'deal.size.h': 'Высота здания',
  'deal.material': 'Материал',

  'deal.additions.windows': 'Окна',
  'deal.additions.doors': 'Двери',
  'deal.additions.gates': 'Ворота',
  'deal.additions.cranes': 'Кран-балка',

  'deal.region': 'Регион и город',
  'deal.city': 'Город поставки',
  'deal.adress': 'Адрес',

  'deal.price': 'Общая цена',
  'deal.priceParts.production': 'Цена производства',
  'deal.priceParts.delivery': 'Цена доставки',
  'deal.priceParts.installation': 'Цена монтажа',

  'deal.company': 'Контакты компании',
  contacts: 'Контакты',

  'deal.source': 'Источник сделки',
  'stage.type': 'Стадия',
  'responsible.id': 'Ответственный',
  'deal.info': 'Дополнительная информация',
  status: 'Статус сделки',

  active: 'Активность сделки',
}

export const minimalFieldsList: Iobject = {
  'deal.name': true,
  'deal.company': true,
  contacts: true,
}

export const windRatio: Iobject = {
  1: 17,
  2: 23,
  3: 30,
  4: 38,
  5: 48,
  6: 60,
  7: 73,
  8: 85,
}

export const snowRatio: Iobject = {
  1: 80,
  2: 120,
  3: 180,
  4: 240,
  5: 320,
  6: 400,
  7: 480,
  8: 560,
}
