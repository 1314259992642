import { tables } from '@/conf/tables'

interface Iobject {
    [key: string]: any
  }

export const addingInit: Iobject = {
  company: {
    id: -1,
    companyName: '',
    companyType: '',
    info: '',
    phones: [],
    emails: [],
    sites: [],
    datetime: null,
  },
  contacts: {
    id: -1,
    firstName: '',
    middleName: '',
    lastName: '',
    company: '',
    position: '',
    department: '',
    info: '',
    phones: [],
    emails: [],
    messengers: [],
    datetime: null,
  },
  phones: { phone: '', info: '' },
  emails: { email: '', info: '' },
  sites: { site: '', info: '' },
  messengers: { messenger: '', info: '' },
}

export const board: Iobject = {
  company: { 
    data: [addingInit.company], 
  },
  contacts: { 
    data: [addingInit.contacts], 
  },
}

export const companyContactsPhrases: Iobject = {
  companyType: 'Форма собственности',
  companyName: 'Название компании',
  firstName: 'Имя',
  middleName: 'Отчество',
  lastName: 'Фамилия',
  position: 'Должность',
  department: 'Отдел',
  info: 'Дополнительная информация',
  company: 'Компания',
  initDatetime: null,
  datetime: null,
}

export const loadData = {
  'deal.company': {
    table: tables.company,
    scheme: 'addCompany',
    noSearchFields: ['companyName'],
    data: [],
    ids: [],
  },
  contacts: {
    table: tables.contacts,
    scheme: 'addContacts',
    noSearchFields: ['firstName', 'lastName', 'middleName'],
    data: [],
    ids: [],
  },
}

export const allVisibleStatusDefault = { company: [], contacts: [] }

export const fullVisibleItemsDefault = {
  company: ['companyName'],
  contacts: ['firstName', 'company', 'phones'],
}

export const defaultAmountSearch = 10
