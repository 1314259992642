
import LoadFiles from '@/services/loadFiles'
import m from '@/mixins/m'
import { openPopupDialog } from '@/store/plugins/generalFunctions'

const loadFiles = new LoadFiles()

interface Iobject {
  [key: string]: any
}

export default {
  name: 'Enter',
  mixins: [m],
  props: {
    enter: {
      required: true,
    },
  },
  data(): Iobject {
    return {
      dragActive: false,
      limitTextLength: 15,
      maxFilesAmount: 3,
    }
  },
  methods: {
    getFileName(text: string): string {
      if (!text) return ''
      const extPos = text.lastIndexOf('.')
      const name = text.substr(0, extPos)
      const extention = text.substr(extPos + 1)
      return `${this.limitText({ text: name, length: this.limitTextLength })}.${extention}`
    },
    handleFiles(e: Iobject): void {
      this.loadFiles(e.target.files)
    },
    dragedFiles(e: Iobject): void {
      this.loadFiles(e.dataTransfer.files)
    },
    loadFiles(files: Iobject): void {
      this.$store.dispatch('app/setLoader', { data: true })
      loadFiles.load(files, this.enter?.files?.length || 0)
        .then((result: Iobject) => {
          if (result.status !== 'ok') throw new Error(JSON.stringify(result))
          result.data.forEach((file: Iobject) => {
            if (file.upload.status !== true) throw new Error(JSON.stringify(result))
            this.enter.files.push(file.data)
          })
        })
        .catch((error: Error) => {
          openPopupDialog('Ошибка при закачке файлов', [{ name: 'Ok', result: true }])
          console.warn('error loadFiles load', error)
        })
        .then(() => {
          this.$store.dispatch('app/setLoader', { data: false })
        })
    },
  },
} as Iobject
