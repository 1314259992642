import store from '@/store'
import { getCookie } from '@/store/plugins/auth/getCookie'

export const getDevGetData = () => {
  if (store.getters['app/getDev']) {
    return `?dev-data=${getCookie('user')}`
  }

  return ''
}
