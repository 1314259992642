

interface Iobject {
  [key: string]: any
}

export default {
  name: 'DatePicker',
  props: {
    datetime: {
      default: null,
    },
  },
  data(): Iobject {
    return {
      days: [...Array(42).keys()],
      months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 
        'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      year: null,
      month: null,

      startWeekDay: null,
      daysPrevMonth: null,
      daysThisMonth: null,
      now: { y: null, m: null, d: null },
    }
  },
  mounted(): void {
    const d = new Date()
    this.now.d = d.getDate()
    this.now.m = d.getMonth()
    this.now.y = d.getFullYear()
  },
  watch: {
    date(): void {
      this.year = null
      this.month = null
    },
  },
  computed: {
    date(): string {
      let d = new Date()
      if (this.datetime) d = new Date(this.datetime * 1000)

      const year = d.getFullYear()
      let month: any = d.getMonth() + 1
      let day: any = d.getDate()

      if (month < 10) month = `0${month}`
      if (day < 10) day = `0${day}`
      return `${day}.${month}.${year}`
    },
    getDays(): Iobject[] {
      return this.days.map((day: number) => this.getDay(day))
    },
    targetDate(): Iobject {
      if (this.date === null) {
        return {
          year: this.now.y,
          month: this.now.m + 1,
          day: this.now.d,
        }
      }
      const t = this.date.split('.')
      return {
        year: Number(t[2]),
        month: Number(t[1]),
        day: Number(t[0]),
      }
    },
    seeDate(): Iobject {
      const d = { 
        year: this.year,
        month: this.month,
      }
      if (d.year === null) {
        d.year = this.targetDate.year
        d.month = this.targetDate.month
      }
      let thisMonth = new Date(d.year, d.month - 1, 1, 0, 0, 0, 0)

      let m = d.month - 1
      let y = d.year
      if (m < 1) {
        m = 12
        y -= 1
      }
      this.startWeekDay = thisMonth.getDay() - 2
      thisMonth = new Date(d.year, d.month, 0, 0, 0, 0, 0)
      this.daysThisMonth = thisMonth.getDate()

      const prevMonth = new Date(y, m, 0, 0, 0, 0, 0)
      this.daysPrevMonth = prevMonth.getDate()
      return d
    },
    isYearMonth(): boolean {
      const isYear = this.targetDate.year === this.seeDate.year
      return isYear && this.targetDate.month === this.seeDate.month
    },
  },
  methods: {
    goNow(): void {
      this.year = this.now.y
      this.month = this.now.m + 1
    },
    changePickData(d: Iobject): void {
      if (d.active === false) return
      let datetime = new Date(d.year, d.month - 1, d.day, 0, 0, 0, 0).getTime()
      datetime = Math.round(datetime / 1000)
      this.$emit('changePickData', datetime)
    },
    changeYear(add: number): void {
      if (this.year === null) {
        this.year = this.targetDate.year
        this.month = this.targetDate.month
      }
      this.year += add
    },
    changeMonth(add: number): void {
      if (this.month === null) {
        this.year = this.targetDate.year
        this.month = this.targetDate.month
      }
      if (this.month + add > 12) {
        this.month = 1
        this.year += 1
        return
      }
      if (this.month + add < 1) {
        this.month = 12
        this.year -= 1
        return
      }
      this.month += add
    },
    getDay(d: number): Iobject {
      const thisDay = this.date !== null && this.isYearMonth && this.targetDate.day === Number(d - this.startWeekDay)

      const s = this.seeDate
      const n = this.now
      let active = s.year > n.y || (s.year === n.y && s.month > n.m + 1)
      if (!active) {
        active = s.year === n.y && s.month === n.m + 1 && d - this.startWeekDay >= n.d
      }

      const thisDate = s.year === n.y && s.month === n.m + 1 && d - this.startWeekDay === n.d
      
      if (d - this.startWeekDay <= 0) {
        let month = this.seeDate.month - 1
        let year = this.seeDate.year
        if (month < 1) {
          month = 12
          year -= 1
        }
        return {
          active,
          day: this.daysPrevMonth + (d - this.startWeekDay),
          month,
          year,
          thisDay: false,
          thisDate,
        }
      }
      if (d - this.startWeekDay > this.daysThisMonth) {
        let month = this.seeDate.month + 1
        let year = this.seeDate.year
        if (month > 12) {
          month = 1
          year += 1
        }
        return {
          active,
          day: d - this.startWeekDay - this.daysThisMonth,
          month,
          year,
          thisDay: false,
          thisDate,
        }
      }
      return {
        active,
        day: d - this.startWeekDay,
        month: this.seeDate.month,
        year: this.seeDate.year,
        thisDay,
        thisDate,
      }
    },
  },
} as Iobject
