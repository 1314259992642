import { 
  CompanyContactsTypes,
  CompanyContactsTypesSingle,
} from '@/conf/routes/pages/addCompanyContacts'

interface Iobject {
  [key: string]: any
}

export const sortFieldsArray: Iobject = ['phones', 'emails']

export const sortFieldsArrayItems: Iobject = {
  phones: 'phone',
  emails: 'email',
}

export const linkByType: Iobject = {
  [CompanyContactsTypes.Contacts]: CompanyContactsTypesSingle.Contact,
  [CompanyContactsTypes.Companies]: CompanyContactsTypesSingle.Company,
}

export const sortFields: Iobject = {
  contacts: [
    {
      name: 'Имя',
      item: 'firstName',
      value: '',
    },
    {
      name: 'Отчество',
      item: 'middleName',
      value: '',
    },
    {
      name: 'Фамилия',
      item: 'lastName',
      value: '',
    },
    {
      name: 'Компания',
      item: 'company',
      value: '',
    },
    {
      name: 'Телефон',
      item: 'phones',
      value: '',

    },
    {
      name: 'Email',
      item: 'emails',
      value: '',
    },
  ],
  companies: [
    {
      name: 'Название',
      item: 'companyName',
      value: '',
    },
    {
      name: 'Телефон',
      item: 'phones',
      value: '',

    },
    {
      name: 'Email',
      item: 'emails',
      value: '',
    },
  ],
}
