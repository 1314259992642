import Vue from 'vue'
import VueRouter from 'vue-router'
import VueTheMask from 'vue-the-mask'

import store from '@/store'
import App from '@/App.vue'
import routes from './routes/index'

declare const window: Window & typeof globalThis & { vm: any }

Vue.use(VueRouter)
Vue.use(VueTheMask)

Vue.config.productionTip = false
Vue.prototype.debug = {
  mode: document.domain === 'localhost' ? 'dev' : 'prod',
}

// (function cons(mode) {
//   const logArr: string[] = ['log']
//   logArr.forEach((type: string) => {
//     if (type === 'log') {
//       const l = console.log
//       console.log = (...args: string[]) => {
//         if (mode === 'dev-') l.apply(console, args)
//       }
//     }
//   })
// }(Vue.prototype.debug.mode))

console.log('routes', routes)
console.log('mode', Vue.prototype.debug.mode)

const router = new VueRouter({
  mode: 'history',
  routes,
})

window.vm = new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

store.dispatch('app/setState', { data: 'INITIAL' })

if (window.vm.debug.mode !== 'prod') {
  store.dispatch('app/setDev', {
    data: true,
  })

  // store.dispatch('app/setDev', {
  //   data: {
  //     datetime: 1632664341,
  //     data: {
  //       id: 1,
  //       firstName: 'Иван',
  //       middleName: 'Иванович',
  //       lastName: 'Иванов',
  //       status: 0,
  //       active: 1,
  //     },
  //   },
  // })
}

// getDealByContactOrCompany([289, 75], 'contacts')
// getDealByContactOrCompany([44], 'deal.company')

/// ////////////// для тестов

store.dispatch('app/setDatetimeCorrect', {
  data: {
    datetime: 1632664341,
    data: 0,
  },
})
