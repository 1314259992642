
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CanbanFilter, { SortByUser } from '@/components/pages/CanbanFilter.vue'
import Statistics from './Statictics.vue'
import m from '@/mixins/m'
import CrmApi from '@/services/crmApi'
import { canbanStages, catId } from '@/conf/canbanData'
import { getNowSecods } from '@/utils'
import { getUnFlat } from '@/store/plugins/canban/functions'

interface Iobject {
  [key: string]: any
}

const crmApi = new CrmApi()

const MONTH = 60 * 60 * 24 * 30

export default {
  name: 'StatisticsWrap',
  mixins: [m],
  components: {
    Header,
    Footer,
    CanbanFilter,
    Statistics,
  },
  async mounted() {
    await this.loadCanbanData()
  },
  data(): any {
    return {
      sortByUser: {},
      error: false,
    }
  },

  computed: {
    getSortedDeals() {
      if (!this.getCanbanData) return []

      const now = Math.round(Number(new Date()) / 1000)

      return this.getCanbanData.data
        .filter((item: any) => {
          let isMatch = true
          Object.keys(this.sortByUser).forEach((key: any) => {
            if (!isMatch) return
            if (this.sortByUser[key] === null) return

            if (key === 'period') {
              if (item.datetime < now - this.sortByUser[key] * MONTH) isMatch = false
            } else if (item[key] !== this.sortByUser[key]) isMatch = false
          })

          return isMatch
        })
        .reverse()
    },
  },
  methods: {
    setSortByUser(sortByUser: SortByUser) {
      this.sortByUser = sortByUser
    },
    async loadCanbanData() {
      this.$store.dispatch('app/setLoader', { data: true })

      const datetime = getNowSecods()

      return new Promise(resolve => {
        crmApi
          .getCanbanData(catId)
          .then((result: Iobject) => {
            console.log('result getCanbanData', result)
            this.$store.dispatch('app/setCanbanData', {
              data: {
                datetime,
                data: getUnFlat(result.data.deals),
              },
            })
          })
          .catch((error: Iobject) => {
            console.warn('error crmApi.getCanbanData', error)
            this.error = true
          })
          .then(() => {
            this.$store.dispatch('app/setLoader', { data: false })

            resolve(true)
          })
      })
    },
  },
} as Iobject
