import CookiesApi from '@/services/cookiesApi'

const cookiesApi = new CookiesApi()

interface Iobject {
  [key: string]: any
}

const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`
  const parts: string[] = value.split(`; ${name}=`)
  if (parts.length === 2) {
    const popParts = parts.pop()
    if (popParts) return popParts.split(';').shift()
  }
  return undefined
}

const userCookieName = 'user'
const userPeriodCookieName = 'user-period'

export async function loginOnStart(): Promise<Iobject | null> {
  const c = getCookie(userCookieName)
  let cookieResult = null
  if (c) cookieResult = await cookiesApi.sendCookie(c)
  if (cookieResult) cookiesApi.resolveCookie(cookieResult)
  return cookieResult
}

export {
  getCookie,
  userCookieName,
  userPeriodCookieName,
}
