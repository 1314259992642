
import draggable from 'vuedraggable'
import CustomSelect from '@/components/atoms/CustomSelect.vue'
import { addingInit, phrases, addText, addPlus } from '@/conf/deals/dealsData'
import { getNowSecods, cloneObj } from '@/utils'
import m from '@/mixins/m'
import { openPopupDialog } from '@/store/plugins/generalFunctions'
import CustomInput from '@/components/atoms/CustomInput.vue'
import CustomTextarea from '@/components/atoms/CustomTextarea.vue'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'Draggable',
  mixins: [m],
  components: {
    draggable,
    CustomSelect,
    CustomInput,
    CustomTextarea,
  },
  props: {
    itemParent: { required: true },
    itemName: { required: true },
    root: { required: true },
    disabled: { required: true },
    scheme: { required: true },
    mode: { default: null },
    validOb: { default: false },
    cusomClick: { default: false },
  },
  data() {
    return {
      enabled: { status: true },
      contacts: '',
      dragging: false,
      adding: null,
      addingInit,
      addText,
      addPlus,
    }
  },
  mounted(): void {
    this.adding = cloneObj(this.addingInit)
  },
  watch: {
    popup(val: null | Iobject): void {
      this.enabled.status = val === null
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 400,
        disabled: true,
        ghostClass: 'ghost',
      }
    },
    p() {
      return phrases
    },
  },
  methods: {
    change_emit(...args: any): void {
      this.$emit('change', ...args)
    },
    keyup_emit(...args: any): void {
      this.$emit('keyup', ...args)
    },
    blur_emit(...args: any): void {
      this.$emit('blur', ...args)
    },
    addDragItem_emit(...args: any): void {
      this.$emit('addDragItem', ...args)
    },
    customClick_emit(...args: any): void {
      this.$emit('customClick', ...args)
    },
    noSearch_emit(...args: any): void {
      this.$emit('noSearch', ...args)
    },

    customClick(emitData: Iobject, item: Iobject): void {
      this.$emit('customClick', { name: emitData.emit, item })
    },
    anyChange(): void {
      this.$emit('anyChange', this.root)
    },
    getFieldType(d: string): string {
      if (!this.scheme[d] || this.scheme[d].list === null) {
        if (this.scheme[d] && this.scheme[d].type === 'textarea') return 'textarea'
        return 'input'
      }
      if (this.scheme[d] && this.scheme[d].list !== null) return 'select'
      if (this.addingInit[d]) return 'drag'
      return 'input'
    },
    showedItem(d: any) {
      return d !== 'ord' && d !== 'datetime' && !addingInit[d]
    },
    cloneAndObjectKeys(ob: Iobject): string[] {
      return Object.keys(cloneObj(ob))
    },
    setDraggingStatus(status: boolean, ob: Iobject): void {
      this.dragging = status
      if (status === false) {
        let i = 0
        if (ob.datetime !== undefined) ob.datetime = getNowSecods()
        ob = ob.map((item: Iobject) => {
          const t = item
          t.ord = i
          i += 1
          return t
        })
      }
      this.$emit('changeDealData', this.itemParent, this.validOb, this.itemName, this.root)
    },
    addDragItem(ob: Iobject, d: string): void {
      if (this.disabled === true) return
      this.adding[d].ord = ob.length
      if (this.adding[d].datetime !== undefined) {
        this.adding[d].datetime = getNowSecods()
      }
      const adding: Iobject = cloneObj(this.adding[d])
      ob.push(adding)
      this.adding = cloneObj(this.addingInit)
      this.$emit('addDragItem', d)
      console.log(this.itemParent)
    },
    replaceDragItem(ob: Iobject, index: number): void {
      if (this.disabled === true) return
      openPopupDialog('Удалить пункт?').then((result: any) => {
        if (result === true) {
          ob.splice(index, 1)
          this.setDraggingStatus(false, ob)
          this.anyChange()
        }
      })
    },
    inputMousemove(): void {
      if (this.enabled.status === true) this.enabled.status = false
    },
    inputMouseleave(): void {
      this.enabled.status = true
    },
  },
} as Iobject
