
import m from '@/mixins/m'
import { resolveOpenPopupDialog } from '@/store/plugins/generalFunctions'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'PopupDialog',
  mixins: [m],
  components: {},
  data(): Iobject {
    return {}
  },
  mounted(): void {
    document.addEventListener('click', this.documentClick)
    document.addEventListener('keydown', this.documentKeyup)
  },
  beforeDestroy(): void {
    document.removeEventListener('click', this.documentClick)
    document.removeEventListener('keydown', this.documentKeyup)
  },
  methods: {
    getClick(a: any): void {
      resolveOpenPopupDialog(a)
    },
    documentClick(e: Iobject): void {
      if (this.getPopupDialog === null || e.target.closest('#board-wrap') === null) return
      if (e.target.closest('#board') === null) resolveOpenPopupDialog('')
    },
    documentKeyup(e: Iobject): void {
      if (this.getPopupDialog === null) return
      if (e.keyCode === 13 && this.getPopupDialog.answers.filter((a: Iobject) => a.result === true).length > 0) {
        resolveOpenPopupDialog(true)
      }
      if (e.keyCode === 27 && this.getPopupDialog.answers.filter((a: Iobject) => a.result === false).length > 0) {
        resolveOpenPopupDialog(false)
      }
    },
  },
} as Iobject
