
import canbanRouteData from '@/conf/routes/pages/canbanDeal'
import m from '@/mixins/m'
import { canbanStages } from '@/conf/canbanData'
import requests from '@/mixins/requests'
import { cloneObj } from '@/utils'
import { loadData } from '@/conf/company/companyData'
import { getCanbanDeal, getUsers } from '@/store/plugins/generalFunctions'
import r from '@/conf/deals/regions'
import { windRatio, snowRatio } from '@/conf/deals/dealsData'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Error from '@/components/atoms/Error.vue'
import CanbanDealPageRight from '@/components/pages/CanbanDealPageRight.vue'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'CanbanDealLeft',
  mixins: [m, requests],
  components: {
    Header,
    Footer,
    Error,
    CanbanDealPageRight,
  },
  data(): Iobject {
    return {
      error: null,
      canbanDeal: null,
      loadData: cloneObj(loadData),
      numberResponses: 0,
      limitiResponses: 4,
      regionData: null,
      canbanStages,
      arrayInfo: {
        phones: 'phone',
        emails: 'email',
        sites: 'site',
        messengers: 'messenger',
      },
    }
  },
  watch: {
    getRouteData(): void {
      this.initialCanbanDealPage()
    },
    error(val: boolean): void {
      if (val === true) this.$store.dispatch('app/setLoader', { data: false })
    },
  },
  computed: {
    d() {
      return this.canbanDeal?.data
    },
  },
  methods: {
    getFio(id: number): string {
      if (!this.getUsers) return ''
      const d = this.getUsers.data.filter((item: Iobject) => item.id === id)[0]
      return `${d.lastName} ${d.firstName} ${d.middleName}`.trim()
    },
    getSnowData(): string {
      if (!this.regionData) return '<span class="no-data">нет данных</span>'
      const ind = this.regionData.prop[0]
      return this.regionData ? `${snowRatio[ind]} кг/м<sup>2</sup>` : '<span class="no-data">нет данных</span>'
    },
    getWindData(): string {
      if (!this.regionData) return '<span class="no-data">нет данных</span>'
      const ind = this.regionData.prop[1]
      return this.regionData ? `${windRatio[ind]} кг/м<sup>2</sup>` : '<span class="no-data">нет данных</span>'
    },
    getRegion(id: number): string {
      this.regionData = r.filter((i: Iobject) => i.id === id)[0]
      return this.regionData ? this.regionData.name : ''
    },
    getWindowsText(g: Iobject): string {
      const textArr = []
      if (g.windowName) textArr.push(g.windowName)
      if (g.windowW) textArr.push(`ш: ${g.windowW} м`)
      if (g.windowH) textArr.push(`в: ${g.windowH} м`)
      if (g.windowAmount) textArr.push(`кол. ${g.windowAmount} шт.`)
      // console.log('textArr', textArr)
      return textArr.join(', ')
    },
    getDoorsText(g: Iobject): string {
      const textArr = []
      if (g.doorName) textArr.push(g.doorName)
      if (g.doorW) textArr.push(`ш: ${g.doorW} м`)
      if (g.doorH) textArr.push(`в: ${g.doorH} м`)
      if (g.doorAmount) textArr.push(`кол. ${g.doorAmount} шт.`)
      return textArr.join(', ')
    },
    getCranesText(g: Iobject): string {
      const textArr = []
      if (g.craneName) textArr.push(g.craneName)
      if (g.craneWeight) textArr.push(`${g.craneWeight}`)
      return textArr.join(', ')
    },
    getGatesText(g: Iobject): string {
      const textArr = []
      if (g.gateName) textArr.push(g.gateName)
      if (g.gateType) textArr.push(g.gateType)
      if (g.gateW) textArr.push(`ш: ${g.gateW} м`)
      if (g.gateH) textArr.push(`в: ${g.gateH} м`)
      if (g.gateAmount) textArr.push(`кол. ${g.gateAmount} шт.`)
      return textArr.join(', ')
    },
    async initialCanbanDealPage(): Promise<void> {
      if (this.$route.name !== canbanRouteData.name) return
      if (this.getUser === null) {
        const isLogin = await this.isLogin()
        if (isLogin === false) return
      }
      if (this.error !== null) this.error = null

      const id = Number(this.$route.params.id)
      this.$store.dispatch('app/setLoader', { data: true })

      getUsers()
        .catch((error: Error) => {
          console.warn('error getUsers', error)
        })
        .then(() => {
          this.numberResponses += 1
          this.removeLoader(this.numberResponses, this.limitiResponses)
        })

      getCanbanDeal(id)
        .then((result: boolean) => {
          console.log('getCanbanDeal result refresh', result)
          this.canbanDeal = cloneObj(this.getCanbanDeal)

          this.$emit('emitDealAndUserForRoleResolve', { deal: this.canbanDeal, getUser: this.getUser })

          return true
        })
        .then(() => {
          console.log('canbanDeal', this.canbanDeal)
          Object.keys(this.loadData).forEach((key: string) => {
            this.canbanDeal.data[key].forEach((i: Iobject) => {
              this.loadData[key].ids.push(i[this.loadData[key].scheme])
            })
            if (this.loadData[key].ids.length === 0) {
              this.numberResponses += 1
              return
            }
            this.loadCompanyContactsFunc(key)
          })
        })
        .catch((error: Error) => {
          console.warn('error getCanbanDeal', error)
          this.error = true
        })
        .then(() => {
          console.log('loadData', this.loadData)
          this.numberResponses += 1
          console.log('this.numberResponses', this.numberResponses)
          this.removeLoader(this.numberResponses, this.limitiResponses)
        })
    },
    async loadCompanyContactsFunc(key: string): Promise<void> {
      try {
        const result = await this.loadCompanyContacts(key, this.loadData[key].ids)
        this.loadData[key].data = result.data
        this.numberResponses += 1
        this.removeLoader(this.numberResponses, this.limitiResponses)
      } catch (error) {
        this.error = true
        console.warn('error getCanbanDeal loadCompanyContactsFunc', error)
      }
    },
    getResponsibleFio(id: number): string {
      const resp = this.getUsers?.data?.filter((i: Iobject) => i.id === id)[0]
      if (!resp) return ''
      return `${resp.firstName} ${resp.middleName} ${resp.lastName}`.trim()
    },
    getWatchersFio(arr: any): string {
      const watchersIds = arr.map((item: any) => item.watchersData)
      const watchers = (this.getUsers?.data ?? []).filter((i: Iobject) => watchersIds.includes(i.id))
      if (!watchers.length) return ''

      return watchers
        .map((watcher: any) => `${watcher.firstName} ${watcher.middleName} ${watcher.lastName}`.trim())
        .join(', ')
        .trim()
    },
    showCompany(c: Iobject): string {
      const s = []
      s.push(`<span class="bold">${c.companyType} ${c.companyName}</span>`.trim())
      Object.keys(this.arrayInfo).forEach((key: string) => {
        if (!c[key]) return
        c[key].forEach((d: Iobject) => {
          const itemName = this.arrayInfo[key]
          s.push(`${d[itemName]}${d.info ? ` (${d.info})` : ''}`.trim())
        })
      })
      return `${s.join('<br />')}<br /><br />`
    },
    showContact(c: Iobject): string {
      const s = []
      s.push(`<span class="bold">${c.firstName} ${c.middleName} ${c.lastName}</span>`.trim())
      if (c.position.length) s.push(`Должность: ${c.position}`)
      if (c.department.length) s.push(`Отдел: ${c.department}`)
      Object.keys(this.arrayInfo).forEach((key: string) => {
        if (!c[key]) return
        c[key].forEach((d: Iobject) => {
          const itemName = this.arrayInfo[key]
          s.push(`${d[itemName]}${d.info ? ` (${d.info})` : ''}`.trim())
        })
      })
      return `${s.join('<br />')}<br /><br />`
    },
  },
} as Iobject
