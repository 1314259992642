interface Iobject {
    [key: string]: any
}

export default {
  watch: {
    editId(val: null | number): void {
      if (val !== null) {
        this.$emit('startEdit', val)
      }
    },
    isEdit(val: null | boolean): void {
      if (val === false) this.editId = null
    },
  },
  computed: {
    iAmAuthor(): number {
      return this.getUser?.data?.id || 0
    },
  },
} as Iobject
