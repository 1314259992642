import addPropDefaults from '@/conf/routes/functions'
import p from '@/conf/routes/properties'
import Search from '@/components/pages/Search.vue'

const { properties } = p

const data: any = {
  path: '/search' as string,
  name: 'search' as string,
  component: Search as any,
  [properties.secure.name]: properties.secure.types.on,
  [properties.changeProp.name]: properties.changeProp.types.user,
  [properties.needLoadData.name]: properties.needLoadData.types.on,
  footer: {
    status: false,
  },
}

addPropDefaults(properties, data)
export default data
