interface Iobject {
  [key: string]: any
}

export const canbanStages = [
  { name: 'Первый контакт', stage: 0 },
  { name: 'В работе', stage: 8 },
  { name: 'Отправка КП', stage: 1 },
  { name: 'Переговоры', stage: 2 },
  { name: 'Принимают решение', stage: 3 },
  { name: 'Согласование договора', stage: 4 },
  { name: 'Оплата аванса', stage: 5 },
  { name: 'Исполнение', stage: 6 },
  { name: 'Окончательный расчет', stage: 7 },
]

export const canbanStagesForStatictics = [
  { id: 0, stage: 0, name: 'Первый контакт', numberBase: 0, color: 'rgba(213, 234, 255, 1)' },
  { id: 10, stage: 8, name: 'В работе', numberBase: 0, color: 'rgba(180, 150, 255, 1)' },
  { id: 20, stage: 1, name: 'Отправка КП', numberBase: 0, color: 'rgba(157, 202, 250, 1)' },
  { id: 30, stage: 2, name: 'Переговоры', numberBase: 1, color: 'rgba(80, 165, 255, 1)' },
  { id: 40, stage: 3, name: 'Принимают решение', numberBase: 2, color: 'rgba(255, 240, 0, 1)' },
  { id: 50, stage: 4, name: 'Согласование договора', numberBase: 3, color: 'rgba(255, 204, 102, 1)' },
  { id: 60, stage: 5, name: 'Оплата аванса', numberBase: 3, color: 'rgba(232, 52, 8, 1)' },
  { id: 70, stage: 6, name: 'Исполнение', numberBase: 4, color: 'rgba(206, 160, 105, 1)' },
  { id: 80, stage: 7, name: 'Окончательный расчет', numberBase: 5, color: 'rgba(204, 255, 102, 1)' },

  { id: 90, status: 3, name: 'Успешно реализована', numberBase: 6, color: 'rgba(27, 175, 24, 1)' },
  { id: 100, status: 2, name: 'Не реализована', numberBase: 0, color: 'rgba(130, 130, 130, 1)' },
  { id: 110, status: 4, name: 'Удалено', numberBase: 0, color: 'rgba(255, 140, 91, 1)' },
  { id: 120, status: 1, name: 'На удаление', numberBase: 0, color: 'rgba(232, 8, 229, 1)' },
]

export const catId = 1
