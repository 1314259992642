
import { canbanDataScheme } from '@/conf/deals/dealsData'
import CrmApi from '@/services/crmApi'
import { cloneObj, getNowSecods } from '@/utils'
import { tables } from '@/conf/tables'
import { 
  addingInit,
  board,
  companyContactsPhrases,
  allVisibleStatusDefault,
  fullVisibleItemsDefault,
} from '@/conf/company/companyData'
import m from '@/mixins/m'
import requests from '@/mixins/requests'
import { 
  openPopupDialog,
  getInsertRequest,
  getChangeRequest,
  getDealByContactOrCompany,
} from '@/store/plugins/generalFunctions'
import { getUnFlat, getHistoryReqToTable } from '@/store/plugins/canban/functions'

import Draggable from '@/components/atoms/Draggable.vue'
import CustomSelect from '@/components/atoms/CustomSelect.vue'
import CustomInput from '@/components/atoms/CustomInput.vue'
import CustomTextarea from '@/components/atoms/CustomTextarea.vue'

interface Iobject {
  [key: string]: any
}

const crmApi = new CrmApi()

export default {
  name: 'AddEditCompanyContacts',
  mixins: [m, requests],
  components: {
    Draggable,
    CustomSelect,
    CustomInput,
    CustomTextarea,
  },
  props: {
    type: {
      required: true,
    },
    popup: {
      default: true,
    },
    getCompanies: {
      default: null,
    },
    edit: {
      default: null,
    },
  },
  data() {
    return {
      scheme: cloneObj(canbanDataScheme),
      enabledDrag: { status: true },
      addDragItems: ['phones', 'emails', 'sites', 'messengers'],
      reqiuredFields: {
        company: {
          companyName: { minLength: 3 },
        },
        contacts: {
          firstName: { minLength: 2 },
          phones: { minLength: 1, item: { name: 'phone', minLength: 10 } },
        },
      },
      dataFields: { company: [], contacts: [] },
      typeNames: {
        company: 'Компания',
        contacts: 'Контакт',
      },
      allVisibleStatusDefault,
      fullVisibleItemsDefault,
      allVisibleStatus: cloneObj(allVisibleStatusDefault),
      fullVisibleItems: cloneObj(fullVisibleItemsDefault),
      inv: ['id', 'change.datetime', 'datetime'],
      board,
      addingInit,
      phrases: companyContactsPhrases,
      textarea: ['info'],
      inserted: [],
      changes: new Map(),
      deleted: new Set(),
      companies: [],
      requestEditDataNum: 0,
      requestEditData: { num: 0, timeout: null, timer: 200 },
      companyContactsToTable: { company: 'deal.company', contacts: 'contacts' },
      saveButton: {
        name: 'Сохранить',
        default: 'Сохранить',
        saving: 'Сохраняю...',
        saved: 'Данные сохранены',
        timeout: 2000,
        setTimeout: null,
      },
      dealData: {},
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    popup(val: boolean) {
      if (val === true) this.init()
    },
    requestEditDataNum(val: number) {
      if (this.requestEditData.timeout) clearTimeout(this.requestEditData.timeout)
      if (val % 2 !== 0) return
      this.requestEditData.timeout = setTimeout(() => {
        this.$store.dispatch('app/setLoader', { data: false })

        this.companies = this.getCompanies ? cloneObj(this.getCompanies) : []
        const addedCompanyIds: Iobject = { 'deal.company': [] }
        console.log('this.board', this.board)
        if (this.board.contact?.data) {
          this.board.contacts.data.forEach((i: Iobject) => {
            if (!i.company) return
            addedCompanyIds['deal.company'].push(i.company)
          })
        }
        console.log('addedCompanyIds', addedCompanyIds)

        if (this.type && (!this.companies || this.companies.length === 0)) {
          this.loadCompanies('deal.company', addedCompanyIds).then((ccData: Iobject) => {
            console.log('ccData', ccData)
            if (ccData[this.loadData['deal.company'].scheme]) {
              this.companies = ccData[this.loadData['deal.company'].scheme]
              this.correctsCompanies()
            }
          })
        } else {
          this.correctsCompanies()
        }
      }, this.requestEditData.timer)
    },
  },
  computed: {
    selectCompanies(): Iobject {
      const s: Iobject[] = []
      let value: any = null
      s.push({ value: null, name: '<i>нет компании</i>' })
      if (this.board.company?.data.length > 0) {
        const c = this.board.company.data[0]
        value = c.id
        const name = `${c.companyType || ''} ${c.companyName || ''}`.trim() || 'нет имени компании'
        s.push({ value, name })
      }
      this.companies.forEach((c: Iobject) => {
        if (c.id !== value) {
          s.push({ value: c.id ?? c.value, name: c.name })
        }
      })
      return s
    },
    valid(): boolean {
      if (!this.board) return false
      let valid = true
      let isItems = false
      this.dataFields = { company: [], contacts: [] }
      this.getObjectKeys(this.board).forEach((b: string) => {
        this.board[b].data.forEach((item: any, ind: number) => {
          this.dataFields[b][ind] = {}
          if (!this.allVisibleStatus[b][ind]) {
            this.$set(this.allVisibleStatus[b], ind, { type: false })
          }
          this.getObjectKeys(item).forEach((i: string) => {
            if (isItems === false) isItems = true
            const len = item[i] ? item[i].length : 0
            if (!this.reqiuredFields[b][i]) return
            if (len < this.reqiuredFields[b][i].minLength) {
              valid = false
              this.dataFields[b][ind][i] = false
            } else {
              const it = this.reqiuredFields[b][i]?.item
              if (!it?.name) return
              const index = this.reqiuredFields[b][i].minLength - 1
              const arrValueLength = item[i][index][it.name]?.length
              if (!arrValueLength || arrValueLength < it.minLength) {
                valid = false
                this.dataFields[b][ind][i] = false
              }
            }
          })
        })
      })
      if (isItems === false) return isItems
      return valid
    },
  },
  methods: {
    changeData(deal: Iobject, validOb: Iobject, item: string | null = null, root: any, e = 'blur'): void {
      if (!root) return
      console.log(item, e)
      const key = `${root.table}_${root.id}`
      this.changes.set(key, root)
    },
    noSearch(item: string, choose: string): void {
      this.noSearchRequest(item, choose, this.companies)
    },
    init(): void {
      this.requestEditDataNum = 0
      if (this.type?.startsWith('add') === true) {
        this.board = {
          company: {
            data: [], name: 'Компания', addButton: 'Добавить компанию', maxNum: 1, 
          },
          contacts: { data: [], name: 'Контакты', addButton: 'Добавить контакт' },
        }
        this.requestEditDataNum = 2
      }

      if (this.type?.startsWith('edit') === true) {
        this.board = {}
        let table = null
        Object.keys(this.loadData).forEach((key: string) => {
          if (this.loadData[key].scheme === this.edit.name) table = this.loadData[key].table
        })
        if (!this.edit.id) return
        this.$store.dispatch('app/setLoader', { data: true })
        this.getEditData(table, this.edit.id)
      }
      
      this.changes.clear()
      this.deleted.clear()
      this.allVisibleStatus = this.allVisibleStatusDefault
      this.fullVisibleItems = this.fullVisibleItemsDefault
    },
    checkBoardForStatic(): void {
      if (this.type === 'editStatic') {
        if (!this.board.company && !this.board.contacts) {
          this.$store.dispatch('app/setLoader', { data: false })
          this.routeGo({ name: 'error' })
        }
      }
    },
    correctsCompanies(): void {
      const deleteId = (this.board?.company?.data[0] && this.board.company.data[0].id) ?? 0
      let index = -1
      this.companies.forEach((v: Iobject, i: number) => {
        if (v.value === deleteId) index = i
      })
      console.log('correctsCompanies index', index)
      if (index >= 0) this.companies.splice(index, 1)
    },
    async getEditData(table: string | null, id: number, nameId = 'id'): Promise<void> {
      if (!table || !id) return
      
      const request: Iobject = { 
        datetime: getNowSecods(), 
        callbacks: [{
          table, nameId, id, compare: '=', 
        }],
      }
      this.requestEditDataNum += 1
      console.log('request getEditData', request)
      crmApi.sendAndGetData(request).then((result: Iobject) => {
        const arr: any = []
        result.data[0][table].forEach((i: Iobject) => {
          const unf = getUnFlat([i])
          arr.push(unf[0])
        })
        if (arr.length === 0) return
        if (table === tables.company) {
          this.$set(this.board, table, {
            data: arr, name: 'Компания', addButton: 'Добавить компанию', maxNum: -1, 
          })

          this.getEditData(tables.contacts, id, tables.company)
          // if (this.type !== 'editStatic') {
          //   this.getEditData(tables.contacts, id, tables.company)
          // }
        }
        if (table === tables.contacts) {
          if (!this.board?.contacts) {
            this.$set(this.board, table, {
              data: [], name: 'Контакты', addButton: 'Добавить контакт', maxNum: -1, 
            })
          }
          this.$set(this.board.contacts, 'data', arr)
        }
      }).then(async () => {
        const typeData = this.companyContactsToTable[table]

        this.dealData[table] = await getDealByContactOrCompany([Number(id)], typeData)
      }).catch((error: Error) => {
        console.warn('error getEditData', error)
      })
        .then(() => {
          this.requestEditDataNum += 1
          this.checkBoardForStatic()
        })
    },
    getVisible(b: string, i: number, g: string): boolean {
      if (this.allVisibleStatus[b][i]) {
        if (this.board[b].data[i][g] && this.board[b].data[i][g].length) return true
        if (this.allVisibleStatus[b][i].type === false && !this.fullVisibleItems[b].includes(g)) {
          return false
        } 
      }
      return true
    },
    setVisiblestatus(b: string, i: number): void {
      if (this.allVisibleStatus[b][i]) {
        this.allVisibleStatus[b][i].type = !this.allVisibleStatus[b][i].type
      }
    },
    getVisiblestatus(b: string, i: number): boolean {
      if (this.allVisibleStatus[b][i]) return this.allVisibleStatus[b][i].type
      return true
    },
    getValidItem(b: string, i: number, g: string): boolean {
      if (this.dataFields[b][i]) {
        if (this.dataFields[b][i][g] !== undefined) return !this.dataFields[b][i][g]
      }
      return false
    },
    getFieldType(d: Iobject, g: string): string {
      if (!Array.isArray(d[g]) && g !== 'company' && !this.inv.includes(g)) {
        return this.textarea.includes(g) ? 'textarea' : 'input'
      }
      if (!Array.isArray(d[g]) && g === 'company' && !this.inv.includes(g)) return 'select'
      if (Array.isArray(d[g]) === true) return 'array'
      return ''
    },
    getObjectKeys(ob: Iobject): any {
      return Object.keys(ob)
    },
    removeItem(item: Iobject, index: any, parent: Iobject, type: string): void {
      const delId = item.id
      if (delId === -1) this.removeNewItem(item, index, parent, type)
      if (delId > -1) this.removeOldItem(item, index, parent, type)
    },
    async removeOldItem(item: Iobject, index: any, parent: Iobject, type: string): Promise<void> {
      const result = await openPopupDialog('Удалить?')
      if (result !== true) return

      let request: Iobject | null = null
      if (type === 'company') {
        if (this.board.contacts?.data?.length > 0) {
          const answers = [{ name: 'Ok', result: true }]
          openPopupDialog('Чтобы удалить компанию, сначала удалите все ее контакты', answers)
        } else {
          request = {
            datetime: getNowSecods(),
            actions: [{
              table: tables.company, id: item.id, nameId: 'id', type: 'delete', items: [],
            }],
          }
        }
      }
      if (type === 'contacts') {
        request = {
          datetime: getNowSecods(),
          actions: [{
            table: tables.contacts, id: item.id, nameId: 'id', type: 'delete', items: [],
          }],
        }
      }
      if (request === null) return
      console.log('delete request', request)
      crmApi.sendAndGetData(request).then((r: Iobject) => {
        console.log('delete response', r)
        if (r.deleted[0][type].id === item.id) {
          this.removeNewItem(item, index, parent, type)
          this.deleted.add(item.id)
        }
      })
    },
    removeNewItem(item: Iobject, index: any, parent: Iobject, type: string): void {
      parent.splice(index, 1)
      if (type === 'company' && this.board.contacts?.data) {
        this.board.contacts.data.forEach((i: Iobject, ind: any) => {
          console.log(this.board.contacts.data[ind])
          if (i.company === item.id) this.board.contacts.data[ind].company = null
        })
      }
    },
    async saveData(): Promise<void> {
      // const result = await openPopupDialog('Вы уверены?')
      // if (result !== true) return
      if (this.type.startsWith('add') === true) this.saveDataAdd()
      if (this.type.startsWith('edit') === true) this.saveDataEdit()
    },
    async saveDataEdit(): Promise<void> {
      if (this.changes.size === 0) openPopupDialog('Нет изменений в данных', [{ name: 'Ok', result: true }])
      if (this.changes.size > 0) {
        console.log('this.changes', this.changes)
        const changeRequest = getChangeRequest(this.changes)
        this.changes.forEach((c: Iobject) => {
          if (c.table === 'company') {
            changeRequest.actions.push(getHistoryReqToTable({
              text: 'Измененены данные компании',
              userId: this.getUser.data.id,
              companyId: c.id,
            }))
          }
          if (c.table === 'contacts') {
            changeRequest.actions.push(getHistoryReqToTable({
              text: 'Измененены данные контакта',
              userId: this.getUser.data.id,
              contactId: c.id,
            }))
          }
        })

        console.log('change request', changeRequest)
        this.$store.dispatch('app/setLoader', { data: true })

        const sb = this.saveButton
        if (sb.setTimeout) clearTimeout(sb.setTimeout)
        sb.name = sb.saving

        crmApi.sendAndGetData(changeRequest)
          .then((result: any) => {
            console.log('result changeRequest', result)
            console.log(this.changes.size, result.changed.length)
            if (this.changes.size === result.changed.length) {
              // openPopupDialog('Данные сохранены', [{ name: 'Ok', result: true }])

              sb.name = sb.saved
              sb.setTimeout = setTimeout(() => {
                sb.name = sb.default
              }, sb.timeout)
            } else {
              throw new Error(result)
            }
          })
          .catch((error: Error) => {
            sb.name = sb.default
            console.warn('error change request', JSON.stringify(error))
            openPopupDialog('Ошибка при сохранении данных', [{ name: 'Ok', result: true }])
          })
          .then(() => {
            this.changes.clear()
            this.$store.dispatch('app/setLoader', { data: false })
          })
      }
    },
    async saveDataAdd(): Promise<void> {
      console.log('board', this.board)
      if (this.board.company?.data[0]) {
        let insertRequest = getInsertRequest([this.board.company.data[0]], tables.company)
        console.log('insert company request', insertRequest)
        this.$store.dispatch('app/setLoader', { data: true })

        crmApi.sendAndGetData(insertRequest)
          .then((result: Iobject) => {
            console.log('insert сompany result', result)
            const company = result.inserted[0][tables.company]
            this.inserted = this.inserted.concat(result.inserted)
            if (this.board.contacts?.data?.length > 0) {
              this.board.contacts.data.forEach((c: Iobject) => {
                if (c.company === -1) c.company = company
              })
              insertRequest = getInsertRequest(this.board.contacts.data, tables.contacts)
              console.log('insert contacts request', insertRequest)
              return crmApi.sendAndGetData(insertRequest)
            } 
            return { data: false }
          })
          .then((result: Iobject) => {
            console.log('insert contacts result', result)
            if (result.data) this.inserted = this.inserted.concat(result.inserted)
          })
          .catch((error: Error) => {
            console.warn('error insert contacts', JSON.stringify(error))
            const answers = [{ name: 'Ok', result: true }]
            openPopupDialog('Ошибка при сохранении компании', answers)
          })
          .then(() => {
            this.$store.dispatch('app/setLoader', { data: false })
            this.saveDataAddSuccess()
          })
      } else {
        if (this.board.contacts?.data?.length === 0) return
        const insertRequest = getInsertRequest(this.board.contacts.data, tables.contacts)
        console.log('insert contacts request', insertRequest)
        crmApi.sendAndGetData(insertRequest)
          .then((result: Iobject) => {
            console.log('insert contacts result', result)
            this.inserted = this.inserted.concat(result.inserted)
          })
          .catch((error: Error) => {
            console.warn('error insert contacts', JSON.stringify(error))
            const answers = [{ name: 'Ok', result: true }]
            openPopupDialog('Ошибка при сохранении контактов', answers)
          })
          .then(() => {
            this.saveDataAddSuccess()
          })
      }
    },
    saveDataAddSuccess(): void {
      // this.$store.dispatch('app/setLoader', { data: false })
      // const answers = [{ name: 'Ok', result: true }]
      // openPopupDialog('Данные сохранены!', answers)
      //   .then(() => {
      //     if (this.type === 'add') this.init()
      //     if (this.type === 'addPopup') this.$emit('stopPopupSavedAdded', this.inserted)
      //   })
      if (this.type === 'add') this.init()
      if (this.type === 'addPopup') this.$emit('stopPopupSavedAdded', this.inserted)
    },
    addItem(item: Iobject, itemName: string): void {
      if (itemName === 'company') {
        item.push(cloneObj(this.addingInit[itemName]))
      }
      if (itemName === 'contacts') {
        if (this.board.company?.data.length === 0) {
          item.push(cloneObj(this.addingInit[itemName]))
          return
        }
        const t = cloneObj(this.addingInit[itemName])
        t.company = this.board.company?.data[0].id || null
        item.push(t)
      }
    },
    popupClose(): void {
      this.$emit('popupClose')
    },
    isPopup(): boolean {
      return ['addPopup', 'editPopup'].includes(this.type)
    },
  },
} as Iobject
