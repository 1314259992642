

interface Iobject {
  [key: string]: any
}

export default {
  name: 'CustomTextarea',
  props: {
    parent: {
      required: true,
    },
    item: {
      required: true,
    },
    disabled: {
      default: false,
    },
    novalid: {
      default: false,
    },
    placeholder: {
      default: '',
    },
    scheme: {
      required: true,
    },
  },
  data() {
    return {

    }
  },
  methods: {
    clickDelete(e: Iobject): void {
      console.log(this.parent, this.item)
      this.parent[this.item] = ''
      e.target.closest('.custom-textarea').querySelector('.--textarea').focus()
    },
  },
} as Iobject
