import { openPopupDialog } from '@/store/plugins/generalFunctions'
import CookiesApi from '@/services/cookiesApi'
import { userCookieName } from '@/store/plugins/auth/getCookie'

interface Iobject {
    [key: string]: any
}

const cookiesApi = new CookiesApi()

export default {
  methods: {
    removeCookie(): void {
      cookiesApi.removeCookie(userCookieName)
        .then((result: Iobject) => {
          if (result.status !== 'ok') throw new Error(JSON.stringify(result))
          window.location.reload()
        }).catch((error: Error) => {
          console.log('removeCookie error', error)
          const answers = [{ name: 'Ok', result: true }]
          openPopupDialog('Произошла ошибка!', answers)
        })
    },
  },
} as Iobject
