
import { PropType } from 'vue'
import { canbanStagesForStatictics } from '@/conf/canbanData'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'Statistics',
  props: {
    getSortedDeals: {
      type: Array as PropType<any>,
      required: true,
    },
  },
  data(): any {
    return {
      maxFilterWidthPercent: 80,
    }
  },
  computed: {
    filter(): Iobject {
      const deals = [...canbanStagesForStatictics].map((deal) => ({ ...deal, number: 0, sum: 0 }))

      this.getSortedDeals.forEach((deal: any) => {
        if ([1, 2, 3, 4].includes(deal.status)) {
          let dl = deals.find((d) => d.status === deal.status)
          this.findDealPlus(deal, dl)

          dl = deals.find((d) => d.stage === 0)
          this.findDealPlus(deal, dl)

          return
        }

        const numberOfPrevStages = canbanStagesForStatictics.findIndex((s) => s.stage === deal['stage.type']) + 1

        const arrOfPrevStages = canbanStagesForStatictics
          .slice(0, numberOfPrevStages)
          .map((stageData) => stageData.stage)

        arrOfPrevStages.forEach((stage) => {
          const dl = deals.find((d) => d.stage === stage)
          this.findDealPlus(deal, dl)
        })
      })

      return deals ?? []
    },
  },
  methods: {
    getDivide(top: number, bottom: number) {
      if (bottom > 0) {
        return top / bottom
      }

      return 0
    },
    findDealPlus(deal: Iobject, findDeal: Iobject): void {
      if (!findDeal) return

      findDeal.number += 1
      findDeal.sum += deal['deal.price'] ?? 0
    },
  },
} as Iobject
