
import m from '@/mixins/m'
import messages from '@/mixins/messages'
import { getTaskIconStatus } from '@/store/helpers/getTaskIconStatus'
import { convertToBr } from '@/store/plugins/generalFunctions'
import { cloneObj, getNowSecods, getNoun } from '@/utils'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'Tasks',
  mixins: [m, messages],
  props: {
    tasks: {
      required: true,
    },
    endedTasks: {
      required: true,
    },
    isEdit: {
      required: true,
    },
    mainPage: {
      default: false,
    },
  },
  data(): Iobject {
    return {
      editId: null,
      textLength: 60,
      drop: {},
      full: {},
      offsetDelay: 60 * 60 * 24,
    }
  },
  mounted(): void {
    document.addEventListener('click', this.documentClick)
  },
  beforeDestroy(): void {
    document.removeEventListener('click', this.documentClick)
  },
  computed: {
    resolvedData(): Iobject {
      const resolverData: Iobject = {}
      this.tasks.forEach((task: Iobject) => {
        resolverData[task.id] = {}
        resolverData[task.id].getStatuses = this.getStatus(task)
        resolverData[task.id].isChangeStatus = this.isChangeStatus(task)
        resolverData[task.id].drop = this.drop[task.id] ? this.drop[task.id] : false
        resolverData[task.id].full = this.full[task.id] ? this.full[task.id] : false
      })
      return resolverData
    },
  },
  methods: {
    getNoun(number: number, one: string, two: string, five: string) {
      return getNoun(number, one, two, five)
    },
    changeMessage(mes: Iobject, drop: Iobject): void {
      if (this.resolvedData[mes.id].isChangeStatus === false) return
      if (this.isEdit === true) return
      const changed = cloneObj(mes)
      changed.type = drop.messageType
      this.$emit('changeMessage', changed)
      this.closeAllDrops()
    },
    documentClick(e: Iobject): void {
      if (e.target.closest('.task__status-type') === null) this.closeAllDrops()
    },
    closeAllDrops(): void {
      Object.keys(this.drop).forEach((d: string) => {
        this.drop[d] = false
      })
    },
    setFull(id: number): void {
      this.$set(this.full, id, this.full[id] ? !this.full[id] : true)
    },
    setDrop(id: number): void {
      if (this.mainPage) return

      if (this.resolvedData[id].isChangeStatus === false) return

      if (this.isEdit === true) return

      this.closeAllDrops()
      this.$set(this.drop, id, this.drop[id] ? !this.drop[id] : true)
    },
    isChangeStatus(mes: Iobject): boolean {
      const isAuthor = this.getUser?.data?.id === mes.userId
      const isResponsible = this.getUser?.data?.id === mes.responsible
      return isAuthor || isResponsible
    },
    isTaskEnded(mes: Iobject): boolean {
      return this.getStatus(mes).type === 2
    },
    iconByRemainTime(mes: Iobject): string {
      return getTaskIconStatus(mes.toDate)
    },
    showTask(mes: Iobject): boolean {
      const isStatusNotEnded = this.getStatus(mes).type !== 2
      const isStatusEndedButShowEnded = this.getStatus(mes).type === 2 && this.endedTasks.status === true
      return isStatusNotEnded || isStatusEndedButShowEnded
    },
    showToEdit(mes: Iobject): boolean {
      const isEdit = this.isEdit !== true
      const AmIauthor = mes.userId === this.iAmAuthor
      const noEditId = this.editId === null
      const typeNotNull = mes.type !== null
      const statusNotEnded = this.getStatus(mes).type !== 2

      return isEdit && AmIauthor && noEditId && typeNotNull && statusNotEnded
    },
    getStatus(task: Iobject): Iobject {
      const toDate = task.toDate + this.offsetDelay

      if (task.type === 2) {
        return {
          text: 'Завершена',
          type: 2,
          messageType: 2,
          change: [
            {
              text: toDate < getNowSecods() ? 'Просрочена' : 'В работе',
              messageType: 1,
            },
          ],
        }
      }

      if (task.type === 1 && toDate < getNowSecods()) {
        return {
          text: 'Просрочена',
          type: 1,
          messageType: 1,
          change: [
            {
              text: 'Завершена',
              messageType: 2,
            },
          ],
        }
      }

      return {
        text: 'В работе',
        type: 0,
        messageType: 1,
        change: [
          {
            text: 'Завершена',
            messageType: 2,
          },
        ],
      }
    },
    getTextWithBr(text: string): string {
      return convertToBr(text)
    },
  },
} as Iobject
