export enum Types {
  on = 'on',
  off = 'off',
  auth = 'auth',
  user = 'user',
  userAndChief = 'userAndChief',
  all = 'all',
  admin = 'admin',
}

export interface IType {
  [Types.on]: boolean
  [Types.off]: boolean
  [Types.auth]: string
  [Types.user]: string
  [Types.userAndChief]: string
  [Types.all]: string
}

const types: IType = {
  [Types.on]: true,
  [Types.off]: false,
  [Types.auth]: 'onlyAuthorized',
  [Types.user]: 'onlyUser',
  [Types.userAndChief]: 'userAndChief',
  [Types.all]: 'all',
}

export enum Names {
  secure = 'secure',
  changeProp = 'changeProp',
  needLoadData = 'needLoadData',
}

interface IFooter {
  status: boolean
}

export interface IRouterType {
  path: string
  name: string
  component: any
  props?: any
  [Names.secure]?: boolean
  [Names.changeProp]?: string
  [Names.needLoadData]?: string
  footer: IFooter
}

export interface ISecureType {
  [Types.on]: any
  [Types.off]: any
}

export interface INeedLoadDataType {
  [Types.on]: any
  [Types.off]: any
}

export interface IChangePropType {
  [Types.user]: any
  [Types.userAndChief]: any
  [Types.all]: any
  [Types.off]: any
}

export interface ISecure {
  name: string
  describe: string
  types: ISecureType
  default: any
}

export interface IChangeProp {
  name: string
  describe: string
  types: IChangePropType
  default: any
}

export interface INeedLoadData {
  name: string
  describe: string
  types: INeedLoadDataType
  default: any
}

export interface IProperties {
  [Names.secure]: ISecure
  [Names.changeProp]: IChangeProp
  [Names.needLoadData]: INeedLoadData
}

const properties: IProperties = {
  secure: {
    name: Names.secure,
    describe: 'Авторизован ли пользователь для входа',
    types: {
      on: types.auth,
      off: types.off,
    },
    default: types.off,
  },
  changeProp: {
    name: Names.changeProp,
    describe: 'Возможность измнения данных',
    types: {
      user: types.user,
      userAndChief: types.userAndChief,
      all: types.all,
      off: types.off,
    },
    default: types.off,
  },
  needLoadData: {
    name: Names.needLoadData,
    describe: 'Необходимость загрузки данных',
    types: {
      on: types.on,
      off: types.off,
    },
    default: types.off,
  },
}

export default { properties, types }
