
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CanbanFilter, { SortByUser } from '@/components/pages/CanbanFilter.vue'
import RegistryBody from '@/components/pages/RegistryBody.vue'
import m from '@/mixins/m'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'Registry',
  mixins: [m],
  components: {
    Header,
    Footer,
    CanbanFilter,
    RegistryBody,
  },
  data() {
    return {
      sortByUser: {},
      defaultFilters: {
        'deal.name': null,
        'responsible.id': null,
        status: 0,
        period: null,
        'stage.type': null,
      },
    }
  },
  methods: {
    setSortByUser(sortByUser: SortByUser) {
      this.sortByUser = sortByUser
    },
  },
} as Iobject
