import addPropDefaults from '@/conf/routes/functions'
import p from '@/conf/routes/properties'
import CanbanDeal from '@/components/pages/CanbanDealPage.vue'

const { properties } = p

const data: any = {
  path: '/deal/:id' as string,
  name: 'canbanDeal' as string,
  component: CanbanDeal as any,
  [properties.secure.name]: properties.secure.types.on,
  [properties.needLoadData.name]: properties.needLoadData.types.on,
  footer: {
    status: false,
  },
}

addPropDefaults(properties, data)
export default data
