
import m from '@/mixins/m'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CustomInput from '@/components/atoms/CustomInput.vue'
import CustomSelect from '@/components/atoms/CustomSelect.vue'
import { cloneObj, getNowSecods } from '@/utils'
import { tables } from '@/conf/tables'
import CrmApi from '@/services/crmApi'
import { Roles, activities, roles, seeSetUsers } from '@/store/plugins/filterByRoles'
import md5 from 'md5'
import { openPopupDialog } from '@/store/plugins/generalFunctions'

interface Iobject {
  [key: string]: any
}

const crmApi = new CrmApi()

export default {
  name: 'SetUsers',
  mixins: [m],
  components: {
    Header,
    Footer,
    CustomInput,
    CustomSelect,
  },
  data(): Iobject {
    return {
      hideUnactive: true,
      userItems: ['firstName', 'lastName', 'middleName', 'role', 'active', 'login', 'email', 'password'],
      md5Key: 'password',
      users: null,
      defaultUsers: null,
      roles,
      activities,
      addUserDefault: {
        firstName: '',
        lastName: '',
        middleName: '',
        role: Roles.manager,
        active: 1,
        login: '',
        email: '',
        password: '',
      },
      addUser: {},
      notValidAddUserItems: ['firstName', 'login', 'email', 'password'],
      notValidAddUserMessage: 'Чтобы добавить пользователя, необходимо ввести Имя, Логин, Email и Пароль',

      notValidSaveUserItems: ['firstName', 'login', 'email'],
      notValidSaveUserMessage: 'Чтобы сохранить пользователя, необходимо ввести Имя, Логин, Email',
    }
  },
  async mounted() {
    this.addUser = cloneObj(this.addUserDefault)
    this.startComponent()
  },
  watch: {
    getUser() {
      if (this.users) return

      this.startComponent()
    },
  },
  methods: {
    async startComponent() {
      if (!seeSetUsers.includes(this.getUser?.data?.role ?? '')) return

      const users = await this.loadUsersData()

      if (!(users as any)?.data || typeof (users as any)?.data[0] === 'undefined') {
        this.users = false

        return
      }

      this.users = (users as any)?.data[0].users ?? false

      this.users = this.users.map((user: any) => ({ ...user, password: '', startActive: user.active }))
      this.defaultUsers = cloneObj(this.users)
    },
    restoreUsers() {
      this.users = cloneObj(this.defaultUsers)
    },
    getAddUserRequest() {
      const items = this.getUserItemsForSave(this.addUser)

      const request = {
        actions: [
          {
            table: tables.users,
            type: 'insert',
            items,
          },
        ],
        callbacks: [],
        datetime: getNowSecods(),
      }

      return request
    },
    checkIsAddUserItemsValid(user: any): boolean {
      let isValid = true
      this.notValidAddUserItems.forEach((key: string) => {
        if (user[key] === '') isValid = false
      })

      return isValid
    },
    checkIsSaveUserItemsValid(user: any): boolean {
      let isValid = true
      this.notValidSaveUserItems.forEach((key: string) => {
        if (user[key] === '') isValid = false
      })

      return isValid
    },
    addNewUser() {
      const request = this.getAddUserRequest()

      crmApi
        .sendAndGetData(request)
        .then(() => {
          this.startComponent()

          this.addUser = cloneObj(this.addUserDefault)

          openPopupDialog('Пользователь успешно добавлен', [{ name: 'Ok', result: true }])
        })
        .catch((error: Iobject) => {
          this.$store.dispatch('app/setLoader', { data: false })
          console.warn('error change user data', error)
          openPopupDialog('Ошибка при сохранении данных', [{ name: 'Ok', result: true }])
        })
    },
    addNewUserRequest() {
      const isValid = this.checkIsAddUserItemsValid(this.addUser)
      if (!isValid) {
        openPopupDialog(this.notValidAddUserMessage, [{ name: 'Ok', result: true }])

        return
      }

      openPopupDialog('Вы уверены?').then((result: any) => {
        if (result === true) {
          this.addNewUser()
        }
      })
    },
    unlognUser(id: number) {
      const request = {
        actions: [
          {
            table: tables.users,
            id,
            nameId: 'id',
            type: 'change',
            items: [
              {
                name: 'cookie',
                value: '',
              },
            ],
          },
        ],
        callbacks: [],
        datetime: getNowSecods(),
      }

      this.$store.dispatch('app/setLoader', { data: true })
      crmApi
        .sendAndGetData(request)
        .then((result) => {
          if (result.status === 'error') {
            this.$store.dispatch('app/setLoader', { data: false })
            openPopupDialog(`Ошибка: ${result.data ?? ''}`, [{ name: 'Ok', result: true }])
            return
          }

          this.startComponent()
          openPopupDialog('Данные успешно сохранены', [{ name: 'Ok', result: true }])
        })
        .catch((error: Iobject) => {
          this.$store.dispatch('app/setLoader', { data: false })
          console.warn('error change user data', error)
          openPopupDialog('Ошибка при сохранении данных', [{ name: 'Ok', result: true }])
        })
    },
    getUserItemsForSave(user: any) {
      const items = [] as any

      this.userItems.forEach((key: string) => {
        if (key === this.md5Key) {
          if (user[key] !== '') {
            items.push({
              name: key,
              value: md5(user[key]),
            })
            items.push({
              name: 'cookie',
              value: '',
            })
          }

          return
        }

        items.push({
          name: key,
          value: user[key],
        })
      })

      return items
    },
    getChangeUserRequest(id: number) {
      const changeUser = this.users.find((user: any) => user.id === id)

      const items = this.getUserItemsForSave(changeUser)

      const request = {
        actions: [
          {
            table: tables.users,
            id,
            nameId: 'id',
            type: 'change',
            items,
          },
        ],
        callbacks: [],
        datetime: getNowSecods(),
      }

      return request
    },
    unlognUserPopup(id: number) {
      openPopupDialog('Вы уверены?').then((result: any) => {
        if (result === true) {
          this.unlognUser(id)
        }
      })
    },
    saveUserDataPopup(user: any) {
      const isValid = this.checkIsSaveUserItemsValid(user)
      if (!isValid) {
        openPopupDialog(this.notValidSaveUserMessage, [{ name: 'Ok', result: true }])

        return
      }

      openPopupDialog('Вы уверены?').then((result: any) => {
        if (result === true) {
          this.saveUserData(user.id)
        }
      })
    },
    async saveUserData(id: number) {
      const request = this.getChangeUserRequest(id)

      this.$store.dispatch('app/setLoader', { data: true })
      crmApi
        .sendAndGetData(request)
        .then((result) => {
          if (result.status === 'error') {
            this.$store.dispatch('app/setLoader', { data: false })
            openPopupDialog(`Ошибка: ${result.data ?? ''}`, [{ name: 'Ok', result: true }])
            return
          }

          this.startComponent()
          openPopupDialog('Данные успешно сохранены', [{ name: 'Ok', result: true }])
        })
        .catch((error: Iobject) => {
          console.warn('error change user data', error)
          openPopupDialog('Ошибка при сохранении данных', [{ name: 'Ok', result: true }])
        })
    },
    async loadUsersData() {
      this.$store.dispatch('app/setLoader', { data: true })

      const datetime = getNowSecods()

      return new Promise((resolve) => {
        const request = {
          datetime: getNowSecods(),
          callbacks: [
            {
              table: tables.users,
              order: 'id',
            },
          ],
        }

        crmApi
          .sendAndGetData(request)
          .then((result: Iobject) => {
            this.$store.dispatch('app/setLoader', { data: false })

            resolve(result)
          })
          .catch((error: Iobject) => {
            this.$store.dispatch('app/setLoader', { data: false })
            console.warn('error loadUsersData', error)

            resolve(false)
          })
      })
    },
  },
} as Iobject
