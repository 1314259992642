
import canbanRouteData from '@/conf/routes/pages/canban'
import { canbanStages, catId } from '@/conf/canbanData'
import { reloadCanbanInterval } from '@/conf/reloadTimers'
import CustomSelect from '@/components/atoms/CustomSelect.vue'
import { getUsers } from '@/store/plugins/generalFunctions'
import { purposes, statuses, sources } from '@/conf/deals/selects'
import { getCoords, getNoun, numberWithSpaces, getDate, cloneObj, getNowSecods } from '@/utils'
import {
  changeCabnanArrayPosition,
  getUnFlat,
  changeCabnanStatus,
  setStatusCanbanDeal,
} from '@/store/plugins/canban/functions'
import r from '@/conf/deals/regions'
import { dispatchByTable, tables } from '@/conf/tables'
import CrmApi from '@/services/crmApi'
import m from '@/mixins/m'
import { filterCanbanByRoles } from '@/store/plugins/filterByRoles'
import { getTaskIconStatus } from '@/store/helpers/getTaskIconStatus'

const crmApi = new CrmApi()

interface Iobject {
  [key: string]: any
}

const defaultSort = {
  status: 0,
  'responsible.id': null,
  'deal.name': null,
  'deal.source': null,
}

export default {
  name: 'Columns',
  components: {
    CustomSelect,
  },
  mixins: [m],
  props: {
    xDiff: {
      default: 0,
      type: Number,
    },
    dealStatus: {
      default: null,
    },
  },
  data(): Iobject {
    return {
      stages: canbanStages,
      canban: {},
      drag: {},
      dragId: null,
      dragTimeout: 200,
      dragSetTimeout: null,
      dragOverInesrtable: null,
      dragOverInesrtableColumn: [],
      dragClone: null,
      isChangeItemPosition: null,
      catId,
      sortedCanban: [],
      canbanByStages: {},
      xScroll: null,
      isSorting: false,
      error: null,
      tasksToDateByDeal: {},
      sortByUser: { ...defaultSort },
    }
  },
  mounted(): void {
    this.error = null
    const app = document.getElementById('app')
    if (!app) return
    app.addEventListener('mouseleave', this.dragStop)
    app.addEventListener('mouseup', this.dragStop)
  },
  beforeDestroy(): void {
    const app = document.getElementById('app')
    if (!app) return
    app.removeEventListener('mouseleave', this.dragStop)
    app.removeEventListener('mouseup', this.dragStop)
  },
  computed: {
    statuses(): Iobject {
      return statuses
    },
    sources() {
      return [{ name: 'Любой', value: null }, ...sources]
    },
    purposes(): Iobject {
      const res = cloneObj(purposes)
      res.unshift({
        name: 'Любой',
        value: null,
      })
      return res
    },
    responsibles(): Iobject[] {
      if (!this.getUsers) return []
      const responsibles: Iobject[] = [
        {
          name: 'Любой',
          value: null,
        },
        {
          name: 'Не назначен',
          value: 0,
        },
      ]
      this.getUsers.data.forEach((user: Iobject) => {
        responsibles.push({
          name: `${user.firstName} ${user.lastName}`.trim(),
          value: user.id,
        })
      })
      return responsibles
    },
  },
  watch: {
    sortByUser: {
      deep: true,
      handler: 'urlSortByUser',
    },
    dragId(val: number | null): void {
      this.$emit('isDrag', val)
    },
    getCanbanOrder(): void {
      this.initialCanbanAndSortDataAndByStage(false)
    },
    getRouteData(): void {
      this.initialCanbanAndSortDataAndByStage(true)
    },
    getCanbanData(): void {
      this.initialCanbanAndSortDataAndByStage(false)
    },
    dealStatus(status: number): void {
      if (status === null) return
      this.setDealStatus(status)
    },
  },
  methods: {
    getTaskText(dealId: number): string {
      if (!this.tasksToDateByDeal[dealId]) return ''

      return `${this.tasksToDateByDeal[dealId].text}
отв: ${this.getName(this.tasksToDateByDeal[dealId].responsible)}
до ${this.getDate(this.tasksToDateByDeal[dealId].toDate)}`
    },
    clearSearch(): void {
      this.sortByUser = {
        ...defaultSort,
      }
      this.initialCanbanAndSortDataAndByStage(true, true)
    },
    getUrlWithParams(val: Iobject): string {
      const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
      let newUrl = `${baseUrl}?`
      Object.keys(val).forEach((item: string) => {
        if (item.length === 0) return

        newUrl += `${item}=${val[item]}&`
      })
      return newUrl
    },
    urlSortByUser(val: Iobject): void {
      window.history.pushState(null, '', this.getUrlWithParams(val))
    },
    changeStatus() {
      this.initialCanbanAndSortDataAndByStage(true, true)
    },
    getCanbanStageByStatus(canbanI: Iobject): Iobject {
      return canbanI
      // return canbanI.filter((d: Iobject) => d.status === this.sortByUser.status)
    },
    setDealStatus(status: number): void {
      if (this.dragId === null) {
        this.$emit('setDealStatusNull')
        return
      }
      const request = changeCabnanStatus({ status, dealId: this.dragId, user: this.getUser })
      this.drag[this.dragId].status = 'freeze'

      crmApi
        .sendAndGetData(request)
        .then((dataFromApi: Iobject) => {
          console.log('changeCabnanStatus result', dataFromApi)
          setStatusCanbanDeal({ dataFromApi })
        })
        .catch((error: Iobject) => {
          console.warn('error changeCabnanStatus', error)
        })
        .then(() => {
          this.defaultAfterDrag()
          this.$emit('setDealStatusNull')
        })
    },
    getNoun(amount: number, one: string, two: string, five: string): string {
      return getNoun(amount, one, two, five)
    },
    getSortByUser(item: Iobject): boolean {
      const isStatus = item.status === this.sortByUser.status
      let isResponsibleId = this.sortByUser['responsible.id'] === null
      let isDealName = this.sortByUser['deal.name'] === null
      let isDealSource = this.sortByUser['deal.source'] === null

      if (!isResponsibleId) {
        isResponsibleId = item['responsible.id'] === this.sortByUser['responsible.id']
      }
      if (!isDealName) {
        isDealName = item['deal.name'] === this.sortByUser['deal.name']
      }

      if (!isDealSource) {
        isDealSource = item['deal.source'] === this.sortByUser['deal.source']
      }

      return isStatus && isResponsibleId && isDealName && isDealSource
    },
    getPrice(items: Iobject): Iobject {
      let price = 0
      let amount = 0
      items.forEach((item: Iobject) => {
        if (this.getSortByUser(item)) {
          price += item['deal.price']
          amount += 1
        }
      })
      return {
        price: numberWithSpaces(price),
        amount,
      }
    },
    getDate(datetime: number): string {
      return getDate(datetime)
    },
    dragOverColumnLeave(stage: number): void {
      if (this.dragOverInesrtableColumn[stage] === null) return
      this.$set(this.dragOverInesrtableColumn, stage, null)
      this.isChangeItemPosition = null
    },
    dragOverColumn(e: Iobject, stage: number): void {
      if (this.dragId === null) return
      if (this.dragOverInesrtable || e.target.closest('#columns-header')) {
        if (this.dragOverInesrtableColumn[stage] !== null) this.$set(this.dragOverInesrtableColumn, stage, null)
        if (this.isChangeItemPosition?.to === null) this.isChangeItemPosition = null
        return
      }

      if (this.dragOverInesrtableColumn[stage] !== true) {
        this.$set(this.dragOverInesrtableColumn, stage, true)
        this.isChangeItemPosition = {
          from: this.dragId,
          to: null,
          position: 'down',
          stage,
        }
      }
    },
    dragOverLeave(e: Iobject, item: Iobject): void {
      if (this.dragId === null) return
      if (this.dragOverInesrtable === true) this.dragOverInesrtable = null
      if (item.id === this.dragId) return

      this.drag[item.id].status = 'none'
      this.isChangeItemPosition = null
    },
    dragOver(e: Iobject, item: Iobject): void {
      if (this.dragId === null) return
      if (this.dragOverInesrtable !== true) this.dragOverInesrtable = true
      if (item.id === this.dragId) return

      const block = document.getElementById(`item-${item.id}`)
      if (!block) return

      const o = getCoords(block)
      const { height } = block.getBoundingClientRect()
      if ((e.clientY ?? e.pageY) > o.top - window.pageYOffset + height / 2) {
        this.drag[item.id].status = 'down'
      } else {
        this.drag[item.id].status = 'up'
      }
      this.isChangeItemPosition = {
        from: this.dragId,
        to: item.id,
        position: this.drag[item.id].status,
        stage: item['stage.type'],
      }
    },
    dragStart(e: Iobject, item: Iobject): void {
      e.stopPropagation()
      e.preventDefault()
      this.dragSetTimeout = setTimeout(() => {
        const block = document.getElementById(`item-${item.id}`)
        const parent = document.getElementById(`column-${item['stage.type']}`)
        if (!block || !parent) return
        const coords = getCoords(block)
        this.drag[item.id].status = 'go'
        this.drag[item.id].x = coords.left - window.pageXOffset
        this.drag[item.id].y = coords.top - window.pageYOffset
        this.drag[item.id].xM = e.clientX ?? e.pageX
        this.drag[item.id].yM = e.clientY ?? e.pageY
        this.drag[item.id].xDiff = this.xDiff
        this.dragId = item.id
        this.addClone(coords, block, parent)
      }, this.dragTimeout)
    },
    dragStop(e: Iobject): void {
      if (this.dragId === null) return
      const isChangeItemPosition = this.isChangeItemPosition
      setTimeout(() => {
        if (this.dragSetTimeout) {
          clearTimeout(this.dragSetTimeout)
          this.dragSetTimeout = null
        }

        if (this.drag[this.dragId]?.status !== 'go') return
        if (isChangeItemPosition === null) {
          this.dragSlideBack()
          return
        }
        this.$store.dispatch('app/setLoader', { data: true })
        this.drag[this.dragId].status = 'freeze'
        try {
          const cp = isChangeItemPosition

          const changes = changeCabnanArrayPosition({
            sortedCanban: this.sortedCanban,
            from: cp.from,
            to: cp.to,
            position: cp.position,
            stage: cp.stage,
            user: this.getUser,
            canbanStages,
          })
          console.log('changes', changes)
          this.completeDrag(changes)
        } catch (error) {
          console.log('Error newGetCanbanData:', error)
          this.dragSlideBack()
        }
      }, 0)
    },
    dragGo(e: Iobject): void {
      if (this.drag[this.dragId]?.status === 'go') {
        const left = this.drag[this.dragId].x - this.drag[this.dragId].xM + e.clientX ?? e.pageX - window.pageXOffset
        const top = this.drag[this.dragId].y - this.drag[this.dragId].yM + e.clientY ?? e.pageY - window.pageYOffset

        this.dragClone.style.left = `${left}px`
        this.dragClone.style.top = `${top}px`
        this.dragClone.style.zIndex = 1000
      }
    },
    dragSlideBack(): void {
      Object.keys(this.drag).forEach((i: string) => {
        if (this.drag[i].status !== 'none') this.drag[i].status = 'none'
      })
      this.dragClone.classList.add('drag-back')

      const xDiff = this.drag[this.dragId].xDiff - this.xDiff

      this.dragClone.style.left = `${this.drag[this.dragId].x + xDiff}px`
      this.dragClone.style.top = `${this.drag[this.dragId].y}px`
      this.defaultAfterDrag()
    },
    removeClone(): void {
      setTimeout(() => {
        this.dragClone.parentNode.removeChild(this.dragClone)
        this.dragClone = null
      }, 150)
    },
    addClone(coords: Iobject, block: HTMLElement, parent: HTMLElement): void {
      this.dragClone = block.cloneNode(true)
      parent.appendChild(this.dragClone)
      const { width } = this.dragClone.getBoundingClientRect()
      this.dragClone.id = 'clone'
      this.dragClone.style.position = 'fixed'
      this.dragClone.style.width = `${width}px`
      this.dragClone.style.left = `${coords.left - window.pageXOffset + 5}px`
      this.dragClone.style.top = `${coords.top - window.pageYOffset + 5}px`
      this.dragClone.style.padding = '10px'
      this.dragClone.style.background = '#ccc'
    },
    defaultAfterDrag(): void {
      this.drag[this.dragId].x = null
      this.drag[this.dragId].y = null
      this.drag[this.dragId].xM = null
      this.drag[this.dragId].yM = null
      this.drag[this.dragId].xDiff = null
      this.dragId = null
      this.stages.forEach((_item: Iobject, index: number) => {
        this.dragOverInesrtableColumn[index] = null
      })
      this.dragOverInesrtable = null
      this.removeClone()
      this.$store.dispatch('app/setLoader', { data: false })
      Object.keys(this.drag).forEach((i: string) => {
        if (this.drag[i].status !== 'none') this.drag[i].status = 'none'
      })
    },
    resolveCallback(item: Iobject): void {
      if (!dispatchByTable[Object.keys(item)[0]]) return
      if (dispatchByTable[Object.keys(item)[0]] === 'app/setCanbanData') {
        const can = cloneObj(this.getCanbanData)
        const unFlat = getUnFlat(item[Object.keys(item)[0]])
        const canData: Iobject[] = []
        can.data.forEach((deal: Iobject) => {
          if (deal.id === unFlat[0].id) {
            canData.push(unFlat[0])
          } else {
            canData.push(deal)
          }
        })
        can.data = canData
        this.$store.dispatch(dispatchByTable[Object.keys(item)[0]], {
          data: can,
        })
      }
      if (dispatchByTable[Object.keys(item)[0]] === 'app/setCanbanOrder') {
        const ordrData = item[Object.keys(item)[0]]
        this.$store.dispatch(dispatchByTable[Object.keys(item)[0]], {
          data: {
            datetime: getNowSecods(),
            data: JSON.parse(ordrData[0].ord),
          },
        })
      }
    },
    async completeDrag(change: Iobject): Promise<void> {
      crmApi
        .sendAndGetData(change)
        .then((result: Iobject) => {
          console.log('completeDrag result', result)
          result.data.forEach((item: Iobject) => {
            this.resolveCallback(item)
          })
          this.defaultAfterDrag()
        })
        .catch((error: Iobject) => {
          console.warn('error completeDrag', error)
          this.defaultAfterDrag()
        })
    },
    getCanbanRequest(): Iobject {
      return {
        datetime: getNowSecods(),
        callbacks: [
          {
            table: tables.canbanData,
            ask: [
              {
                nameId: 'catId',
                id: 1,
                compare: '=',
                link: 'AND',
              },
              {
                nameId: 'status',
                id: this.sortByUser.status,
                compare: '=',
                link: '',
              },
            ],
            order: 'datetime',
          },
        ],
      }
    },
    getCanbanOrderRequest(): Iobject {
      return {
        datetime: getNowSecods(),
        callbacks: [
          {
            table: tables.canbanOrder,
            ask: [
              {
                nameId: 'catId',
                id: 1,
                compare: '=',
                link: '',
              },
            ],
            order: 'id',
          },
        ],
      }
    },
    async initialCanbanAndSortDataAndByStage(load: boolean, notFirstRequest?: boolean): Promise<void> {
      if (this.$route.name !== canbanRouteData.name) return
      if (this.getUser === null) {
        const isLogin = await this.isLogin()
        if (isLogin === false) return
      }
      if (this.isSorting === true) return

      this.$store.dispatch('app/setLoader', { data: true })

      await getUsers()

      this.isSorting = true
      if (this.error !== null) this.error = null

      this.resolveSortByUserFromUrl()

      const datetime = getNowSecods()
      const lastCanbanDataTime = this.getCanbanData?.datetime ?? 0
      if (!notFirstRequest && (load === false || datetime - lastCanbanDataTime < reloadCanbanInterval)) {
        this.$store.dispatch('app/setLoader', { data: false })
        this.sortCanban()
        return
      }

      let numberResponses = 0
      const limitiResponses = 1

      const order = await crmApi.sendAndGetData(this.getCanbanOrderRequest())
      this.$store.dispatch('app/setCanbanOrder', {
        data: {
          datetime,
          data: JSON.parse(order.data[0].canbanOrder[0].ord),
        },
      })

      await crmApi
        .sendAndGetData(this.getCanbanRequest())
        .then((result: Iobject) => {
          console.log('result getCanbanData', result)
          this.$store.dispatch('app/setCanbanData', {
            data: {
              datetime,
              data: getUnFlat(result.data[0].canbanDeals),
            },
          })
          this.sortCanban()
        })
        .catch((error: Iobject) => {
          console.warn('error crmApi.getCanbanData', error)
          this.error = true
        })
        .then(() => {
          setTimeout(() => {
            numberResponses += 1
            this.removeLoader(numberResponses, limitiResponses)
            this.isSorting = false
          }, 50)
        })

      const tasks = await crmApi.sendAndGetData(this.getTesksRequest())
      this.setTaskByDeal(tasks.data[0].canbanHistory)
    },
    setTaskByDeal(tasks: any): any {
      const sortedByIdTasks = tasks.sort((a: any, b: any) => b.id - a.id)
      const tasksByDealArr = {} as any

      sortedByIdTasks.forEach((task: any) => {
        if (tasksByDealArr[task.dealId]) return

        if (!tasksByDealArr[task.dealId]) tasksByDealArr[task.dealId] = task
      })

      this.tasksToDateByDeal = tasksByDealArr
    },
    iconByRemainTime(id: number | string): string {
      if (!this.tasksToDateByDeal[id]) return ''

      return getTaskIconStatus(this.tasksToDateByDeal[id].toDate)
    },
    getTesksRequest(): Iobject {
      const conbanDealsId = this.getCanbanData.data.map((deal: any) => deal.id)

      return {
        datetime: getNowSecods(),
        callbacks: [
          {
            table: tables.canbanHistory,
            ask: [
              {
                nameId: 'dealId',
                id: `(${conbanDealsId.join(',')})`,
                compare: 'IN',
                link: 'AND',
              },
              {
                nameId: 'type',
                id: 1,
                compare: '=',
                link: '',
              },
            ],
            order: 'toDate',
          },
        ],
      }
    },
    resolveSortByUserFromUrl(): void {
      const strGet = window.location.search.replace('?', '').split('&')
      strGet.forEach((item: string) => {
        const itemData = item.split('=')
        try {
          this.sortByUser[itemData[0]] = JSON.parse(decodeURI(itemData[1]))
        } catch (e) {
          this.sortByUser[itemData[0]] = decodeURI(itemData[1])
        }
      })
    },
    sortCanban(): void {
      this.error = false
      const can = filterCanbanByRoles(cloneObj(this.getCanbanData.data) as any, this.getUser)
      const ord = cloneObj(this.getCanbanOrder.data)
      this.sortedCanban = []
      this.canbanByStages = {}
      const canbanItemById: Iobject = {}
      const sortedIds: number[] = []

      can.forEach((item: Iobject) => {
        canbanItemById[item.id] = item
        sortedIds.push(item.id)
        this.$set(this.drag, item.id, {
          status: 'none',
          x: null,
          y: null,
          xM: null,
          yM: null,
        })
      })
      ord.forEach((id: number) => {
        if (canbanItemById[id]) {
          this.sortedCanban.push(canbanItemById[id])
        }
      })
      if (this.sortedCanban.length < can.length) {
        sortedIds
          .filter((x) => !ord.includes(x))
          .forEach((id: number) => {
            this.sortedCanban.unshift(canbanItemById[id])
          })
      }
      this.sortedCanban.forEach((item: Iobject) => {
        if (!this.canbanByStages[item['stage.type']]) this.canbanByStages[item['stage.type']] = []
        this.canbanByStages[item['stage.type']].push(item)
      })

      this.stages.forEach((stage: Iobject, index: number) => {
        this.$set(this.dragOverInesrtableColumn, index, null)
        this.$set(this.canban, index, this.canbanByStages[index] || [])
      })
      setTimeout(() => {
        this.isSorting = false
      }, 50)
    },
    mousemoveCanban(e: Iobject): void {
      this.dragGo(e)
    },
    gotoCanbanItem(id: number) {
      if (this.dragId !== null) return
      this.routeGo({ name: 'canbanDeal', params: { id } })
    },
    getRegion(id: number): string | boolean {
      if (!id) return false
      const region = r.filter((i: Iobject) => i.id === id)[0]
      return region ? region.name : false
    },
  },
} as Iobject
