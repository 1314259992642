import { getUnFlat } from './canban/functions'

const ACTIVE_USER = 1

export enum Roles {
  admin = 'admin',
  head = 'head',
  market = 'market',
  manager = 'manager',
  rop = 'rop',
}

export const seeSetUsers = [Roles.admin, Roles.head]

export const roles = Object.keys(Roles).map((key) => ({ name: key, value: key }))
export const activities = [
  { name: 'Активен', value: 1 },
  { name: 'Не активен', value: 0 },
]

export const filterCanbanByRoles = (canban: any[], getUser: any) => {
  if (getUser?.data?.active !== ACTIVE_USER) return []

  if (getUser.data.role === Roles.admin) return canban

  if (getUser.data.role === Roles.head) return canban

  if (getUser.data.role === Roles.rop) return canban

  if (getUser.data.role === Roles.market) return canban

  if (getUser.data.role === Roles.manager) {
    const getFiltered = canban.filter((deal: any) => deal['responsible.id'] === getUser.data.id)
    if (getFiltered.length) return getFiltered
  }

  return canban.filter((deal: any) => {
    const watchers = (getUnFlat([deal])[0] ?? []).watchers.map(({ watchersData }: any) => watchersData ?? null)
    if (!watchers.includes(getUser.data.id)) return false

    return true
  })
}

export const filterDealByRoles = (deal: any, getUser: any) => {
  if (getUser?.data?.active !== ACTIVE_USER) return []

  if (getUser.data.role === Roles.admin) return true

  if (getUser.data.role === Roles.head) return true

  if (getUser.data.role === Roles.rop) return true

  if (getUser.data.role === Roles.market) return true

  if (getUser.data.role === Roles.manager) {
    if (deal.data['responsible.id'] === getUser.data.id) return true
  }

  return (deal?.data?.watchers ?? []).map(({ watchersData }: any) => watchersData ?? null).includes(getUser.data.id)
}
