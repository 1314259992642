
import m from '@/mixins/m'
import { getNowSecods, getDate } from '@/utils'
import { tables } from '@/conf/tables'
import regions from '@/conf/deals/regions'
import CrmApi from '@/services/crmApi'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CustomInput from '@/components/atoms/CustomInput.vue'
import { CompanyContactsTypesSingle, CompanyContactsTypes } from '@/conf/routes/pages/addCompanyContacts'
import { getUnFlat } from '@/store/plugins/canban/functions'
import { getDealByContactOrCompany } from '@/store/plugins/generalFunctions'

interface Iobject {
  [key: string]: any
}

function compareSearch(a: Iobject, b: Iobject) {
  if (a.amount !== b.amount) {
    return (a.amount < b.amount) ? 1 : (a.amount > b.amount) ? -1 : 0
  }
  return (a.data.id < b.data.id) ? 1 : (a.data.id > b.data.id) ? -1 : 0
}

const crmApi = new CrmApi()

export default {
  name: 'SortCompanyContacts',
  components: {
    CustomInput,
    Header,
    Footer,
  },
  mixins: [m],
  data(): Iobject {
    return {
      search: {
        value: '',
        placeholder: 'Строка для поиска',
        timeout: 500,
        setTimeout: undefined,
        result: [],
        limit: 1000,
      },
      results: [
        { name: 'Сделки', type: tables.canbanData, linkType: 'canbanDeal' },
        { name: 'Компании', type: tables.company, linkType: CompanyContactsTypesSingle.Company },
        { name: 'Контакты', type: tables.contacts, linkType: CompanyContactsTypesSingle.Contact },
      ],
      result: {},
      searches: undefined,
      dealData: {
        [tables.company]: [],
        [tables.contacts]: [],
      },
    }
  },
  async mounted() {
    if (this.getUser === null) {
      const isLogin = await this.isLogin()
      if (isLogin === false) {
        this.routeGo({ name: 'auth' })
      }
    }
  },
  computed: {
    searchedData(): Iobject {
      if (this.search.result.length === 0) {
        return this.search.result
      }

      this.search.result.forEach((item: Iobject) => {
        if (!this.result[item.type]) {
          this.result[item.type] = {}
        }

        if (!this.result[item.type][item.v]) {
          this.result[item.type][item.v] = {}
        }

        item.value.data[0][item.type].forEach((i: Iobject) => {
          this.result[item.type][item.v][i.id] = i
        })
      })

      const ratedResult: Iobject = {}
      const ratedResultArray: Iobject = {}

      Object.keys(this.result).forEach((type: string) => {
        Object.keys(this.result[type]).forEach((key: string) => {
          if (!ratedResult[type]) {
            ratedResult[type] = {}
            ratedResultArray[type] = []
          }

          Object.keys(this.result[type][key]).forEach((item: string) => {
            if (!ratedResult[type][item]) {
              const unf = getUnFlat([this.result[type][key][item]])

              ratedResult[type][item] = {
                data: unf[0],
                id: this.result[type][key][item].id,
                amount: 1,
              }

              if (type === tables.canbanData) {
                ratedResult[type][item].company = unf[0]['deal.company'].map((i: Iobject) => i.addCompany)
                ratedResult[type][item].contacts = unf[0].contacts.map((i: Iobject) => i.addContacts)
              }

              if (type === tables.contacts) {
                const id = this.result[type][key][item].id

                Object.keys(ratedResult[tables.canbanData]).forEach((k: string) => {
                  if (ratedResult[tables.canbanData][k].contacts.includes(id)) {
                    ratedResult[tables.canbanData][k].amount += 1
                  }
                })
              }

              if (type === tables.company) {
                const id = this.result[type][key][item].id
                
                Object.keys(ratedResult[tables.canbanData]).forEach((k: string) => {
                  if (ratedResult[tables.canbanData][k].company.includes(id)) {
                    ratedResult[tables.canbanData][k].amount += 1
                  }
                })
              }
            } else {
              ratedResult[type][item].amount += 1
            }
          })
        })

        Object.keys(ratedResult[type]).forEach((key: string) => {
          ratedResultArray[type].push(ratedResult[type][key])
        })
      })

      Object.keys(ratedResultArray).forEach((type: string) => {
        ratedResultArray[type].sort(compareSearch)
        ratedResultArray[type] = ratedResultArray[type].slice(0, 10)
      })
      
      this.watchResult()

      return ratedResultArray
    },
  },
  methods: {
    async watchResult(): Promise<void> {
      if (!this.result[CompanyContactsTypes.Contacts]) return

      let company: any = []
      let contacts: any = []

      Object.keys(this.result[CompanyContactsTypesSingle.Company]).forEach((item: string) => {
        company = company
          .concat(Object.keys(this.result[CompanyContactsTypesSingle.Company][item]))
          .map((x: string) => Number(x))
      })

      Object.keys(this.result[CompanyContactsTypes.Contacts]).forEach((item: any) => {
        contacts = contacts
          .concat(Object.keys(this.result[CompanyContactsTypes.Contacts][item]))
          .map((x: string) => Number(x))
      })

      if (company.length) {
        this.dealData[tables.company] = await getDealByContactOrCompany(company, 'deal.company')
      }

      if (contacts.length) {
        this.dealData[tables.contacts] = await getDealByContactOrCompany(contacts, 'contacts')
      }
    },
    getLine(item: Iobject, type: string, result: Iobject, deal?: Iobject): string {
      const d = item.data
      const reg = regions.filter((i: Iobject) => i.id = d['deal.region'])[0]
      let persent = ((item.amount / this.searches) * 100).toFixed(0)
      persent = Number(persent) > 100 ? '100' : persent

      const link = this.$router.resolve({ name: result.linkType, params: { id: item.id } })

      if (type === tables.canbanData) {
        return `<span class="line-percent">${persent}%</span>
          <span class="line-id">#${d.id}</span>
          <span class="line-name">
            <a class="search__results-link"
              href="${link.href}"
              target="_blank"
            >
              ${this.getItemName(d)}
            </a>
          </span>
          <span class="line-purpose">${d['deal.purpose']}</span>
          <span class="line-size">${d['deal.size.l']} x ${d['deal.size.w']} x ${d['deal.size.h']}</span>
          <span class="line-material">${d['deal.material']}</span>
          <span class="line-city">${d['deal.city']}</span>
          <span class="line-region">${reg?.name ? reg.name : ''}</span>
          <span class="line-price">${d['deal.price']} ₽</span>
          <span class="line-datetime">${getDate(d.datetime)}</span>
          <span class="line-source">${d['deal.source']}</span>`
      }
      
      let dealHtml = ''
      if (deal && deal[type] && deal[type][d.id]) {
        const dealData = deal[type][d.id]

        dealHtml += '<span class="line-name">'

        dealData.forEach((dealItem: Iobject) => {
          const linkFromContactOrCompany = this.$router.resolve({ name: 'canbanDeal', params: { id: dealItem.id } })

          dealHtml += `<a class="search__results-link"
              href="${linkFromContactOrCompany?.href}"
              target="_blank"
            >
              ${dealItem.id}, ${dealItem['deal.name']}
            </a><br />`
        })

        dealHtml += '</span>'
      }

      if (type === tables.contacts) {
        return `<span class="line-percent">${persent}%</span>
          <span class="line-name">
            <a class="search__results-link"
              href="${link.href}"
              target="_blank"
            >
              ${this.getItemName(d, type)}
            </a>
          </span>
          ${dealHtml}
          <span class="line-department">${d.department}</span>
          <span class="line-position">${d.position}</span>
          <span class="line-info">${d.info}</span>
          <span class="line-phone">${d.phones.map((i: Iobject) => i.phone).join(', ')}</span>
          <span class="line-email">${d.emails.map((i: Iobject) => i.email).join(', ')}</span>
          <span class="line-messenger">${d.messengers.map((i: Iobject) => i.messenger).join(', ')}</span>`
      }

      if (type === tables.company) {
        return `<span class="line-percent">${persent}%</span>
          <span class="line-name">
            <a class="search__results-link"
              href="${link.href}"
              target="_blank"
            >
              ${this.getItemName(d, type)}
            </a>
          </span>
          ${dealHtml}
          <span class="line-info">${d.info}</span>
          <span class="line-phone">${d.phones.map((i: Iobject) => i.phone).join(', ')}</span>
          <span class="line-email">${d.emails.map((i: Iobject) => i.email).join(', ')}</span>
          <span class="line-site">${d.sites.map((i: Iobject) => i.site).join(', ')}</span>`
      }

      return ''
    },
    getItemName(item: Iobject, type: string): string {
      if (type === tables.company) {
        return (`${item.companyType} ${item.companyName}`).trim()
      }

      if (type === tables.contacts) {
        return (`${item.firstName} ${item.middleName} ${item.lastName}`).trim()
      }

      return item['deal.name']
    },
    searchKeyup(): void {
      if (this.search.setTimeout) {
        clearTimeout(this.search.setTimeout)
      }

      this.search.setTimeout = setTimeout(() => {
        this.searchKeyupBody()
      }, this.search.timeout)
    },
    async searchKeyupBody(): Promise<void> {
      this.search.result = []
      this.result = {}
      const v = this.search.value.toLowerCase().trim().replace(/ {1,}/g, ' ')

      if (v.length <= 1) return
      const valueArr = v.split(' ')
      this.searches = valueArr.length
      console.log('valueArr=', valueArr)

      this.$store.dispatch('app/setLoader', { data: true })
      valueArr.forEach(async (item: Iobject) => {
        await this.searchFunction(item)
      })
      this.$store.dispatch('app/setLoader', { data: false })
    },
    async searchFunction(value: string): Promise<void> {
      try {
        await this.getCANBAN(value)
        await this.getCOMPANY(value)
        await this.getCONTACT(value)
      } catch (error) {
        console.warn('search error', value, error)
      }
    },
    async getCONTACT(value: string): Promise<void> {
      try {
        const requestContact = this.getRequestContact(value)
        this.search.result.push({
          type: tables.contacts,
          v: value,
          value: await crmApi.sendAndGetData(requestContact),
        })
      } catch (error) {
        console.warn('search contact error', error)
      }
    },
    async getCOMPANY(value: string): Promise<void> {
      try {
        const requestCompany = this.getRequestCompany(value)
        this.search.result.push({
          type: tables.company,
          v: value,
          value: await crmApi.sendAndGetData(requestCompany),
        })
      } catch (error) {
        console.warn('search company error', error)
      }
    },
    async getCANBAN(value: string): Promise<void> {
      const getRegion: any = []
      regions.forEach((region: Iobject) => {
        if (region.id && region?.name.toLowerCase().indexOf(value) >= 0) getRegion.push(region.id)
      })

      try {
        if (Number(value)) {
          const requestCanbanDigit = this.getRequestCanbanDigit(value)
          this.search.result.push({
            type: tables.canbanData,
            preType: 'number',
            v: value,
            value: await crmApi.sendAndGetData(requestCanbanDigit),
          })
        }

        if (getRegion.length > 0) {
          const requestCanbanRegion = this.getRequestCanbanRegion(getRegion)
          this.search.result.push({
            type: tables.canbanData,
            preType: 'region',
            v: value,
            value: await crmApi.sendAndGetData(requestCanbanRegion),
          })
        }

        const requestCanbanString = this.getRequestCanbanString(value)
        this.search.result.push({
          type: tables.canbanData,
          v: value,
          value: await crmApi.sendAndGetData(requestCanbanString),
        })
      } catch (error) {
        console.warn('search canban error', error)
      }
    },
    getRequestContact(value: string): Iobject {
      return {
        datetime: getNowSecods(), 
        callbacks: [
          { 
            table: tables.contacts,
            ask: [
              {
                nameId: 'firstName',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'middleName',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'lastName',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'department',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'position',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'info',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'phones',
                id: `"%${String(value)}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'emails',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'messengers',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: '',
              },
            ],
            order: 'id', 
            desc: 'desc', 
            limit: `0,${this.search.limit}`,
          },
        ],
      }
    },
    getRequestCompany(value: string): Iobject {
      return {
        datetime: getNowSecods(), 
        callbacks: [
          { 
            table: tables.company,
            ask: [
              {
                nameId: 'companyName',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'companyType',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'info',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'phones',
                id: `"%${String(value)}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'emails',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'sites',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: '',
              },
            ],
            order: 'id', 
            desc: 'desc', 
            limit: `0,${this.search.limit}`,
          },
        ],
      }
    },
    getRequestCanbanRegion(getRegion: number[]): Iobject {
      if (getRegion.length === 0) return {}

      return {
        datetime: getNowSecods(), 
        callbacks: [
          { 
            table: tables.canbanData,
            ask: [
              {
                nameId: 'deal.region',
                id: `(${getRegion.join(',')})`,
                compare: 'IN',
                link: '',
              },
            ],
            order: 'id', 
            desc: 'desc', 
            limit: `0,${this.search.limit}`,
          },
        ],
      }
    },
    getRequestCanbanDigit(value: string): Iobject {
      return {
        datetime: getNowSecods(), 
        callbacks: [
          { 
            table: tables.canbanData,
            ask: [
              {
                nameId: 'deal.size.l',
                id: value,
                compare: '=',
                link: 'OR',
              },
              {
                nameId: 'deal.size.w',
                id: value,
                compare: '=',
                link: 'OR',
              },
              {
                nameId: 'deal.size.h',
                id: value,
                compare: '=',
                link: 'OR',
              },
              {
                nameId: 'deal.price',
                id: value,
                compare: '=',
                link: '',
              },
            ],
            order: 'id', 
            desc: 'desc', 
            limit: `0,${this.search.limit}`,
          },
        ],
      }
    },
    getRequestCanbanString(value: string): Iobject {
      return {
        datetime: getNowSecods(), 
        callbacks: [
          { 
            table: tables.canbanData,
            ask: [
              {
                nameId: 'deal.description',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'deal.name',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'deal.purpose',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'deal.city',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'deal.adress',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: 'OR',
              },
              {
                nameId: 'deal.info',
                id: `"%${value}%"`,
                compare: 'LIKE',
                link: '',
              },
            ],
            order: 'id', 
            desc: 'desc', 
            limit: `0,${this.search.limit}`,
          },
        ],
      }
    },
  },
} as Iobject
