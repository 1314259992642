import addPropDefaults from '@/conf/routes/functions'
import p from '@/conf/routes/properties'
import Canban from '@/components/pages/CanbanPage.vue'

export const CANBAN_ROUTE = '/canban'

const { properties } = p

const data: any = {
  path: CANBAN_ROUTE as string,
  name: 'canban' as string,
  component: Canban as any,
  [properties.secure.name]: properties.secure.types.on,
  [properties.changeProp.name]: properties.changeProp.types.user,
  [properties.needLoadData.name]: properties.needLoadData.types.on,
  footer: {
    status: false,
  },
}

addPropDefaults(properties, data)
export default data
