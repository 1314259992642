
import m from '@/mixins/m'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import AddEditCompanyContacts from '@/components/pages/AddEditCompanyContacts.vue'
import addCompanyContactsRouteData from '@/conf/routes/pages/addCompanyContacts'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'addCompanyContacts',
  mixins: [m],
  components: {
    Header,
    Footer,
    AddEditCompanyContacts,
  },
  data(): Iobject {
    return {
      d: { 
        id: this.$route.params.id,
        name: this.$route.name === 'company' 
          ? 'addCompany'
          : 'addContacts',
      },
    }
  },
  watch: {
    getRouteData(): void {
      this.init()
    },
  },
  methods: {
    async init(): Promise<void> {
      if (!addCompanyContactsRouteData.map((d: Iobject) => d.name).includes(this.$route.name)) return
      if (this.getUser === null) {
        this.$store.dispatch('app/setLoader', { data: false })
        await this.isLogin()
      }
    },
  },
} as Iobject
