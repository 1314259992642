import store from '@/store'
import { getNowSecods } from '@/utils'

interface Iobject {
  [key: string]: any
}

class CookiesApi {
    #timer = 10000

    #timeout: null | number = null

    #domen = 'https://crm.psktitan.ru'

    #cookieUrl = '/cookies.php'

    private static instance: CookiesApi

    constructor() {
      if (CookiesApi.instance) {
        return CookiesApi.instance
      }
      CookiesApi.instance = this
    }

    async sendLoginPassword(login: string, password: string): Promise<Iobject> {
      return this.request(this.#cookieUrl, { login, password })
    }

    async sendCookie(cookie: string): Promise<Iobject> {
      return this.request(this.#cookieUrl, { cookie })
    }

    async removeCookie(removeCookie: string): Promise<Iobject> {
      return this.request(this.#cookieUrl, { removeCookie })
    }

    async checkCookie(checkCookie: string): Promise<Iobject> {
      return this.request(this.#cookieUrl, { checkCookie })
    }

    resolveCookie(cookieResult: Iobject): boolean {
      if (cookieResult?.status !== 'ok') return false
      const datetime = getNowSecods()
      store.dispatch('app/setUser', {
        data: {
          datetime,
          data: cookieResult.data,
        },
      })
      return true
    }

    async request(url: string, params: Iobject): Promise<Iobject> {
      const data: string[] = []
      Object.keys(params).forEach((par: string) => {
        data.push(`${par}=${encodeURIComponent(params[par])}`)
      })
      
      return new Promise<Iobject>((resolve, reject) => {
        if (this.#timeout) clearTimeout(this.#timeout)
        this.#timeout = setTimeout(() => {
          throw new Error('timeout sendCookie')
        }, this.#timer)
      
        const xhr = new XMLHttpRequest()
        xhr.open('POST', `${this.#domen}${url}`, true)
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
        xhr.send(data.join('&'))
        xhr.onload = () => {
          if (this.#timeout) clearTimeout(this.#timeout)
          if (xhr.status === 200) {
            try {
              const r = JSON.parse(decodeURIComponent(xhr.response.replace(/\+/g, ' ')))
              resolve(r)
            } catch (err) {
              reject(new Error(JSON.stringify(err)))
            }
          } else {
            reject(new Error(JSON.stringify(xhr)))
          }
        }
        xhr.onerror = () => {
          reject(new Error(JSON.stringify(xhr)))
        }
      })
    }
}

export default CookiesApi
