interface Iobject {
    [key: string]: any
}
  
export const showAddsType = 1

export const defaultEnter: Iobject = {
  dealId: null,
  text: '',
  id: null,
  type: 0,
  toDate: null,
  responsible: null,
  files: [],
  userId: null,
  datetime: null,
  'edit.datetime': null,
}

export const defaultPropNames: Iobject = {
  toDate: 'Календарь',
  responsible: 'Ответственный',
}

export const messageTypes: Iobject = {
  0: {
    name: 'Примечание',
    toDate: false,
    responsible: false,
  },
  1: {
    name: 'Задача',
    toDate: true,
    responsible: true,
  },
}
