import addPropDefaults from '@/conf/routes/functions'
import p, { 
  IRouterType, 
  Names as propNames,
} from '@/conf/routes/properties'
import AddCompanyContacts from '@/components/pages/SortCompanyContacts.vue'
import { CompanyContactsTypes } from '@/conf/routes/pages/addCompanyContacts'

const { properties } = p

const company: IRouterType = {
  path: `/${CompanyContactsTypes.Companies}` as string,
  name: CompanyContactsTypes.Companies as string,
  component: AddCompanyContacts as any,
  props: { type: CompanyContactsTypes.Companies },
  [propNames.secure]: properties.secure.types.on,
  [propNames.changeProp]: properties.changeProp.types.user,
  [propNames.needLoadData]: properties.needLoadData.types.off,
  footer: {
    status: false,
  },
}

const contact: IRouterType = {
  path: `/${CompanyContactsTypes.Contacts}` as string,
  name: CompanyContactsTypes.Contacts as string,
  component: AddCompanyContacts as any,
  props: { type: CompanyContactsTypes.Contacts },
  [properties.secure.name]: properties.secure.types.on,
  [properties.changeProp.name]: properties.changeProp.types.user,
  [properties.needLoadData.name]: properties.needLoadData.types.off,
  footer: {
    status: false,
  },
}

addPropDefaults(properties, company)
addPropDefaults(properties, contact)

export default [company, contact]
