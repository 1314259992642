import { getNowSecods } from '@/utils'

interface Iobject {
    [key: string]: any
}

export const defaultMessagesAmount = 30

export const scrollingLoad = 50

export const scrollTimer = 300

export const errorMessage = (text: string): Iobject => ({
  text: `Ошибка при загрузке ${text}`,
  id: 0,
  type: null,
  toDate: null,
  responsible: null,
  files: [],
  userId: null,
  datetime: getNowSecods(),
})

export const startMessage = (datetime: number): Iobject => ({
  text: 'Создание сделки',
  id: 0,
  type: -1,
  toDate: null,
  responsible: null,
  files: [],
  userId: null,
  datetime,
})
