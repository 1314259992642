
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Error from '@/components/atoms/Error.vue'
import CanbanDealPageLeft from '@/components/pages/CanbanDealPageLeft.vue'
import CanbanDealPageRight from '@/components/pages/CanbanDealPageRight.vue'
import { filterDealByRoles } from '@/store/plugins/filterByRoles'
import { CANBAN_ROUTE } from '@/conf/routes/pages/canban'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'CanbanDeal',
  components: {
    Header,
    Footer,
    Error,
    CanbanDealPageLeft,
    CanbanDealPageRight,
  },
  data(): Iobject {
    return {}
  },
  mounted() {
    this.changeScale()
    window.addEventListener('resize', this.changeScale, false)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.changeScale)
  },
  watch: {},
  computed: {},
  methods: {
    emitDealAndUserForRoleResolve(emited: any): void {
      if (!filterDealByRoles(emited.deal, emited.getUser)) {
        this.$router.push({ path: CANBAN_ROUTE })
      }
    },
    changeScale(): void {
      const header = document.getElementById('header')
      const dealDody = document.getElementById('deal-body')

      if (!dealDody) return
      if (!header) return

      dealDody.style.height = `${document.documentElement.clientHeight - header.offsetHeight}px`
    },
  },
} as Iobject
