
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Columns from '@/components/pages/Columns.vue'
import m from '@/mixins/m'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'Canban',
  mixins: [m],
  components: {
    Header,
    Footer,
    Columns,
  },
  data(): Iobject {
    return {
      xScroll: null,
      xDiff: null,
      dealStatus: null,
      isCanbanDrag: null,
    }
  },
  mounted(): void {
    const columns: HTMLElement | null = document.getElementById('columns-scroll')
    if (!columns) return
    this.xDiff = columns.scrollLeft
  },
  methods: {
    isDrag(id: number | null): void {
      this.isCanbanDrag = id
    },
    dealStatusesMouseup(status: number): void {
      this.dealStatus = status
    },
    yScrollCanbanStart(e: Iobject): void {
      const columns: HTMLElement | null = document.getElementById('columns-scroll')
      if (!columns) return
      this.xScroll = e.clientX + columns.scrollLeft
    },
    yScrollCanbanStop(): void {
      this.xScroll = null
    },
    yScrollCanbanMove(e: Iobject): void {
      if (this.xScroll === null) return
      const columns: HTMLElement | null = document.getElementById('columns-scroll')
      if (!columns) return
      columns.scrollLeft = this.xScroll - e.clientX
      this.xDiff = columns.scrollLeft
    },
    mousemoveCanban(e: Iobject): void {
      this.yScrollCanbanMove(e)
    },
  },
} as Iobject
