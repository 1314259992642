import addPropDefaults from '@/conf/routes/functions'
import p from '@/conf/routes/properties'
import StatisticsWrap from '@/components/pages/StatisticsWrap.vue'

const { properties } = p

const data: any = {
  path: '/statistics' as string,
  name: 'statistics' as string,
  component: StatisticsWrap as any,
  [properties.secure.name]: properties.secure.types.on,
  [properties.changeProp.name]: properties.changeProp.types.user,
  [properties.needLoadData.name]: properties.needLoadData.types.on,
  footer: {
    status: false,
  },
}

addPropDefaults(properties, data)
export default data
