
import routes from '@/routes/index'
import store from '@/store'
import m from '@/mixins/m'
import cookie from '@/mixins/cookie'
import Load from '@/components/Load.vue'
// import p from '@/conf/routes/properties'
import PopupDialog from '@/components/pages/PopupDialog.vue'
import SearchBody from '@/components/atoms/SearchBody.vue'

import CookiesApi from '@/services/cookiesApi'
import { getCookie, userCookieName, userPeriodCookieName, loginOnStart } from '@/store/plugins/auth/getCookie'

// const { properties } = p
const cookiesApi = new CookiesApi()

interface Iobject {
  [key: string]: any
}

const getRouterItem = (r: Iobject, name: any): Iobject | boolean => {
  let result: Iobject | boolean = false
  for (let i = 0; i < routes.length; i += 1) {
    if (!r[i]) continue
    if (r[i].name === name) {
      result = r[i]
      break
    }
    if (r[i].children) result = getRouterItem(r[i].children, name)
  }
  return result
}

export default {
  name: 'app',
  mixins: [m, cookie],
  components: {
    Load,
    PopupDialog,
    SearchBody,
  },
  data() {
    return {
      search: { show: true },
    }
  },
  async mounted() {
    await loginOnStart()
  },
  watch: {
    $route(to: Iobject, from: Iobject): void {
      setTimeout(async () => {
        this.$store.dispatch('app/setRouteData', {
          data: getRouterItem(routes, to.name),
        })
      }, 0)
      console.log(from.name, '--', to.name)
    },
    async getState(state: string): Promise<void> {
      if (state === 'INITIAL') {
        this.$store.dispatch('app/setRouteData', {
          data: getRouterItem(routes, this.$route.name),
        })
      }
    },
    getRouteData(route: Iobject): void {
      console.log('route:', route)
      // const auth = properties.secure
      // if (!this.getUser && route[auth.name] !== auth.types.on) {
      //   if (auth.name !== 'auth') this.routeGo({ name: 'auth' })
      // }
      if (!route) this.routeGo({ name: 'error' })
      // if (this.debug.mode === 'prod') this.checkCookie()
    },
  },
  computed: {},
  methods: {
    hideSearch(): void {
      store.dispatch('app/setSearchShow', { data: false })
    },
    checkCookie(): void {
      const cookieData = getCookie(userCookieName) || null
      const cookiePeriodData = getCookie(userPeriodCookieName) || null
      if (cookieData !== null && cookiePeriodData === null) {
        cookiesApi.checkCookie(cookieData).then((result: Iobject) => {
          if (result.status === 'remove') this.removeCookie()
        })
      }
      if (cookieData === null && this.getUser) this.removeCookie()
    },
  },
} as Iobject
