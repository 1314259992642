

interface Iobject {
  [key: string]: any
}

export default {
  name: 'Error',
  props: {
    type: {
      default: 'pageError',
    },
  },
} as Iobject
