import { catId } from '@/conf/canbanData'

interface Iobject {
  [key: string]: any
}

export const defaultDeal: Iobject = {
  'deal.name': null,
  'deal.description': null,

  'deal.purpose': null,
  'deal.roof': null,
  'deal.size.l': null,
  'deal.size.w': null,
  'deal.size.h': null,
  'deal.material': null,

  'deal.additions.windows': [],
  'deal.additions.doors': [],
  'deal.additions.gates': [],
  'deal.additions.cranes': [],

  'deal.region': null,
  'deal.city': null,
  'deal.adress': null,

  'deal.price': null,
  'deal.priceParts.production': null,
  'deal.priceParts.delivery': null,
  'deal.priceParts.installation': null,

  'deal.company': [],
  contacts: [],

  'deal.source': null,
  'stage.type': 0,
  'responsible.id': null,
  watchers: [],
  'deal.info': '',
  status: 0,

  'stage.datetime': null,
  catId,
  datetime: null,
  active: 1,
}
