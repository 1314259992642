
import { PropType } from 'vue'
import CustomSelect from '@/components/atoms/CustomSelect.vue'
import { getUsers } from '@/store/plugins/generalFunctions'
import { purposes, statuses, filterPeriods, stageTypes, sources } from '@/conf/deals/selects'

import { cloneObj } from '@/utils'

import m from '@/mixins/m'

interface Iobject {
  [key: string]: any
}

export interface SortByUser {
  status: null | number | string
  'responsible.id': null | number | string
  'deal.name': null | string
  period: null | number
  'stage.type': null | number
}

interface Data {
  sortByUser: any
  [key: string]: any
}

export enum Period {
  month = 'Месяц',
  twoMonths = 'Два месяца',
}

const defaultSort = {
  status: null,
  'responsible.id': null,
  'deal.name': null,
  period: null,
  'stage.type': null,
  'deal.source': null,
}

export default {
  name: 'CanbanFilter',
  components: {
    CustomSelect,
  },
  mixins: [m],
  props: {
    header: {
      type: String,
      required: true,
    },
    hiddenFilters: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    defaultFilters: {
      type: Object as PropType<SortByUser> | PropType<null>,
      default: () => null,
    },
  },
  data(): Data {
    return {
      sortByUser: { ...defaultSort },
    }
  },
  async mounted() {
    if (this.defaultFilters) {
      this.sortByUser = this.defaultFilters
    }

    await getUsers()

    this.resolveSortByUserFromUrl()

    this.urlSortByUser(this.sortByUser)
  },
  computed: {
    stageTypes() {
      return [{ name: 'Любая', value: null }, ...stageTypes]
    },
    filterPeriods() {
      return [{ name: 'Любой', value: null }, ...filterPeriods]
    },
    statuses() {
      return [{ name: 'Любой', value: null }, ...statuses]
    },
    sources() {
      return [{ name: 'Любой', value: null }, ...sources]
    },
    purposes() {
      const res = cloneObj(purposes)
      res.unshift({
        name: 'Любой',
        value: null,
      })
      return res
    },
    responsibles() {
      if (!this.getUsers) return []
      const responsibles: Iobject[] = [
        {
          name: 'Любой',
          value: null,
        },
        {
          name: 'Не назначен',
          value: 0,
        },
      ]
      this.getUsers.data.forEach((user: Iobject) => {
        responsibles.push({
          name: `${user.firstName} ${user.lastName}`.trim(),
          value: user.id,
        })
      })
      return responsibles
    },
  },
  watch: {
    sortByUser: {
      deep: true,
      handler: 'urlSortByUser',
    },
    dealStatus(status: number): void {
      if (status === null) return
      this.setDealStatus(status)
    },
  },
  methods: {
    clearSearch(): void {
      this.sortByUser = {
        ...defaultSort,
      }
    },
    getUrlWithParams(val: Iobject): string {
      const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
      let newUrl = `${baseUrl}?`
      Object.keys(val).forEach((item: string) => {
        if (item.length === 0) return

        newUrl += `${item}=${val[item]}&`
      })
      return newUrl
    },
    urlSortByUser(val: Iobject): void {
      window.history.pushState(null, '', this.getUrlWithParams(val))

      this.$emit('setSortByUser', this.sortByUser)
    },
    resolveSortByUserFromUrl(): void {
      const strGet = window.location.search.replace('?', '').split('&')

      const newSortData = {} as any

      strGet.forEach((item: string) => {
        const itemData = item.split('=')
        const index = itemData[0]

        if (!index) return

        try {
          newSortData[index] = JSON.parse(decodeURI(itemData[1]))
        } catch (e) {
          newSortData[index] = decodeURI(itemData[1])
        }
      })

      Object.keys(newSortData).forEach((key: string) => {
        this.sortByUser[key] = newSortData[key]
      })
    },
  },
} as Iobject
