
import { defaultEnter } from '@/conf/messenger/enterData'
import { cloneObj, getNowSecods } from '@/utils'
import Enter from '@/components/messenger/Enter.vue'
import Messages from '@/components/messenger/Messages.vue'
import m from '@/mixins/m'
import {
  getMessages,
  convertMessage,
  unConvertMessage,
  inserMessage,
  changeMessage,
} from '@/store/plugins/messenger/functions'
import { defaultMessagesAmount, errorMessage, scrollTimer, startMessage } from '@/conf/messenger/messagesData'
import { tables } from '@/conf/tables'
import { getCanbanDeal, openPopupDialog } from '@/store/plugins/generalFunctions'

interface Iobject {
  [key: string]: any
}

function sortMessages(a: Iobject, b: Iobject) {
  return a.id < b.id ? 1 : a.id > b.id ? -1 : 0
}

export default {
  name: 'CanbanDealRight',
  mixins: [m],
  components: {
    Enter,
    Messages,
  },
  data(): Iobject {
    return {
      enter: cloneObj(defaultEnter),
      isEdit: null,
      messages: [],
      tasks: [],
      log: {
        status: false,
      },
      endedTasks: {
        status: false,
      },
      scrollTimer,
      scrollLoader: false,
      stopScrollLoad: false,
    }
  },
  watch: {
    getRouteData(): void {
      this.getDealMessages('tasks')
      this.getDealMessages('messages')
    },
  },
  methods: {
    overScroll(scrollTop: any): void {
      if (this.stopScrollLoad === true) return
      if (this.scrollLoader !== false) return
      this.scrollLoader = scrollTop
      this.getDealMessages('messages')
    },
    async getDealMessages(messagesType: string): Promise<void> {
      const dealId = this.$route.params.id
      const limit = `${this.messages.length},${defaultMessagesAmount}`
      getMessages({ dealId, limit, messagesType })
        .then((result: Iobject) => {
          const added: Iobject[] = []
          result.data[0][tables.canbanHistory].forEach((message: Iobject) => {
            added.push(convertMessage(message))
          })

          console.log('===result', result)

          if (messagesType === 'tasks') {
            this.tasks = added
            this.messages = this.messages.concat(this.tasks)
            return
          }
          const lastMessageAmount = this.messages.length
          this.messages = this.messages.concat(added)
          const nowMessageAmount = this.messages.length

          if (lastMessageAmount === nowMessageAmount) {
            this.stopScrollLoad = true
            getCanbanDeal(dealId).then(() => {
              const deal = this.$store.getters['app/getCanbanDeal']
              this.messages.push(startMessage(deal.data.datetime))
            })
          }
          this.messages.sort(sortMessages)
        })
        .catch((error: Error) => {
          console.warn('initialMessages error', error)
          this.tasks.push(errorMessage(messagesType))
        })
        .then(() => {
          setTimeout(() => {
            this.scrollLoader = false
          }, this.scrollTimer)
        })
    },
    enterMessage(enter: Iobject): void {
      if (enter.author === null) enter.author = this.getUser?.data?.id
      if (enter.dealId === null) enter.dealId = Number(this.$route.params.id)

      let modifiedEnter = cloneObj(enter)
      modifiedEnter = unConvertMessage(modifiedEnter)
      if (enter.id === null) this.inserMessage(modifiedEnter)
      if (enter.id !== null) this.changeMessage(modifiedEnter)
    },
    changeMessage(enter: Iobject): void {
      this.$store.dispatch('app/setLoader', { data: true })
      changeMessage(enter)
        .then((result: Iobject) => {
          const id = result.changed[0][tables.canbanHistory].id
          if (id !== enter.id) throw new Error(JSON.stringify(result))

          if ([1, 2].includes(enter.type)) {
            this.tasks.forEach((task: Iobject) => {
              if (task.id !== id) return
              Object.keys(task).forEach((key: string) => {
                task[key] = enter[key]
              })
              task = convertMessage(task)
            })
            return
          }

          this.messages.forEach((message: Iobject) => {
            if (message.id !== id) return
            Object.keys(message).forEach((key: string) => {
              message[key] = enter[key]
            })
            message = convertMessage(message)
          })
        })
        .catch((error: Error) => {
          console.warn('error changeMessage', error)
          openPopupDialog('Ошибка при редактировании сообщения', [{ name: 'Ok', result: true }])
        })
        .then(() => {
          this.$store.dispatch('app/setLoader', { data: false })
          this.enter = cloneObj(defaultEnter)
          this.isEdit = false
        })
    },
    inserMessage(enter: Iobject): void {
      this.$store.dispatch('app/setLoader', { data: true })
      inserMessage(enter)
        .then((result: Iobject) => {
          try {
            const id = result.inserted[0][tables.canbanHistory]
            enter.id = id
            enter.datetime = getNowSecods()
            if (enter.type === 1) {
              this.tasks.unshift(convertMessage(enter))
            } else {
              this.messages.unshift(convertMessage(enter))
            }
          } catch (err) {
            throw new Error(err as string)
          }
        })
        .catch((error: Error) => {
          console.warn('error inserMessage', error)
          openPopupDialog('Ошибка при добавлении сообщения', [{ name: 'Ok', result: true }])
        })
        .then(() => {
          this.$store.dispatch('app/setLoader', { data: false })
          this.enter = cloneObj(defaultEnter)
        })
    },
    stopEdit(): void {
      this.isEdit = false
    },
    startEdit(id: number): void {
      this.isEdit = true
      let edit = this.messages.find((mes: Iobject) => mes.id === id) || null
      if (!edit) edit = this.tasks.find((mes: Iobject) => mes.id === id) || null
      this.enter = cloneObj(edit)
    },
  },
} as Iobject
