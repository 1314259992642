
import m from '@/mixins/m'
import messages from '@/mixins/messages'
import { getDate, getNowSecods } from '@/utils'
import { scrollingLoad } from '@/conf/messenger/messagesData'
import { convertToBr } from '@/store/plugins/generalFunctions'
import TasksBlock from '@/components/messenger/TasksBlock.vue'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'Messages',
  mixins: [m, messages],
  components: {
    TasksBlock,
  },
  props: {
    messages: {
      required: true,
    },
    tasks: {
      required: true,
    },
    isEdit: {
      required: true,
    },
    scrollLoader: {
      required: true,
    },
    log: {
      required: true,
    },
    endedTasks: {
      required: true,
    },
  },
  data(): Iobject {
    return {
      editId: null,
      isScroll: {
        status: false,
        timer: 500,
        timeout: null,
        text: null,
        pxInterval: 100,
        offsetDelay: 60 * 60 * 24,
      },
    }
  },
  methods: {
    isMessageShow(mes: Iobject): boolean {
      if (mes.type === 1 || (mes.type === 2 && mes.type - this.offsetDelay > getNowSecods())) return false

      return mes.type !== -1 || (mes.type === -1 && this.log.status === true)
    },
    changeMessage(changed: Iobject): void {
      this.$emit('changeMessage', changed)
    },
    startEdit(val: number): void {
      this.editId = val
    },
    getTextWithBr(text: string): string {
      return convertToBr(text)
    },
    getScrollingData(e: Iobject): number {
      const elem: HTMLElement | null = document.getElementById('messages')
      if (!elem) return 0
      const box = elem.getBoundingClientRect()
      return (e.target.scrollTop - box.height - scrollingLoad) * -1
    },
    scrollVisibleData(e: Iobject): void {
      if (this.isScroll.status === false) this.isScroll.status = true
      if (this.isScroll.timeout) clearTimeout(this.isScroll.timeout)
      this.isScroll.timeout = setTimeout(() => {
        this.isScroll.status = false
      }, this.isScroll.timer)

      const children = document.querySelectorAll('#messages > .messages__item') || []
      const scrolling = this.getScrollingData(e)

      const elem: HTMLElement | null = document.getElementById('messages')
      if (!elem) return 
      const box = elem.getBoundingClientRect()

      let index: number | null = null
      children.forEach((item: Iobject, ind: number) => {
        if (index !== null) return
        const offset = item.offsetTop * -1
        if (offset > scrolling - box.height - this.isScroll.pxInterval) index = ind
      })
      if (index && this.messages[index]) {
        this.isScroll.text = getDate(this.messages[index].datetime)
      } else {
        this.isScroll.text = null
      }
    },
    getScroll(e: Iobject): void {
      this.scrollVisibleData(e)

      if (this.scrollLoader !== false) {
        e.target.scrollTop = this.scrollLoader
        return
      }
      const elem: HTMLElement | null = document.getElementById('messages')
      if (!elem) return
      if (this.getScrollingData(e) > elem.scrollHeight) {
        this.$emit('overScroll', e.target.scrollTop)
      }
    },
  },
} as Iobject
