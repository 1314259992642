
import m from '@/mixins/m'

import { openPopupDialog } from '@/store/plugins/generalFunctions'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CustomInput from '@/components/atoms/CustomInput.vue'

import CookiesApi from '@/services/cookiesApi'

import { getNowSecods } from '@/utils'

const cookiesApi = new CookiesApi()

interface Iobject {
  [key: string]: any
}

export default {
  name: 'Auth',
  mixins: [m],
  components: {
    Header,
    Footer,
    CustomInput,
  },
  data(): Iobject {
    return {
      auth: {
        login: '',
        password: '',
      },
      lastAuth: {
        login: '',
        password: '',
      },
      authValid: {
        login: 4,
        password: 5,
      },
    }
  },
  mounted(): void {
    document.addEventListener('keydown', this.pressEnter)
  },
  beforeDestroy(): void {
    document.removeEventListener('keydown', this.pressEnter)
  },
  computed: {
    validBtn(): boolean {
      const lenLogin = this.auth.login.length >= this.authValid.login
      const lastLogin = this.auth.login !== this.lastAuth.login

      const lenPassword = this.auth.password.length >= this.authValid.password
      const lastPassword = this.auth.password !== this.lastAuth.password

      return lenLogin && lenPassword && (lastLogin || lastPassword)
    },
  },
  methods: {
    sendAuth(): void {
      this.$store.dispatch('app/setLoader', { data: true })
      
      this.lastAuth.login = this.auth.login
      this.lastAuth.password = this.auth.password

      cookiesApi.sendLoginPassword(this.auth.login, this.auth.password)
        .then((result: Iobject) => {
          console.log('auth result', result)
          if (result.status !== 'ok') {
            const answers = [{ name: 'Ok', result: true }]
            openPopupDialog('Ошибочные данные', answers)
            throw new Error('Ошибка ввода данных')
          }

          const datetime = getNowSecods()
          this.$store.dispatch('app/setUser', {
            data: {
              datetime,
              data: result.data,
            },
          })
          this.routeBack(-1)
        }).catch((error: string) => {
          console.log('Ошибка авторизации', error)
        }).then(() => {
          this.$store.dispatch('app/setLoader', { data: false })
        })
    },
    pressEnter(e: Iobject): void {
      if (e.keyCode === 13 && this.validBtn) this.sendAuth()
    },
  },
} as Iobject
