import { userCookieName } from '@/store/plugins/auth/getCookie'
import CookiesApi from '@/services/cookiesApi'
import { getDevGetData } from '@/store/plugins/auth/GetDevGetData'

interface Iobject {
    [key: string]: any
}

class CrmApi {
    #timer = 10000

    #timeout: null | number = null

    #url = 'https://crm.psktitan.ru'

    #CookiesApi = new CookiesApi()
    
    private static instance: CrmApi

    constructor() {
      if (CrmApi.instance) {
        return CrmApi.instance
      }

      CrmApi.instance = this
    }

    async removeCookie(): Promise<void> {
      this.#CookiesApi.removeCookie(userCookieName)
        .then((result: Iobject) => {
          if (result.status !== 'ok') throw new Error(JSON.stringify(result))
          window.location.reload()
        }).catch((error: Error) => {
          console.log('removeCookie error', error)
        })
    }

    async getCanbanData(catId: number): Promise<Iobject> {
      return new Promise<Iobject>((resolve) => {
        if (this.#timeout) clearTimeout(this.#timeout)
        this.#timeout = setTimeout(() => {
          throw new Error('timeout getCanbanData')
        }, this.#timer)

        const xhr = new XMLHttpRequest()
        xhr.open('POST', `${this.#url}/post/getCanbanData.php${getDevGetData()}`, true)
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
        xhr.send(`catId=${catId}`)
        xhr.onload = () => {
          if (this.#timeout) clearTimeout(this.#timeout)
          if (xhr.status === 200) {
            try {
              const r = JSON.parse(decodeURIComponent(xhr.response.replace(/\+/g, ' ')))
              resolve(r)
            } catch (err) {
              throw new Error(JSON.stringify(err))
              // reject(new Error(JSON.stringify(err)))
            }
          } else {
            this.removeCookie()
            throw new Error(JSON.stringify(xhr))
            // reject(new Error(JSON.stringify(xhr)))
          }
        }
        xhr.onerror = () => {
          throw new Error(JSON.stringify(xhr))
          // reject(new Error(JSON.stringify(xhr)))
        }
      })
    }

    async sendAndGetData(changes: Iobject): Promise<Iobject> {
      return new Promise<Iobject>((resolve, reject) => {
        if (this.#timeout) clearTimeout(this.#timeout)
        this.#timeout = setTimeout(() => {
          throw new Error('timeout sendAndGetData')
        }, this.#timer)
        
        const changesEncoded = encodeURIComponent(JSON.stringify(changes))
        const xhr = new XMLHttpRequest()
        xhr.open('POST', `${this.#url}/post/requests.php${getDevGetData()}`, true)
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
        xhr.send(`changes=${changesEncoded}`)
        xhr.onload = () => {
          if (this.#timeout) clearTimeout(this.#timeout)
          if (xhr.status === 200) {
            try {
              let response = xhr.response
              response = response.replace(/\+/g, ' ')
              const r = JSON.parse(decodeURIComponent(response))
              resolve(r)
            } catch (err) {
              this.removeCookie()
              reject(new Error(JSON.stringify(err)))
            }
          } else {
            reject(new Error(JSON.stringify(xhr)))
          }
        }
        xhr.onerror = () => {
          reject(new Error(JSON.stringify(xhr)))
        }
      })
    }
}

export default CrmApi
