var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"stat"},[_c('div',{staticClass:"stat__item stat__left"},[_c('div',{staticClass:"stat__filter filter"},_vm._l((_vm.filter),function(f){return _c('div',{key:f.color,staticClass:"filter__item"},[_c('div',{staticClass:"filter__percent"},[_vm._v(_vm._s(Math.round(_vm.getDivide(f.number, _vm.filter[f.numberBase].number) * 100))+"%")]),_c('div',{staticClass:"filter__percent-body",style:({
            background: f.color,
            flex: `0 0 ${_vm.getDivide(f.number, _vm.filter[f.numberBase].number) * _vm.maxFilterWidthPercent}%`,
          })})])}),0)]),_c('div',{staticClass:"stat__item stat__center"},_vm._l((_vm.filter),function(f){return _c('div',{key:f.color,staticClass:"filter__item filter__item-right"},[_c('p',{staticClass:"filter__item_title"},[_vm._v(_vm._s(f.name))]),_c('p',{staticClass:"filter__item_data item-data"},[_vm._v(" "+_vm._s(f.number)+" сделок на "),_c('span',{staticClass:"item-data__sum"},[_vm._v(_vm._s(f.sum.toLocaleString('ru'))+" ₽")])])])}),0),_c('div',{staticClass:"stat__item stat__right"},[_c('div',{staticClass:"stat__filter filter"},_vm._l((_vm.filter),function(f){return _c('div',{key:f.color,staticClass:"filter__item filter__item-right"},[_c('div',{staticClass:"filter__percent-body",style:({
            background: f.color,
            flex: `0 0 ${_vm.getDivide(f.sum, _vm.filter[0].sum) * _vm.maxFilterWidthPercent}%`,
          })}),_c('div',{staticClass:"filter__percent"},[_vm._v(_vm._s(Math.round(_vm.getDivide(f.sum, _vm.filter[0].sum) * 100))+"%")])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }