
import { PropType } from 'vue'
import m from '@/mixins/m'
import CrmApi from '@/services/crmApi'
import { canbanStages, catId } from '@/conf/canbanData'
import { cloneObj, getNowSecods } from '@/utils'
import r from '@/conf/deals/regions'
import { tables } from '@/conf/tables'
import { filterCanbanByRoles } from '@/store/plugins/filterByRoles'
import { getUnFlat } from '@/store/plugins/canban/functions'
import Statistics from './Statictics.vue'
import { SortByUser } from './CanbanFilter.vue'

const crmApi = new CrmApi()

interface Iobject {
  [key: string]: any
}

const MONTH = 60 * 60 * 24 * 30

export default {
  name: 'RegistryBody',
  components: {
    Statistics,
  },
  mixins: [m],
  data(): any {
    return {
      sortByUserData: {
        'deal.name': null,
        'responsible.id': null,
        status: null,
        period: null,
        'stage.type': null,
      },
      catId,
      contacts: {},
      COMPANIES: 'company',
      CONTACTS: 'contacts',
      canbanStages,
    }
  },
  props: {
    sortByUserChanged: {
      type: Object as PropType<SortByUser>,
      required: true,
    },
    defaultFilters: {
      type: Object as PropType<SortByUser> | PropType<null>,
      default: () => null,
    },
  },
  async mounted() {
    if (this.defaultFilters) {
      this.sortByUserData = this.defaultFilters
    }

    await this.loadCanbanData()

    this.sortByUserChangedFn()
  },
  watch: {
    sortByUserChanged: {
      deep: true,
      handler: 'sortByUserChangedFn',
    },
  },
  computed: {
    getSortedDeals() {
      if (!this.getCanbanData) return []

      const now = Math.round(Number(new Date()) / 1000)

      const can = filterCanbanByRoles(cloneObj(this.getCanbanData.data) as any, this.getUser)

      return can
        .filter((item: any) => {
          let isMatch = true
          Object.keys(this.sortByUserData).forEach((key: any) => {
            if (!isMatch) return
            if (this.sortByUserData[key] === null) return

            if (key === 'period') {
              if (item.datetime < now - this.sortByUserData[key] * MONTH) isMatch = false
            } else if (item[key] !== this.sortByUserData[key]) isMatch = false
          })

          return isMatch
        })
        .reverse()
    },
  },
  methods: {
    gotoDeal(id: number) {
      this.routeGo({ name: 'canbanDeal', params: { id } })
    },
    addNewEmptyContact(dealId: number) {
      if (!this.contacts[dealId]) this.$set(this.contacts, dealId, { [this.COMPANIES]: {}, [this.CONTACTS]: [] })
    },
    addContacts(type: string, data: Iobject[], dealId: number) {
      this.addNewEmptyContact(dealId)

      this.contacts[dealId][type] = data
    },
    addCompanies(type: string, data: Iobject[], dealId: number) {
      this.addNewEmptyContact(dealId)

      this.contacts[dealId][type] = data.map((d: Iobject) => d.companyName)
    },
    getCompanies(item: any) {
      const request: Iobject = {
        datetime: getNowSecods(),
        callbacks: [],
      }
      request.callbacks.push({
        table: tables.company,
        nameId: 'id',
        id: `(${item['deal.company'].map((c: any) => c.addCompany).join(',')})`,
        compare: 'IN',
      })
      crmApi
        .sendAndGetData(request)
        .then((result: Iobject) => {
          const c = getUnFlat(result.data[0][tables.company])
          if (c.length === 0) return

          this.addCompanies(this.COMPANIES, c, item.id)
        })
        .catch((error: Error) => {
          console.warn('error get companies data', error)
        })
    },
    getContacts(item: any) {
      const request: Iobject = {
        datetime: getNowSecods(),
        callbacks: [],
      }
      request.callbacks.push({
        table: tables.contacts,
        nameId: 'id',
        id: `(${item.contacts.map((c: any) => c.addContacts).join(',')})`,
        compare: 'IN',
      })
      crmApi
        .sendAndGetData(request)
        .then((result: Iobject) => {
          const c = getUnFlat(result.data[0][tables.contacts])
          if (c.length === 0) return

          this.addContacts(this.CONTACTS, c, item.id)
        })
        .catch((error: Error) => {
          console.warn('error get contacts data', error)
        })
    },
    downloadContacts(item: any) {
      if (item['deal.company'].length > 0) this.getCompanies(item)
      if (item.contacts.length > 0) this.getContacts(item)
    },
    getRequest(table: string, type: Iobject) {
      const request: Iobject = {
        datetime: getNowSecods(),
        callbacks: [],
      }
      if (type.type === 'init') {
        request.callbacks.push({
          table,
          order: 'id',
          desc: 'desc',
          limit: `${this.result[this.type].length},${this.limitPage}`,
        })
      }
      if (type.type === 'getCompanies') {
        request.callbacks.push({
          table,
          nameId: 'id',
          id: type.companyIds,
          compare: 'IN',
        })
      }
      return request
    },
    getRegion(id: number | null) {
      if (id === null) return 'Не указан'

      return r.find((reg: any) => reg.id === id)?.name ?? 'Не указан'
    },
    sortByUserChangedFn() {
      this.resolveSortByUserFromUrl()
    },
    resolveSortByUserFromUrl(): void {
      const strGet = window.location.search.replace('?', '').split('&')

      const newSortData = {} as any

      strGet.forEach((item: string) => {
        const itemData = item.split('=')
        const index = itemData[0]

        if (!index) return

        try {
          newSortData[index] = JSON.parse(decodeURI(itemData[1]))
        } catch (e) {
          newSortData[index] = decodeURI(itemData[1])
        }
      })

      Object.keys(newSortData).forEach((key: any) => {
        this.sortByUserData[key] = newSortData[key]
      })
    },
    async loadCanbanData() {
      this.$store.dispatch('app/setLoader', { data: true })

      const datetime = getNowSecods()

      return new Promise(resolve => {
        crmApi
          .getCanbanData(this.catId)
          .then((result: Iobject) => {
            console.log('result getCanbanData', result)
            this.$store.dispatch('app/setCanbanData', {
              data: {
                datetime,
                data: getUnFlat(result.data.deals),
              },
            })
          })
          .catch((error: Iobject) => {
            console.warn('error crmApi.getCanbanData', error)
            this.error = true
          })
          .then(() => {
            this.$store.dispatch('app/setLoader', { data: false })

            resolve(true)
          })
      })
    },
  },
} as Iobject
