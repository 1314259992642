
import { 
  showAddsType,
  defaultEnter,
  defaultPropNames,
  messageTypes,
} from '@/conf/messenger/enterData'
import m from '@/mixins/m'
import CustomTextarea from '@/components/atoms/CustomTextarea.vue'
import CustomSelect from '@/components/atoms/CustomSelect.vue'
import DatePicker from '@/components/atoms/DatePicker.vue'
import AddFiles from '@/components/atoms/AddFiles.vue'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'Enter',
  mixins: [m],
  components: {
    CustomTextarea,
    CustomSelect,
    DatePicker,
    AddFiles,
  },
  props: {
    enter: {
      required: true,
    },
    edit: {
      default: null,
    },
    log: {
      required: true,
    },
    endedTasks: {
      required: true,
    },
  },
  data(): Iobject {
    return {
      showAddsType,
      defaultEnter,
      defaultPropNames,
      messageTypes,
      showDatePicker: false,
    }
  },
  mounted(): void {
    document.addEventListener('click', this.documentClick)
  },
  beforeDestroy(): void {
    document.removeEventListener('click', this.documentClick)
  },
  computed: {
    responsibles(): Iobject[] {
      if (!this.getUsers) return []
      const responsibles: Iobject[] = []
      this.getUsers.data.forEach((user: Iobject) => {
        responsibles.push({
          name: `${user.firstName} ${user.lastName}`.trim(), 
          value: user.id,
        })
      })
      return responsibles
    },
    valid(): boolean {
      if (this.enter.type === 0 && this.enter.text.length > 0) return true
      if (this.enter.type === 1) {
        const text = this.enter.text.length > 0
        const toDate = this.enter.toDate !== null
        const responsible = this.enter.responsible > 0
        if (text && toDate && responsible) return true
      }
      return false
    },
    activeType(): Iobject {
      let a: Iobject = {}
      Object.keys(this.messageTypes).forEach((key: string) => {
        if (Number(key) === this.enter.type) a = this.messageTypes[key]
      })
      return a
    },
    notActiveType(): Iobject {
      let a: Iobject = {}
      Object.keys(this.messageTypes).forEach((key: string) => {
        if (Number(key) !== this.enter.type) a = this.messageTypes[key]
      })
      return a
    },
  },
  methods: {
    deleteFile(delIndex: number): void {
      const files: Iobject[] = []
      this.enter.files.forEach((file: Iobject, index: number) => {
        if (index !== delIndex) files.push(file)
      })
      this.enter.files = files
    },
    enterMessage(): void {
      this.enter.userId = this.getUser.data.id
      this.$emit('enterMessage', this.enter)
    },
    documentClick(e: Iobject): void {
      const datePicker = e.target.closest('#datePicker')
      const datePickerStart = e.target.closest('#datePickerStart')
      if (datePicker === null && datePickerStart === null) this.showDatePicker = false
    },
    changePickData(d: Iobject): void {
      this.enter.toDate = d
    },
    changeEnterType(): void {
      if (this.enter.type === 0) {
        this.enter.type = 1
        this.enter.toDate = this.defaultEnter.toDate
        this.enter.responsible = this.defaultEnter.responsible
      } else {
        this.enter.type = 0
      }
      this.showDatePicker = false
    },
    stopEdit(): void {
      Object.keys(this.enter).forEach((key: string) => {
        this.enter[key] = this.defaultEnter[key]
      })
      this.showDatePicker = false
      this.$emit('stopEdit')
    },
  },
} as Iobject
