import main from '@/conf/routes/pages/main'
import canban from '@/conf/routes/pages/canban'
import registry from '@/conf/routes/pages/registry'
import canbanDeal from '@/conf/routes/pages/canbanDeal'
import addEditDeal from '@/conf/routes/pages/addEditDeal'
import error from '@/conf/routes/pages/error'
import auth from '@/conf/routes/pages/auth'
import companyContacts from '@/conf/routes/pages/companyContacts'
import addCompanyContacts from '@/conf/routes/pages/addCompanyContacts'
import sortCompanyContacts from '@/conf/routes/pages/sortCompanyContacts'
import search from '@/conf/routes/pages/search'
import statistics from '@/conf/routes/pages/statistics'
import setUsers from '@/conf/routes/pages/setUsers'

const routes: any = [
  main,
  canban,
  registry,
  statistics,
  canbanDeal,
  ...addEditDeal,
  error,
  auth,
  companyContacts,
  ...addCompanyContacts,
  ...sortCompanyContacts,
  setUsers,
  search,
]

export default routes
