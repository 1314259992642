import store from '@/store'
import CrmApi from '@/services/crmApi'
import { reloadUsersInterval, reloadCanbanDealInterval } from '@/conf/reloadTimers'
import { tables } from '@/conf/tables'
import { getUnFlat, getFlat } from '@/store/plugins/canban/functions'
import { getNowSecods } from '@/utils'

const crmApi = new CrmApi()

interface Iobject {
  [key: string]: any
}

export async function getDealByContactOrCompany(
  idArr: number[],
  type: 'contacts' | 'deal.company'
): Promise<Iobject | undefined> {
  const addName = type === 'contacts' ? 'addContacts' : 'addCompany'

  const ask = idArr.map((item: number, i: number) => ({
    nameId: type,
    id: `"%${item}%"`,
    compare: 'LIKE',
    link: i < idArr.length - 1 ? 'OR' : '',
  }))

  const request = {
    datetime: getNowSecods(),
    callbacks: [
      {
        table: tables.canbanData,
        ask: [...ask],
        order: 'id',
        desc: 'desc',
      },
    ],
  }

  return crmApi
    .sendAndGetData(request)
    .then((result: Iobject) => {
      try {
        const r = result.data[0][tables.canbanData]

        const resultArr = r.map((item: Iobject) => getUnFlat([item])[0])

        const resultObj: Iobject = {}

        idArr.forEach((id) => {
          resultObj[id] = resultArr.filter(
            (deal: Iobject) => deal[type].filter((item: Iobject) => item[addName] === id)[0]
          )
        })

        return resultObj
      } catch (error) {
        throw new Error(error as string)
      }
    })
    .catch((error: Error) => {
      console.warn('error getDealByContactOrCompany', error)
      return undefined
    })
}

export function convertToBr(text: string): string {
  if (typeof text !== 'string') return text

  return text.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

export function getChangeRequest(data: Iobject[]): Iobject {
  const actions: Iobject[] = []
  let i = 0
  data.forEach((c: Iobject) => {
    const flat = getFlat({}, c.data, '', ['id', 'datetime'])
    const items: Iobject = []
    Object.keys(flat).forEach((key: string) => {
      items.push({ name: key, value: flat[key] })
    })
    actions[i] = {
      table: c.table,
      id: c.id,
      nameId: c.nameId,
      type: 'change',
      items,
    }
    i += 1
  })
  const changeRequest: Iobject = {
    actions,
    datetime: getNowSecods(),
  }
  return changeRequest
}

export function getInsertRequest(data: Iobject[], table: string): Iobject {
  const insert: Iobject[] = []
  data.forEach((da: Iobject, i: number) => {
    insert[i] = []
    Object.keys(da).forEach((key: string) => {
      if (key === 'id') return
      let d = da[key]
      d = Array.isArray(d) ? JSON.stringify(d).replace(/null/g, '""') : d
      insert[i].push({
        name: key,
        value: d === null ? '' : d,
      })
    })
  })
  const actions: Iobject[] = []
  insert.forEach((i: Iobject) => {
    actions.push({ table, type: 'insert', items: i })
  })
  const insertRequest: Iobject = {
    actions,
    datetime: getNowSecods(),
  }
  return insertRequest
}

let resolvePopup: any = null
export function resolveOpenPopupDialog(status: string | boolean): void {
  resolvePopup(status)
}
export function openPopupDialog(question: string, answers: Iobject | null = null): Promise<any> {
  if (answers === null) {
    answers = [
      { name: 'Да', result: true },
      { name: 'Нет', result: false },
    ]
  }
  store.dispatch('app/setPopupDialog', {
    data: { question, answers },
  })
  return new Promise<any>((resolve: any) => {
    resolvePopup = resolve
  }).then((result: any) => {
    store.dispatch('app/setPopupDialog', { data: null })
    return result
  })
}

export async function getCanbanDeal(id: number): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    const datetime = store.getters['app/getCanbanDeal']?.datetime
    const isDealId = store.getters['app/getCanbanDeal']?.data?.id || -1
    if (isDealId !== id || !datetime || datetime < getNowSecods() - reloadCanbanDealInterval) {
      const getCanbanDealById: Iobject = {
        datetime: getNowSecods(),
        callbacks: [
          {
            table: tables.canbanData,
            nameId: 'id',
            id,
            compare: '=',
          },
        ],
      }
      crmApi
        .sendAndGetData(getCanbanDealById)
        .then((result: Iobject) => {
          console.log('getCanbanDealById', result)
          store.dispatch('app/setCanbanDeal', {
            data: {
              datetime: getNowSecods(),
              data: getUnFlat(result.data[0][tables.canbanData])[0],
            },
          })
          resolve(true)
        })
        .catch((error: Error) => {
          reject(error)
        })
    } else {
      resolve(false)
    }
  })
}

export async function getUsers(): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    const datetime = store.getters['app/getUsers']?.datetime
    if (!datetime || datetime < getNowSecods() - reloadUsersInterval) {
      const getAllUsers: Iobject = {
        datetime: getNowSecods(),
        callbacks: [{ table: tables.users }],
      }
      crmApi
        .sendAndGetData(getAllUsers)
        .then((result: Iobject) => {
          store.dispatch('app/setUsers', {
            data: {
              datetime: getNowSecods(),
              data: result.data[0].users.filter((u: Iobject) => u.active === 1),
            },
          })
          resolve(true)
        })
        .catch((error: Error) => {
          reject(error)
        })
    } else {
      resolve(false)
    }
  })
}
