
import m from '@/mixins/m'
import { 
  sortFields,
  sortFieldsArray,
  sortFieldsArrayItems,
  linkByType,
} from '@/conf/sortCompanyContacts/sortData'
import { CompanyContactsTypes } from '@/conf/routes/pages/addCompanyContacts'
import { tables } from '@/conf/tables'
import { getNowSecods, cloneObj } from '@/utils'
import CrmApi from '@/services/crmApi'
import { getUnFlat } from '@/store/plugins/canban/functions'
import { getDealByContactOrCompany } from '@/store/plugins/generalFunctions'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CustomInput from '@/components/atoms/CustomInput.vue'

interface Iobject {
  [key: string]: any
}

const crmApi = new CrmApi()

export default {
  name: 'SortCompanyContacts',
  components: {
    CustomInput,
    Header,
    Footer,
  },
  props: {
    type: {
      required: true,
    },
  },
  mixins: [m],
  data(): Iobject {
    return {
      sortFields,
      result: {
        companies: [],
        contacts: [],
      },
      companies: null,
      limitPage: 15,
      sortFieldsArray,
      sortFieldsArrayItems,
      dealData: {
        companies: {},
        contacts: {},
      },
    }
  },
  watch: {
    getRouteData(): void {
      this.init()
      this.result = {
        companies: [],
        contacts: [],
      }
    },
  },
  methods: {
    async resolveResult(): Promise<Iobject> {
      const dealData = cloneObj(this.dealData[this.type])
      const dealDataKeys = Object.keys(dealData)

      let requestIds = this.result[this.type].map((item: Iobject) => item.id)
      requestIds = requestIds.filter((item: number) => (
        dealDataKeys.indexOf(String(item)) === -1 ? item : false
      ))

      const type = this.type === 'contacts' ? 'contacts' : 'deal.company'
      const deals = await getDealByContactOrCompany(requestIds, type) ?? {}

      Object.keys(deals).forEach((item: string) => {
        dealData[item] = deals[item]
      })

      return dealData
    },
    getRequest(table: string, type: Iobject) {
      const request: Iobject = {
        datetime: getNowSecods(),
        callbacks: [],
      }
      if (type.type === 'init') {
        request.callbacks.push({
          table, 
          order: 'id', 
          desc: 'desc',
          limit: `${this.result[this.type].length},${this.limitPage}`,
        })
      }
      if (type.type === 'getCompanies') {
        request.callbacks.push({
          table, 
          nameId: 'id',
          id: type.companyIds,
          compare: 'IN',
        })
      }
      return request
    },
    async init(): Promise<void> {
      if (this.getUser === null) {
        const isLogin = await this.isLogin()
        if (isLogin === false) return
      }
      this.getData({ type: 'init' })
    },
    async getData(type: Iobject): Promise<void> {
      const table = this.type === CompanyContactsTypes.Companies
        ? tables.company
        : tables.contacts
      this.$store.dispatch('app/setLoader', { data: true })
      crmApi.sendAndGetData(this.getRequest(table, type))
        .then((result: Iobject) => {
          this.result[this.type] = this.result[this.type].concat(getUnFlat(result.data[0][table]))

          if (this.type === CompanyContactsTypes.Contacts) {
            return this.getCompanyNames(this.result[this.type])
          }
          return true
        }).catch((error: Error) => {
          console.warn('error get data', error)
        }).then(async () => {
          this.dealData[this.type] = await this.resolveResult()
          this.$store.dispatch('app/setLoader', { data: false })
        })
    },
    async getCompanyNames(result: Iobject): Promise<Iobject> {
      let companyIds = Array.from(new Set(result.map((d: Iobject) => d.company))).join(',')
      companyIds = `(${companyIds})`

      const request = this.getRequest(tables.company, { type: 'getCompanies', companyIds })

      crmApi.sendAndGetData(request)
        .then((companies: Iobject) => {
          const c = getUnFlat(companies.data[0][tables.company])
          this.companies = {}
          c.forEach((comp: Iobject) => {
            this.companies[comp.id] = comp
          })
        }).catch((error: Error) => {
          console.warn('error get companies data', error)
        })
      return {}
    },
    loadMore(): void {
      this.getData({ type: 'init' })
    },
    gotoLink(id: number): void {
      this.routeGo({ name: linkByType[this.type], params: { id } })
    },
    gotoCompany(id: number): void {
      this.routeGo({
        name: linkByType[CompanyContactsTypes.Companies],
        params: { id },
      })
    },
  },
} as Iobject
