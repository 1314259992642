
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Error from '@/components/atoms/Error.vue'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'ErrorPage',
  components: {
    Header,
    Footer,
    Error,
  },
  data(): Iobject {
    return {}
  },
  computed: {

  },
  methods: {

  },
}
