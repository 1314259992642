
import { statuses } from '@/conf/deals/selects'
import m from '@/mixins/m'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'Footer',
  mixins: [m],
  components: {},
  data(): Iobject {
    return {
      footer: false,
      statuses,
    }
  },
  watch: {
    getRouteData(route: Iobject): void {
      this.footer = route.footer.status
    },
  },
  computed: {
    statusesNonActive(): Iobject {
      return this.statuses
        .filter((s: Iobject) => s.value > 0)
        .map((s: Iobject) => Object.assign(s, this.$set(s, 'hover', false)))
    },
  },
} as Iobject
