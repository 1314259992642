export default [
  {
    name: 'Майкоп, Адыгея', prop: [2, 1, 1625.99, 'Ржев'], id: 1,
  },
  {
    name: 'Барнаул, Алтайский край', prop: [4, 3, 351.49, 'Новокузнецк'], id: 2,
  },
  {
    name: 'Бийск, Алтайский край', prop: [4, 1, 258.34, 'Новокузнецк'], id: 3,
  },
  {
    name: 'Рубцовск, Алтайский край', prop: [3, 3, 639.83, 'Новокузнецк'], id: 4,
  },
  {
    name: 'Благовещенск, Амурская область', prop: [1, 3, 4421.48, 'Новокузнецк'], id: 5,
  },
  {
    name: 'Архангельск, Архангельская область', prop: [4, 2, 1402.61, 'Ржев'], id: 6,
  },
  {
    name: 'Северодвинск, Архангельская область', prop: [4, 2, 1436.83, 'Ржев'], id: 7,
  },
  {
    name: 'Астрахань, Астраханская область', prop: [1, 3, 1614.27, 'Ржев'], id: 8,
  },
  {
    name: 'Нефтекамск, Башкортостан', prop: [5, 2, 1516.57, 'Ржев'], id: 9,
  },
  {
    name: 'Салават, Башкортостан', prop: [5, 3, 2140.47, 'Ржев'], id: 10,
  },
  {
    name: 'Стерлитамак, Башкортостан', prop: [5, 3, 1724.22, 'Ржев'], id: 11,
  },
  {
    name: 'Уфа, Башкортостан', prop: [5, 2, 1593.76, 'Ржев'], id: 12,
  },
  {
    name: 'Белгород, Белгородская область', prop: [3, 2, 871.93, 'Ржев'], id: 13,
  },
  {
    name: 'Старый Оскол, Белгородская область', prop: [3, 2, 866.84, 'Ржев'], id: 14,
  },
  {
    name: 'Брянск, Брянская область', prop: [3, 1, 469.87, 'Ржев'], id: 15,
  },
  {
    name: 'Улан-Удэ, Бурятия', prop: [1, 3, 2234.3, 'Новокузнецк'], id: 16,
  },
  {
    name: 'Владимир, Владимирская область', prop: [3, 1, 423.85, 'Ржев'], id: 17,
  },
  {
    name: 'Ковров, Владимирская область', prop: [4, 1, 506.91, 'Ржев'], id: 18,
  },
  {
    name: 'Муром, Владимирская область', prop: [3, 1, 551.29, 'Ржев'], id: 19,
  },
  {
    name: 'Волгоград, Волгоградская область', prop: [2, 3, 1191.12, 'Ржев'], id: 20,
  },
  {
    name: 'Волжский, Волгоградская область', prop: [2, 3, 1212.69, 'Ржев'], id: 21,
  },
  {
    name: 'Камышин, Волгоградская область', prop: [3, 2, 1202.68, 'Ржев'], id: 22,
  },
  {
    name: 'Вологда, Вологодская область', prop: [4, 1, 637.84, 'Ржев'], id: 23,
  },
  {
    name: 'Череповец, Вологодская область', prop: [4, 1, 539.95, 'Ржев'], id: 24,
  },
  {
    name: 'Воронеж, Воронежская область', prop: [3, 2, 738.7, 'Ржев'], id: 25,
  },
  {
    name: 'Дербент, Дагестан', prop: [2, 5, 2163.53, 'Ржев'], id: 26,
  },
  {
    name: 'Махачкала, Дагестан', prop: [2, 5, 2041.06, 'Ржев'], id: 27,
  },
  {
    name: 'Хасавюрт, Дагестан', prop: [2, 5, 1958.66, 'Ржев'], id: 28,
  },
  {
    name: 'Чита, Забайкальский край', prop: [1, 2, 2880.66, 'Новокузнецк'], id: 29,
  },
  {
    name: 'Иваново, Ивановская область', prop: [4, 1, 539.8, 'Ржев'], id: 30,
  },
  {
    name: 'Ангарск, Иркутская область', prop: [2, 3, 1760.81, 'Новокузнецк'], id: 31,
  },
  {
    name: 'Братск, Иркутская область', prop: [3, 2, 1428.78, 'Новокузнецк'], id: 32,
  },
  {
    name: 'Иркутск, Иркутская область', prop: [2, 3, 1806.74, 'Новокузнецк'], id: 33,
  },
  {
    name: 'Калининград, Калининградская область', prop: [2, 2, 1033.02, 'Ржев'], id: 34,
  },
  {
    name: 'Элиста, Калмыкия', prop: [2, 3, 1483.29, 'Ржев'], id: 35,
  },
  {
    name: 'Калуга, Калужская область', prop: [3, 1, 315.7, 'Ржев'], id: 36,
  },
  {
    name: 'Обнинск, Калужская область', prop: [3, 1, 239.94, 'Ржев'], id: 37,
  },
  {
    name: 'Кемерово, Кемеровская область', prop: [4, 3, 219.71, 'Новокузнецк'], id: 38,
  },
  {
    name: 'Киселевск, Кемеровская область', prop: [4, 2, 60.12, 'Новокузнецк'], id: 39,
  },
  {
    name: 'Ленинск-Кузнецкий, Кемеровская область', prop: [4, 3, 138.93, 'Новокузнецк'], id: 40,
  },
  {
    name: 'Новокузнецк, Кемеровская область', prop: [4, 3, 0, 'Новокузнецк'], id: 41,
  },
  {
    name: 'Прокопьевск, Кемеровская область', prop: [4, 2, 38.53, 'Новокузнецк'], id: 42,
  },
  {
    name: 'Киров, Кировская область', prop: [5, 1, 1173.44, 'Ржев'], id: 43,
  },
  {
    name: 'Кострома, Костромская область', prop: [4, 1, 560.18, 'Ржев'], id: 44,
  },
  {
    name: 'Краснодар, Краснодарский край', prop: [2, 6, 1568.17, 'Ржев'], id: 45,
  },
  {
    name: 'Новороссийск, Краснодарский край', prop: [2, 5, 1716.7, 'Ржев'], id: 46,
  },
  {
    name: 'Сочи, Краснодарский край', prop: [2, 4, 1844.79, 'Ржев'], id: 47,
  },
  {
    name: 'Ачинск, Красноярский край', prop: [4, 3, 589.3, 'Новокузнецк'], id: 48,
  },
  {
    name: 'Красноярск, Красноярский край', prop: [3, 3, 760.97, 'Новокузнецк'], id: 49,
  },
  {
    name: 'Курган, Курганская область', prop: [3, 2, 1587.38, 'Новокузнецк'], id: 50,
  },
  {
    name: 'Курск, Курская область', prop: [3, 2, 694.79, 'Ржев'], id: 51,
  },
  {
    name: 'Санкт-Петербург, Ленинградская область', prop: [3, 2, 639.54, 'Ржев'], id: 52,
  },
  {
    name: 'Елец, Липецкая область', prop: [3, 2, 606.71, 'Ржев'], id: 53,
  },
  {
    name: 'Липецк, Липецкая область', prop: [3, 2, 662.84, 'Ржев'], id: 54,
  },
  {
    name: 'Магадан, Магаданская область', prop: [5, 5, 6788.33, 'Новокузнецк'], id: 55,
  },
  {
    name: 'Йошкар-Ола, Марийская Республика', prop: [4, 1, 1001.67, 'Ржев'], id: 56,
  },
  {
    name: 'Саранск, Мордовия', prop: [3, 2, 878.33, 'Ржев'], id: 57,
  },
  {
    name: 'Балашиха, Московская область', prop: [3, 1, 262.22, 'Ржев'], id: 58,
  },
  {
    name: 'Железнодорожный, Московская область', prop: [3, 2, 271.85, 'Ржев'], id: 59,
  },
  {
    name: 'Жуковский, Московская область', prop: [3, 1, 197.26, 'Ржев'], id: 60,
  },
  {
    name: 'Коломна, Московская область', prop: [3, 1, 342.52, 'Ржев'], id: 61,
  },
  {
    name: 'Красногорск, Московская область', prop: [3, 1, 210.4, 'Ржев'], id: 62,
  },
  {
    name: 'Люберцы, Московская область', prop: [3, 1, 252.04, 'Ржев'], id: 63,
  },
  {
    name: 'Москва, Московская область', prop: [3, 1, 229.09, 'Ржев'], id: 64,
  },
  {
    name: 'Мытищи, Московская область', prop: [3, 1, 244.78, 'Ржев'], id: 65,
  },
  {
    name: 'Ногинск, Московская область', prop: [3, 1, 294.9, 'Ржев'], id: 66,
  },
  {
    name: 'Одинцово, Московская область', prop: [4, 1, 224.47, 'Ржев'], id: 67,
  },
  {
    name: 'Орехово-Зуево, Московская область', prop: [3, 1, 336.84, 'Ржев'], id: 68,
  },
  {
    name: 'Подольск, Московская область', prop: [3, 1, 256.6, 'Ржев'], id: 69,
  },
  {
    name: 'Серпухов, Московская область', prop: [3, 1, 288.62, 'Ржев'], id: 70,
  },
  {
    name: 'Химки, Московская область', prop: [3, 1, 224.23, 'Ржев'], id: 71,
  },
  {
    name: 'Щелково, Московская область', prop: [3, 1, 262.81, 'Ржев'], id: 72,
  },
  {
    name: 'Электросталь, Московская область', prop: [3, 1, 299.17, 'Ржев'], id: 73,
  },
  {
    name: 'Мурманск, Мурманская область', prop: [5, 4, 1867.37, 'Ржев'], id: 74,
  },
  {
    name: 'Арзамас, Нижегородская область', prop: [4, 2, 708.18, 'Ржев'], id: 75,
  },
  {
    name: 'Дзержинск, Нижегородская область', prop: [4, 1, 632.97, 'Ржев'], id: 76,
  },
  {
    name: 'Нижний Новгород, Нижегородская область', prop: [4, 1, 661.15, 'Ржев'], id: 77,
  },
  {
    name: 'Великий Новгород, Новгородская область', prop: [3, 1, 517.13, 'Ржев'], id: 78,
  },
  {
    name: 'Новосибирск, Новосибирская область', prop: [4, 3, 368.41, 'Новокузнецк'], id: 79,
  },
  {
    name: 'Омск, Омская область', prop: [3, 2, 1031.65, 'Новокузнецк'], id: 80,
  },
  {
    name: 'Оренбург, Оренбургская область', prop: [4, 3, 1787.72, 'Ржев'], id: 81,
  },
  {
    name: 'Орск, Оренбургская область', prop: [4, 2, 2063.01, 'Ржев'], id: 82,
  },
  {
    name: 'Орел, Орловская область', prop: [3, 2, 504.46, 'Ржев'], id: 83,
  },
  {
    name: 'Пенза, Пензенская область', prop: [3, 2, 971.22, 'Ржев'], id: 84,
  },
  {
    name: 'Пермь, Пермский край', prop: [5, 2, 1657.12, 'Ржев'], id: 85,
  },
  {
    name: 'Артем, Приморский край', prop: [3, 4, 5141.89, 'Новокузнецк'], id: 86,
  },
  {
    name: 'Владивосток, Приморский край', prop: [2, 4, 5161.78, 'Новокузнецк'], id: 87,
  },
  {
    name: 'Находка, Приморский край', prop: [2, 5, 5277.44, 'Новокузнецк'], id: 88,
  },
  {
    name: 'Уссурийск, Приморский край', prop: [2, 3, 5062.14, 'Новокузнецк'], id: 89,
  },
  {
    name: 'Великие Луки, Псковская область', prop: [3, 1, 250.94, 'Ржев'], id: 90,
  },
  {
    name: 'Псков, Псковская область', prop: [3, 1, 512.42, 'Ржев'], id: 91,
  },
  {
    name: 'Петрозаводск, Республика Карелия', prop: [2, 5, 955.33, 'Ржев'], id: 92,
  },
  {
    name: 'Сыктывкар, Республика Коми', prop: [5, 1, 1538.82, 'Ржев'], id: 93,
  },
  {
    name: 'Ухта, Республика Коми', prop: [5, 2, 1848.22, 'Ржев'], id: 94,
  },
  {
    name: 'Батайск, Ростовская область', prop: [2, 3, 1306.43, 'Ржев'], id: 95,
  },
  {
    name: 'Волгодонск, Ростовская область', prop: [2, 3, 1334.13, 'Ржев'], id: 96,
  },
  {
    name: 'Новочеркасск, Ростовская область', prop: [2, 3, 1267.49, 'Ржев'], id: 97,
  },
  {
    name: 'Новошахтинск, Ростовская область', prop: [2, 3, 1243.71, 'Ржев'], id: 98,
  },
  {
    name: 'Ростов-на-Дону, Ростовская область', prop: [2, 3, 1297.17, 'Ржев'], id: 99,
  },
  {
    name: 'Таганрог, Ростовская область', prop: [2, 3, 1364.25, 'Ржев'], id: 100,
  },
  {
    name: 'Шахты, Ростовская область', prop: [2, 3, 1231.47, 'Ржев'], id: 101,
  },
  {
    name: 'Рязань, Рязанская область', prop: [3, 1, 423.62, 'Ржев'], id: 102,
  },
  {
    name: 'Волжский, Самарская область', prop: [4, 3, 1212.69, 'Ржев'], id: 103,
  },
  {
    name: 'Новокуйбышевск, Самарская область', prop: [4, 3, 1407.4, 'Ржев'], id: 104,
  },
  {
    name: 'Самара, Самарская область', prop: [4, 3, 1386.46, 'Ржев'], id: 105,
  },
  {
    name: 'Сызрань, Самарская область', prop: [3, 3, 1222.47, 'Ржев'], id: 106,
  },
  {
    name: 'Тольятти, Самарская область', prop: [4, 3, 1318.62, 'Ржев'], id: 107,
  },
  {
    name: 'Балаково, Саратовская область', prop: [3, 3, 1295.94, 'Ржев'], id: 108,
  },
  {
    name: 'Саратов, Саратовская область', prop: [3, 3, 1128.9, 'Ржев'], id: 109,
  },
  {
    name: 'Энгельс, Саратовская область', prop: [3, 3, 1136.28, 'Ржев'], id: 110,
  },
  {
    name: 'Южно-Сахалинск, Сахалинская область', prop: [4, 4, 5888.66, 'Новокузнецк'], id: 111,
  },
  {
    name: 'Екатеринбург, Свердловская область', prop: [3, 2, 2010.38, 'Ржев'], id: 112,
  },
  {
    name: 'Каменск-Уральский, Свердловская область', prop: [3, 1, 2107.75, 'Ржев'], id: 113,
  },
  {
    name: 'Нижний Тагил, Свердловская область', prop: [4, 2, 2000.3, 'Ржев'], id: 114,
  },
  {
    name: 'Первоуральск, Свердловская область', prop: [4, 2, 1984.96, 'Ржев'], id: 115,
  },
  {
    name: 'Владикавказ, Северная осетия', prop: [2, 1, 2018.95, 'Ржев'], id: 116,
  },
  {
    name: 'Смоленск, Смоленская область', prop: [3, 1, 304, 'Ржев'], id: 117,
  },
  {
    name: 'Невинномысск, Ставропольский край', prop: [2, 5, 1698.92, 'Ржев'], id: 118,
  },
  {
    name: 'Ставрополь, Ставропольский край', prop: [2, 5, 1633.89, 'Ржев'], id: 119,
  },
  {
    name: 'Тамбов, Тамбовская область', prop: [3, 2, 681.64, 'Ржев'], id: 120,
  },
  {
    name: 'Альметьевск, Татарстан', prop: [5, 2, 1315, 'Ржев'], id: 121,
  },
  {
    name: 'Казань, Татарстан ', prop: [4, 2, 1067.49, 'Ржев'], id: 122,
  },
  {
    name: 'Набережные Челны, Татарстан', prop: [5, 2, 1307.05, 'Ржев'], id: 123,
  },
  {
    name: 'Нижнекамск, Татарстан ', prop: [5, 2, 1290.36, 'Ржев'], id: 124,
  },
  {
    name: 'Тверь, Тверская область', prop: [4, 1, 125.97, 'Ржев'], id: 125,
  },
  {
    name: 'Томск, Томская область', prop: [4, 3, 425.32, 'Новокузнецк'], id: 126,
  },
  {
    name: 'Новомосковск, Тульская область', prop: [3, 1, 444.62, 'Ржев'], id: 127,
  },
  {
    name: 'Тула, Тульская область', prop: [2, 1, 400.71, 'Ржев'], id: 128,
  },
  {
    name: 'Кызыл, Тыва', prop: [2, 1, 1243.55, 'Новокузнецк'], id: 129,
  },
  {
    name: 'Тобольск, Тюменская область', prop: [4, 2, 1728.23, 'Новокузнецк'], id: 130,
  },
  {
    name: 'Тюмень, Тюменская область', prop: [3, 2, 1667.49, 'Новокузнецк'], id: 131,
  },
  {
    name: 'Ижевск, Удмуртия', prop: [5, 1, 1430.64, 'Ржев'], id: 132,
  },
  {
    name: 'Димитровград, Ульяновская область', prop: [4, 2, 1200.74, 'Ржев'], id: 133,
  },
  {
    name: 'Ульяновск, Ульяновская область', prop: [4, 2, 1103.74, 'Ржев'], id: 134,
  },
  {
    name: 'Комсомольск-на-Амуре, Хабаровский край', prop: [4, 3, 5385.08, 'Новокузнецк'], id: 135,
  },
  {
    name: 'Хабаровск, Хабаровский край', prop: [2, 3, 4991.7, 'Новокузнецк'], id: 136,
  },
  {
    name: 'Абакан, Хакасия', prop: [2, 3, 855.15, 'Новокузнецк'], id: 137,
  },
  {
    name: 'Нефтеюганск, Ханты-Мансийский АО', prop: [4, 2, 1572.31, 'Новокузнецк'], id: 138,
  },
  {
    name: 'Нижневартовск, Ханты-Мансийский АО', prop: [5, 2, 1294.72, 'Новокузнецк'], id: 139,
  },
  {
    name: 'Сургут, Ханты-Мансийский АО', prop: [4, 2, 1514.52, 'Новокузнецк'], id: 140,
  },
  {
    name: 'Златоуст, Челябинская область', prop: [4, 2, 1871.36, 'Ржев'], id: 141,
  },
  {
    name: 'Копейск, Челябинская область', prop: [3, 2, 2025.95, 'Ржев'], id: 142,
  },
  {
    name: 'Магнитогорск, Челябинская область', prop: [4, 3, 1932.72, 'Ржев'], id: 143,
  },
  {
    name: 'Миасс, Челябинская область', prop: [3, 2, 1911.2, 'Ржев'], id: 144,
  },
  {
    name: 'Челябинск, Челябинская область', prop: [3, 2, 2008.61, 'Ржев'], id: 145,
  },
  {
    name: 'Грозный, Чеченская Республика', prop: [2, 4, 2001.29, 'Ржев'], id: 146,
  },
  {
    name: 'Новочебоксарск, Чувашия', prop: [4, 2, 925.42, 'Ржев'], id: 147,
  },
  {
    name: 'Чебоксары, Чувашская Республика', prop: [4, 2, 911.36, 'Ржев'], id: 148,
  },
  {
    name: 'Якутск, Якутия', prop: [2, 2, 4958.53, 'Новокузнецк'], id: 149,
  },
  {
    name: 'Новый Уренгой, Ямало-Ненецкий АО', prop: [5, 2, 2198.57, 'Новокузнецк'], id: 150,
  },
  {
    name: 'Ноябрьск, Ямало-Ненецкий АО', prop: [5, 2, 1762.77, 'Новокузнецк'], id: 151,
  },
  {
    name: 'Рыбинск, Ярославская область', prop: [4, 1, 413.18, 'Ржев'], id: 152,
  },
  {
    name: 'Ярославль, Ярославская область', prop: [4, 1, 490.01, 'Ржев'], id: 153,
  },
]
