interface Iobject {
  [key: string]: any
}

const tables: Iobject = {
  canbanOrder: 'canbanOrder',
  canbanData: 'canbanDeals',
  canbanHistory: 'canbanHistory',
  users: 'users',
  company: 'company',
  contacts: 'contacts',
}

const dispatchByTable: Iobject = {
  canbanDeals: 'app/setCanbanData',
  canbanOrder: 'app/setCanbanOrder',
  users: 'app/setUsers',
}

const jsonParseKeys: Iobject = {
  history: true,
  contacts: true,
  'deal.additions.windows': true,
  'deal.additions.doors': true,
  'deal.additions.gates': true,
  'deal.additions.cranes': true,
  'deal.company': true,
  phones: true,
  emails: true,
  messengers: true,
  sites: true,
  watchers: true,
}

export { tables, dispatchByTable, jsonParseKeys }
