
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SearchBody from '@/components/atoms/SearchBody.vue'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'SortCompanyContacts',
  components: {
    Header,
    Footer,
    SearchBody,
  },
} as Iobject
