import addPropDefaults from '@/conf/routes/functions'
import p from '@/conf/routes/properties'
import AddCompanyContacts from '@/components/pages/AddCompanyContacts.vue'

export enum CompanyContactsTypesSingle {
  Company = 'company',
  Contact = 'contact',
}

export enum CompanyContactsTypes {
  Companies = 'companies',
  Contacts = 'contacts',
}

const { properties } = p

const company: any = {
  path: `/${CompanyContactsTypesSingle.Company}/:id` as string,
  name: CompanyContactsTypesSingle.Company as string,
  component: AddCompanyContacts as any,
  props: true,
  [properties.secure.name]: properties.secure.types.on,
  [properties.changeProp.name]: properties.changeProp.types.user,
  [properties.needLoadData.name]: properties.needLoadData.types.off,
  footer: {
    status: false,
  },
}

const contact: any = {
  path: `/${CompanyContactsTypesSingle.Contact}/:id` as string,
  name: CompanyContactsTypesSingle.Contact as string,
  component: AddCompanyContacts as any,
  props: true,
  [properties.secure.name]: properties.secure.types.on,
  [properties.changeProp.name]: properties.changeProp.types.user,
  [properties.needLoadData.name]: properties.needLoadData.types.off,
  footer: {
    status: false,
  },
}

addPropDefaults(properties, company)
addPropDefaults(properties, contact)

export default [company, contact]
