
import { getNowSecods } from '@/utils'
import { tables } from '@/conf/tables'
import CrmApi from '@/services/crmApi'
import { getUsers } from '@/store/plugins/generalFunctions'
import m from '@/mixins/m'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import TasksBlock from '@/components/messenger/TasksBlock.vue'
import CustomSelect from '@/components/atoms/CustomSelect.vue'

interface Iobject {
  [key: string]: any
}

const crmApi = new CrmApi()

export default {
  name: 'Main',
  mixins: [m],
  components: {
    Header,
    Footer,
    TasksBlock,
    CustomSelect,
  },
  data(): Iobject {
    return {
      tasks: undefined,
      deals: undefined,
      endedTasks: {
        status: false,
      },
      isError: undefined,
      filter: {
        responsible: null,
        userId: null,
        dealId: null,
      },
      activeDealStatus: 0,
    }
  },
  watch: {
    async getRouteData(): Promise<void> {
      await this.init()
    },
    filter: {
      deep: true,
      handler: 'urlFilterByUser',
    },
  },
  computed: {
    editedTasks(): Iobject[] {
      if (!this.tasks || !this.deals) return []

      const dealById: Iobject = {}
      this.deals.forEach((deal: Iobject) => {
        dealById[deal.id] = deal
      })

      const tasks: Iobject[] = []
      this.tasks.forEach((task: Iobject) => {
        if (dealById[task.dealId].status !== this.activeDealStatus) return
        task.deal = dealById[task.dealId]

        if (this.filter.responsible !== null) {
          if (task.responsible !== this.filter.responsible) return
        }

        if (this.filter.userId !== null) {
          if (task.userId !== this.filter.userId) return
        }

        if (this.filter.dealId !== null) {
          if (task.deal.id !== this.filter.dealId) return
        }

        tasks.push(task)
      })

      return tasks || []
    },
    users(): Iobject[] {
      if (!this.getUsers) return []
      const users: Iobject[] = [
        {
          name: 'Любой',
          value: null,
        },
      ]
      this.getUsers.data.forEach((user: Iobject) => {
        users.push({
          name: `${user.firstName} ${user.lastName}`.trim(),
          value: user.id,
        })
      })
      return users
    },
    dealIds(): Iobject[] {
      if (!this.deals) return []

      const deals = [
        {
          name: 'Любая',
          value: null,
        },
      ]
      this.deals.forEach((deal: Iobject) => {
        if (deal.status !== this.activeDealStatus) return

        deals.push({
          name: `#${deal.id}, ${deal['deal.name']}`,
          value: deal.id,
        })
      })
      return deals
    },
  },
  methods: {
    clearSearch(): void {
      this.filter = {
        responsible: null,
        userId: null,
        dealId: null,
      }
    },
    resolveSortByUserFromUrl(): void {
      const strGet = window.location.search.replace('?', '').split('&')
      strGet.forEach((item: string) => {
        const itemData = item.split('=')
        try {
          this.filter[itemData[0]] = JSON.parse(decodeURI(itemData[1]))
        } catch (e) {
          this.filter[itemData[0]] = decodeURI(itemData[1])
        }
      })
    },
    getUrlWithParams(val: Iobject): string {
      const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
      let newUrl = `${baseUrl}?`
      Object.keys(val).forEach((item: string) => {
        if (item.length === 0) return

        newUrl += `${item}=${val[item]}&`
      })
      return newUrl
    },
    urlFilterByUser(val: Iobject): void {
      window.history.pushState(null, '', this.getUrlWithParams(val))
    },
    async init(): Promise<void> {
      this.$store.dispatch('app/setLoader', { data: true })
      if (this.getUser === null) {
        const isLogin = await this.isLogin()
        if (isLogin === false) {
          this.routeGo({ name: 'auth' })
          this.$store.dispatch('app/setLoader', { data: false })
          return
        }
      }

      if (this.getUsers === null) {
        const users: boolean = await getUsers()
        if (users === false) {
          this.$store.dispatch('app/setLoader', { data: false })
          this.isError = true
          return
        }
      }

      this.tasks = await this.getTasks()

      if (!this.tasks) {
        this.isError = true
        this.$store.dispatch('app/setLoader', { data: false })
        return
      }

      this.deals = await this.getDeals()

      this.resolveSortByUserFromUrl()
      this.$store.dispatch('app/setLoader', { data: false })
    },
    async getDeals(): Promise<boolean> {
      const dealsIdsArr: Iobject = [...new Set(this.tasks.map((item: Iobject) => item.dealId))]
      const request = this.getDealsRequest(dealsIdsArr)
      const deals = await crmApi.sendAndGetData(request)
      return deals?.data[0][tables.canbanData]
    },
    getDealsRequest(deals: Iobject): Iobject {
      return {
        datetime: getNowSecods(),
        callbacks: [
          {
            table: tables.canbanData,
            ask: [
              {
                nameId: 'id',
                id: `(${deals.join(',')})`,
                compare: 'IN',
                link: '',
              },
            ],
            order: 'id',
            desc: 'desc',
          },
        ],
      }
    },
    getTesksRequest(): Iobject {
      return {
        datetime: getNowSecods(),
        callbacks: [
          {
            table: tables.canbanHistory,
            ask: [
              {
                nameId: 'dealId',
                id: 1,
                compare: '>=',
                link: 'AND',
              },
              {
                nameId: 'type',
                id: 1,
                compare: '=',
                link: '',
              },
            ],
            order: 'toDate',
          },
        ],
      }
    },
    async getTasks(): Promise<void> {
      const request = this.getTesksRequest()
      const tasks = await crmApi.sendAndGetData(request)
      return tasks?.data[0][tables.canbanHistory]
    },
  },
} as Iobject
