import { mapGetters } from 'vuex'
import { numberWithSpaces, getDateTime, getDate } from '@/utils'
import CookiesApi from '@/services/cookiesApi'
import { getCookie, userCookieName } from '@/store/plugins/auth/getCookie'
import LoadFiles from '@/services/loadFiles'

const cookiesApi = new CookiesApi()
const loadFiles = new LoadFiles()

interface Iobject {
  [key: string]: any
}

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters('app', [
      'getState',
      'getCanbanData',
      'getCanbanOrder',
      'getCanbanDeal',
      'getRouteData',
      'getUser',
      'getUsers',
      'getLoader',
      'getDatetimeCorrect',
      'getPopupDialog',
      'getSearchShow',
    ]),
  },
  methods: {
    getDownloadLink(file: Iobject): string {
      return `${loadFiles.getDownloadLink()}?file=${file.link}`
    },
    getDateTime(datetime: number): string {
      return getDateTime(datetime)
    },
    getDate(datetime: number): string {
      return getDate(datetime)
    },
    limitText(args: Iobject): string {
      const { text, length } = args
      let textLength = length

      const brPos = text.indexOf('\n')
      if (brPos >= 0 && brPos < textLength) textLength = brPos - 5

      if (textLength === null || text.length <= textLength) return text

      const plusSymbols = text.substr(textLength).search(/[A-zA-яЁё0-9]/) + 1
      return `${text.substr(0, textLength + plusSymbols)}...`
    },
    getDisabled(mode: string, item: string): boolean {
      if (mode || item) return false
      return false
    },
    getItemId(id: string): string {
      return id.replace(/\./g, '_')
    },
    routeBack(n: number): void {
      this.$router.go(n)
    },
    routeGo(data: Iobject): void {
      this.$router.push(data)
    },
    noData(data: any, text: string, textSuccessAfter = ''): any {
      try {
        if (!data) return `<span class="no-data">${text}</span>`
        return `${data}${textSuccessAfter}`
      } catch (err) {
        return `${data}${textSuccessAfter}`
      }
    },
    checkPhone(item: Iobject): string | boolean {
      try {
        return item.contacts[0].phones[0].phone
      } catch (err) {
        return false
      }
    },
    numberWithSpaces(x: number): string | null {
      return numberWithSpaces(x)
    },
    removeLoader(numberResponses: number | null = null, limitiResponses: number | null = null): boolean | null {
      if (numberResponses === limitiResponses || numberResponses === null || limitiResponses === null) {
        this.$store.dispatch('app/setLoader', { data: false })
        return true
      }
      return false
    },
    async isLogin(): Promise<boolean> {
      const cookie = getCookie(userCookieName)
      let cookieResult = null
      if (cookie) cookieResult = await cookiesApi.sendCookie(cookie)
      if (!cookieResult || cookiesApi.resolveCookie(cookieResult) === false) {
        if (this.getRouteData) {
          this.routeGo({
            name: 'auth',
            params: {
              to: this.getRouteData.name,
            },
          })
          return false
        }
      }
      return true
    },
    getName(userId: Iobject): string {
      if (userId === null) return ''
      const d = this.getUsers?.data.find((user: Iobject) => user.id === userId)
      if (!d) return ''
      return `${d.lastName} ${d.firstName}`.trim()
    },
    getUserName(): string {
      if (!this.getUser) return ''
      return `${this.getUser.data.lastName} ${this.getUser.data.firstName}`.trim()
    },
  },
} as Iobject
