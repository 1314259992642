var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"messages",attrs:{"id":"messages"},on:{"scroll":function($event){return _vm.getScroll($event)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isScroll.text && _vm.isScroll.status),expression:"isScroll.text && isScroll.status"}],staticClass:"messages__date"},[_c('span',{staticClass:"messages__date-text",domProps:{"textContent":_vm._s(_vm.isScroll.text)}})]),_c('TasksBlock',{staticClass:"messages__item messages__item-tasks",attrs:{"tasks":_vm.tasks,"isEdit":_vm.isEdit,"endedTasks":_vm.endedTasks},on:{"startEdit":_vm.startEdit,"changeMessage":_vm.changeMessage}}),_vm._l((_vm.messages),function(m,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMessageShow(m)),expression:"isMessageShow(m)"}],key:index,class:['messages__item', {
      'iAmNotAuthor': m.userId !== _vm.iAmAuthor,
      'iAmAuthor': m.userId === _vm.iAmAuthor,
      'info': m.type === -1
    }]},[_c('div',{class:['messages__item-text', 'item', {
        'notEdit': _vm.editId !== null && _vm.editId !== m.id
      }]},[_c('p',{staticClass:"item__header"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(m.userId === _vm.iAmAuthor && _vm.editId === null && m.type !== -1),expression:"m.userId === iAmAuthor && editId === null && m.type !== -1"}],staticClass:"item__edit",on:{"click":function($event){_vm.editId = m.id}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.png"),"alt":"edit"}})]),_c('span',{staticClass:"item__date"},[_vm._v(_vm._s(_vm.getDateTime(m.datetime)))]),_c('span',{staticClass:"item__author"},[_vm._v(_vm._s(_vm.getName(m.userId)))])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(m.files.length > 0),expression:"m.files.length > 0"}],staticClass:"item__files"},_vm._l((m.files),function(f,findex){return _c('a',{key:findex,staticClass:"item__files-link",attrs:{"href":_vm.getDownloadLink(f),"target":"_blank"},domProps:{"textContent":_vm._s(f.name)}})}),0),_c('p',{staticClass:"item__text"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(m.toDate && m.responsible),expression:"m.toDate && m.responsible"}],staticClass:"item__task"},[_c('img',{staticClass:"icon-done",attrs:{"src":require("@/assets/images/done.svg"),"alt":"clock"}}),_c('span',[_vm._v(_vm._s(_vm.getDate(m.toDate))+" для "+_vm._s(_vm.getName(m.responsible))+":")])]),_c('span',{staticClass:"item__text-message",domProps:{"innerHTML":_vm._s(_vm.getTextWithBr(m.text))}})])])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }