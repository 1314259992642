import CrmApi from '@/services/crmApi'
import { getNowSecods } from '@/utils'
import { tables } from '@/conf/tables'
import { getInsertRequest } from '@/store/plugins/generalFunctions'

const crmApi = new CrmApi()

interface Iobject {
  [key: string]: any
}

function getMessagesRequest(args: Iobject): Iobject {
  const { dealId, limit, messagesType } = args
  if (messagesType === 'messages') {
    return {
      datetime: getNowSecods(),
      callbacks: [
        { 
          table: tables.canbanHistory,
          ask: [
            {
              nameId: 'dealId',
              id: dealId,
              compare: '=',
              link: 'AND',
            },
            {
              nameId: 'type',
              id: 0,
              compare: '<=',
              link: '',
            },
          ],
          order: 'id', 
          desc: 'desc', 
          limit,
        },
      ],
    }
  }
  if (messagesType === 'tasks') {
    return {
      datetime: getNowSecods(),
      callbacks: [
        { 
          table: tables.canbanHistory,
          ask: [
            {
              nameId: 'dealId',
              id: dealId,
              compare: '=',
              link: 'AND',
            },
            {
              nameId: 'type',
              id: '(1,2)',
              compare: 'IN',
              link: '',
            },
          ],
          order: 'toDate',
        },
      ],
    }  
  }
  return {
    datetime: getNowSecods(),
    callbacks: [],
  }
}

export async function getMessages(args: Iobject): Promise<Iobject> {
  return new Promise<Iobject>((resolve, reject) => {
    const getLimitMessages = getMessagesRequest(args)
    crmApi.sendAndGetData(getLimitMessages).then((result: Iobject) => {
      resolve(result)
    }).catch((error: Error) => {
      reject(error)
    })
  })
}

export function convertMessage(message: Iobject): Iobject {
  message.files = JSON.parse(`[${message.files}]`)
  return message
}

export function unConvertMessage(message: Iobject): Iobject {
  const files = JSON.stringify(message.files)
  message.files = files.substr(1, files.length - 2)
  return message
}

export async function inserMessage(enter: Iobject): Promise<any> {
  const insertRequest = getInsertRequest([enter], tables.canbanHistory)
  return crmApi.sendAndGetData(insertRequest).then((result: Iobject) => result).catch((error: Error) => {
    throw new Error(JSON.stringify(error))
  })
}

export async function changeMessage(enter: Iobject): Promise<any> {
  const items: Iobject = []
  console.log(enter)
  const stopKey = ['id', 'datetime']
  Object.keys(enter).forEach((key: string) => {
    if (enter[key] !== null && !stopKey.includes(key)) {
      items.push({
        name: key,
        value: enter[key],
      })
    }
  })

  const changeRequest: Iobject = {
    actions: [
      {
        table: tables.canbanHistory,
        id: enter.id,
        nameId: 'id',
        type: 'change',
        items,
      },
    ],
    callbacks: [
      { 
        table: tables.canbanHistory,
        nameId: 'id',
        id: enter.id,
        compare: '=',
      },
    ],
    datetime: getNowSecods(),
  }
  console.log('message changeRequest', changeRequest)
  return crmApi.sendAndGetData(changeRequest).then((result: Iobject) => result).catch((error: Error) => {
    throw new Error(JSON.stringify(error))
  })
}
