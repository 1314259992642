import addPropDefaults from '@/conf/routes/functions'
import p from '@/conf/routes/properties'
import setUsersComp from '@/components/pages/SetUsers.vue'

const { properties } = p

const setUsers: any = {
  path: '/set-users' as string,
  name: 'setUsers' as string,
  component: setUsersComp as any,
  props: true,
  [properties.secure.name]: properties.secure.types.on,
  [properties.changeProp.name]: properties.changeProp.types.user,
  [properties.needLoadData.name]: properties.needLoadData.types.off,
  footer: {
    status: false,
  },
}

addPropDefaults(properties, setUsers)

export default setUsers
