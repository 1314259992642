
import m from '@/mixins/m'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'Load',
  components: {},
  mixins: [m],
  data(): Iobject {
    return {
      stage: false,
      timeout: null,
    }
  },
  watch: {
    getLoader(val: boolean): void {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
      if (val === true) {
        this.stage = 1
        this.timeout = setTimeout(() => {
          this.stage = 2
        }, 300)
      }
      if (val === false) this.stage = false
    },
  },
} as Iobject
