import { canbanStages } from '@/conf/canbanData'
import r from '@/conf/deals/regions'
import { Types } from '../routes/properties'

interface Iobject {
  [key: string]: any
}

export interface selectItem {
  name: string
  value: string | number
}

export const purposes: Iobject = [
  { name: 'Ангар для вертолета', value: 'Ангар для вертолета' },
  { name: 'Ангар для самолета', value: 'Ангар для самолета' },
  { name: 'Ангар для яхт', value: 'Ангар для яхт' },
  { name: 'Производственный ангар', value: 'Производственный ангар' },
  { name: 'Складской ангар', value: 'Складской ангар' },
  { name: 'Сельскохозяйственный ангар', value: 'Сельскохозяйственный ангар' },
  { name: 'Ангары для тяжелого климата', value: 'Ангары для тяжелого климата' },
  { name: 'Раздвижные ангары', value: 'Раздвижные ангары' },
  { name: 'Обслуживание техники', value: 'Обслуживание техники' },
  { name: 'Спортивные ангары и павильоны', value: 'Спортивные ангары и павильоны' },
]

export const roofs: Iobject = [
  { name: 'Односкатная', value: 'Односкатная' },
  { name: 'Двускатная', value: 'Двускатная' },
  { name: 'Арочная', value: 'Арочная' },
  { name: 'Ломаная', value: 'Ломаная' },
]

export const materials: Iobject = [
  { name: 'Тент', value: 'Тент' },
  { name: 'Теплый тент', value: 'Теплый тент' },
  { name: 'Профлист', value: 'Профлист' },
  { name: 'Сендвич панель', value: 'Сендвич панель' },
]

const reg: Iobject = []
r.forEach((item: Iobject) => {
  reg.push({ name: item.name, value: item.id })
})
export const regions = reg

export const sources: Iobject[] = [
  { name: 'Калькулятор', value: 'Калькулятор' },
  { name: 'Холодные', value: 'Холодные' },
  { name: 'Отдел продаж', value: 'Отдел продаж' },
  { name: 'Другое', value: 'Другое' },
]

function getStageTypes() {
  return canbanStages
    .filter((stageData) => stageData.stage !== undefined)
    .map((stageData) => ({
      name: stageData.name,
      value: stageData.stage,
    }))
}

export const stageTypes = getStageTypes()

export const responsibles: Iobject[] = []

export const statusContact: Iobject = [
  { name: 'Лицо принимающее решение', value: 'Лицо принимающее решение' },
  { name: 'Помощник', value: 'Помощник' },
]

export const gateTypes: Iobject = [
  { name: 'Распашные', value: 'Распашные' },
  { name: 'Тентовая штора', value: 'Тентовая штора' },
  { name: 'Подъемная тентовая штора', value: 'Подъемная тентовая штора' },
]

export const craneWeights: Iobject = [
  { name: '1 тонна', value: '1 тонна' },
  { name: '2 тонны', value: '2 тонны' },
  { name: '3 тонны', value: '3 тонны' },
  { name: '5 тонн', value: '5 тонн' },
  { name: '8 тонн', value: '8 тонн' },
  { name: '10 тонн', value: '10 тонн' },
  { name: '12 тонн', value: '12 тонн' },
]

export const statuses: any[] = [
  { name: 'Активная', value: 0 },
  { name: 'На удаление', value: 1 },
  { name: 'Не реализована', value: 2 },
  { name: 'Успешно реализована', value: 3 },
  { name: 'Удаленная', value: 4 },
]

export const filterPeriods: selectItem[] = [
  { name: 'Месяц', value: 1 },
  { name: 'Два месяца', value: 2 },
  { name: 'Три месяца', value: 3 },
  { name: 'Пол года', value: 6 },
]

export const addCompany: any[] = []

export const addContacts: any[] = []

export const addWatchers: any[] = []
