
interface Iobject {
  [key: string]: any
}

export default {
  name: 'CustomSelect',
  props: {
    id: {
      type: String,
      required: true,
    },
    itemParent: {
      required: true,
    },
    itemName: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    root: {
      required: true,
    },
    scheme: {
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Выберите опцию',
    },
    validOb: {
      default: false,
    },
    disabled: {
      default: false,
    },
    alwaysSearch: {
      default: false,
    },
  },
  data() {
    return {
      chooseOptionsLength: 5,
      choose: '',
      focus: false,
      chooseFocus: false,
      opened: false,
      before: false,
      selected: null,
      hovered: null,
      checkToBefore: 200,
      isChoose: null,
    }
  },
  mounted(): void {
    this.defaultInstance()
    this.defaultSelect()
    document.addEventListener('click', this.documentClick)
    document.addEventListener('scroll', this.documentScroll)
    document.addEventListener('keydown', this.documentKeyup)
  },
  beforeDestroy(): void {
    document.removeEventListener('click', this.documentClick)
    document.removeEventListener('scroll', this.documentScroll)
    document.removeEventListener('keydown', this.documentKeyup)
  },
  watch: {
    sortedOptions(val: Iobject): void {
      if (val && val.length === 0) this.$emit('noSearch', this.itemName, this.choose.trim())
    },
    focus(val: boolean): void {
      if (val === true || this.chooseFocus === true) {
        this.opened = true
      }
    },
    chooseFocus(val: boolean): void {
      if (val === true || this.focus === true) this.opened = true
    },
    opened(val: boolean): void {
      if (val === true) {
        const select = document.getElementById(this.id)
        if (!select) return
        this.before = select.getBoundingClientRect().top > window.innerHeight - this.checkToBefore
      } else {
        this.defaultInstance()
        this.defaultSelect()
      }
    },
    selected(val: null | number): void {
      if (val === null) return
      this.isChoose = document.getElementById(`choose_${this.id}`)
      if (val === -1 && this.isChoose) {
        this.isChoose.focus()
        return
      }
      if (this.isChoose) {
        this.chooseFocus = false
        this.isChoose.blur()
      }

      const selectBlock = document.getElementById(`${this.id}`)
      if (selectBlock) selectBlock.focus()

      setTimeout(() => {
        const select = document.getElementById(`selected-${val}`)
        const parentSelect = document.getElementById(`parent-selected-${this.id}`)
        if (!select || !parentSelect) return
        const scrollNumberItems = 3
        const parentHeight = parentSelect.getBoundingClientRect().height
        const y = select.getBoundingClientRect().height * (val + scrollNumberItems)
        parentSelect.scrollTo({
          top: y > parentHeight ? y - parentHeight : 0,
          behavior: 'smooth',
        })
      }, 0)
    },
  },
  computed: {
    realValueStrip(): string {
      let title = ''
      if (this.scheme[this.itemName] && this.itemParent[this.itemName]) {
        title += `${this.scheme[this.itemName].name}: `
      }
      return typeof this.realValue === 'string' ? `${title}${this.realValue.replace(/<\/?[^>]+(>|$)/g, '')}` : ''
    },
    realValue(): string {
      try {
        return this.options.filter((o: Iobject) => o.value === this.itemParent[this.itemName])[0].name || null
      } catch (err) {
        if (this.scheme[this.itemName]) return `<span class='--placeholder'>${this.scheme[this.itemName].name}</span>`
        if (this.scheme[this.itemName] && this.scheme[this.itemName].placeholder) {
          return this.scheme[this.itemName].placeholder
        }
        if (this.itemParent[this.itemName]) return this.itemParent[this.itemName]
        return `<span class='--placeholder'>${this.placeholder}</span>`
      }
    },
    sortedOptions(): Iobject[] {
      if (this.choose === '') return this.options
      const s: Iobject[] = []
      this.options.forEach((o: Iobject) => {
        if (o.name.toLowerCase().indexOf(this.choose.trim().toLowerCase()) >= 0) s.push(o)
      })
      this.defaultSelect()
      return s
    },
  },
  methods: {
    isDeletedItem(): boolean {
      const d = this.itemParent[this.itemName]
      if (String(d).length === 0) return false
      if (this.sortedOptions.filter((i: Iobject) => i.value === d).length === 0) return true
      return false
    },
    open(): void {
      if (this.disabled === true) return
      this.focus = true
    },
    onBlur(type: string | null = null): void {
      if (type !== null) this[type] = false
      setTimeout(() => {
        if (this.focus === false && this.chooseFocus === false) this.opened = false
      }, 100)
    },
    defaultSelect(): void {
      this.selected = null
      this.hovered = null
    },
    defaultInstance(): void {
      this.choose = ''
      this.opened = false
      this.focus = false
      this.chooseFocus = false
    },
    moveUnderItem(): void {
      if (this.selected !== null) this.selected = null
    },
    setValue(o: Iobject): void {
      this.itemParent[this.itemName] = o.value
      this.onBlur()
      this.defaultInstance()
      this.defaultSelect()
      this.$emit('change', this.itemParent, this.validOb, this.itemName, this.root)
    },
    documentClick(e: Iobject): void {
      if (this.opened === false) return
      if (e.target.closest(`#${this.id}`) === null) this.defaultInstance()
    },
    documentScroll(): void {
      if (this.opened === false) return
      const select = document.getElementById(this.id)
      if (!select) return
      this.before = select.getBoundingClientRect().top > window.innerHeight - this.checkToBefore
    },
    documentKeyup(e: Iobject): void {
      if (this.opened === false) return
      const startPos = this.isChoose ? -1 : 0
      if (e.keyCode === 27) this.opened = false
      if (e.keyCode === 38) {
        if (this.selected === null && this.hovered === null) {
          this.selected = 0
        } else {
          if (this.selected === null) this.selected = this.hovered
          this.selected = this.selected - 1 >= startPos ? this.selected - 1 : this.sortedOptions.length - 1
        }
      }
      if (e.keyCode === 40) {
        if (this.selected === null && this.hovered === null) {
          this.selected = 0
        } else {
          if (this.selected === null) this.selected = this.hovered
          this.selected = this.selected + 1 === this.sortedOptions.length ? startPos : this.selected + 1
        }
      }
      if (e.keyCode === 13 && this.selected !== null) {
        this.setValue(this.sortedOptions[this.selected])
      }
      if ([13, 38, 40, 27].indexOf(e.keyCode) >= 0) e.preventDefault()
    },
  },
} as Iobject
