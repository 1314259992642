
import m from '@/mixins/m'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import AddEditCompanyContacts from '@/components/pages/AddEditCompanyContacts.vue'

interface Iobject {
  [key: string]: any
}

export default {
  name: 'CompanyContacts',
  mixins: [m],
  components: {
    Header,
    Footer,
    AddEditCompanyContacts,
  },
  data(): Iobject {
    return {

    }
  },
  computed: {

  },
  methods: {

  },
} as Iobject
