import addPropDefaults from '@/conf/routes/functions'
import p from '@/conf/routes/properties'
import CompanyContacts from '@/components/pages/CompanyContacts.vue'

const { properties } = p

const data: any = {
  path: '/add-company-contacts' as string,
  name: 'addEditCompanyContacts' as string,
  component: CompanyContacts as any,
  [properties.secure.name]: properties.secure.types.on,
  props: true,
  footer: {
    status: false,
  },
}

addPropDefaults(properties, data)
export default data
