import { getDate } from '@/utils'

const MS_IN_SECOND = 1000
const ORANGE_FIRE = 3 * 24 * 60 * 60

export const getTaskIconStatus = (toDate: number) => {
  const now = new Date().getTime() / MS_IN_SECOND
  const nowD = getDate(now)
  const timeD = getDate(toDate)

  if (nowD === timeD || toDate < now) return 'RED_FIRE'

  if (toDate - now < ORANGE_FIRE) return 'ORANGE_FIRE'
  return 'CLOCK'
}
