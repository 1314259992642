interface Iobject {
    [key: string]: any
}

const division = (n: any, d: any) => {
  try {
    const r = n / d
    return Math.round(r) === Math.round(r * 10) / 10
  } catch (err) {
    return false
  }
}

export function validItem(d: Iobject, s: Iobject, key: string, allInvalid: boolean): boolean {
  if (s[key] === undefined) return false
  if (d[key] === null) return allInvalid

  if (s[key].list !== null) {
    if (d[key].length === 0) return true
  }

  if (s[key].list === null && s[key].type === 'text') {
    const length = d[key].length >= s[key]['min-length'] && d[key].length <= s[key]['max-length']
    if (s[key].simbols === 'all' && !length) return true
  }

  if (s[key].list === null && s[key].type === 'tel') {
    if (!Number(d[key])) return true
    const isIn = d[key] >= s[key]['min-value'] && d[key] <= s[key]['max-value']
    if (!isIn) return true
    if (!division(d[key], s[key].division)) return true
  }

  return false
}
