
interface Iobject {
  [key: string]: any
}

export default {
  name: 'CustomInput',
  props: {
    parent: {
      required: true,
    },
    item: {
      required: true,
    },
    disabled: {
      default: false,
    },
    type: {
      default: 'text',
    },
    novalid: {
      default: false,
    },
    placeholder: {
      default: '',
    },
    scheme: {
      required: true,
    },
    id: {
      default: false,
    },
  },
  data() {
    return {
      maskedFields: ['phone'],
      focusStatus: false,
      mouseover: false,
    }
  },
  methods: {
    getTitle(item: string): string {
      if (this.scheme[item]) return `${this.scheme[item].name}: ${this.parent[item] || 'нет'}`
      return this.parent[item]
    },
    getMask(item: string, parent: Iobject): string {
      if (item === 'phone') {
        const n = parent[item] ? parent[item].match(/\d/g) : ''
        const m = n ? n.length : 0
        if (m <= 11) return '+# (###) ###-##-###'
        if (m > 11) return '##################'
      }
      return 'X'.repeat(255)
    },
    clickDelete(e: Iobject): void {
      this.parent[this.item] = ''
      e.target.closest('.custom-input').querySelector('.--input').focus()
      this.$emit('clearInput')
    },
    focus(): void {
      this.focusStatus = true
    },
    blur(): void {
      this.focusStatus = false
      this.$emit('blur')
    },
  },
} as Iobject
