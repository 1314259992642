import { getNowSecods } from '@/utils'
import { validItem } from '@/conf/deals/validation'
import { loadData, defaultAmountSearch } from '@/conf/company/companyData'
import { tables } from '@/conf/tables'
import { getUnFlat } from '@/store/plugins/canban/functions'
import CrmApi from '@/services/crmApi'

const crmApi = new CrmApi()

interface Iobject {
    [key: string]: any
}

export default {
  data() {
    return {
      loadData,
      noSearchTimeout: null,
      noSearchTimer: 500,
    }
  },
  methods: {
    companyScheme(i: Iobject): Iobject {
      return { name: `${i.companyType} ${i.companyName}`.trim(), value: i.id, data: getUnFlat([i])[0] }
    },
    contactsScheme(i: Iobject, company: Iobject): Iobject {
      const c = company[i.company] ? `, ${company[i.company]}` : ''
      const name = `${i.firstName} ${i.middleName} ${i.lastName}`.trim()
      return { name: `${name}${c}`, value: i.id, data: getUnFlat([i])[0] }
    },
    async noSearchRequest(item: string, choose: string, whereAdd: Iobject): Promise<void> {
      if (this.noSearchTimeout) clearTimeout(this.noSearchTimeout)
      this.noSearchTimeout = setTimeout(() => {
        const ld = this.loadData
        let table: string | null = null
        let noSearchFields: any | null = null
        Object.keys(ld).forEach((key: string) => {
          if ([ld[key].scheme, ld[key].table].includes(item)) {
            table = ld[key].table
            noSearchFields = ld[key].noSearchFields
          }
        })
        if (!table || !noSearchFields || choose.length < 3) return

        this.$store.dispatch('app/setLoader', { data: true })
        const request: Iobject = { 
          datetime: getNowSecods(), 
          callbacks: noSearchFields.map((n: string) => ({
            table, nameId: n, id: `'%${choose}%'`, compare: 'LIKE', 
          })),
        }
        console.log('request', request)
        const found = new Set()
        crmApi.sendAndGetData(request).then((result: Iobject) => {
          try {
            console.log('result', result)
            const companies: any = []
            result.data.forEach((d: Iobject) => {
              if (!table) return
              d[table].forEach((i: Iobject) => { 
                found.add(i)
                if (table === tables.contacts) companies.push(i.company)
              })
            })
            console.log('companies', companies)
            console.log('found', found)
            if (table === tables.company) {
              found.forEach((i: any) => { whereAdd.push(this.companyScheme(i)) })
            }
            if (table === tables.contacts) {
              if (companies.length > 0) {
                return this.getCompanyContacts(tables.company, {}, companies, 0)
              } 
              found.forEach((i: any) => { whereAdd.push(this.contactsScheme(i, {})) })
            }
            return false
          } catch (error) { throw new Error(JSON.stringify(error)) }
        }).then((companyArr: Iobject) => {
          console.log('companyArr', companyArr)
          if (!companyArr) return
          const company: any = {}
          companyArr.data.forEach((i: Iobject) => { company[i.value] = i.name })
          found.forEach((i: any) => { whereAdd.push(this.contactsScheme(i, company)) })
        }).catch((error: Error) => {
          console.warn('error noSearch', error)
        })
          .then(() => {
            this.$store.dispatch('app/setLoader', { data: false })
          })
      }, this.noSearchTimer)
    },
    async getCompanyContacts(
      table: string, company: Iobject, addedIds: number[], last = defaultAmountSearch,
    ): Promise<Iobject> {
      const filterAddedIds = addedIds.filter((d) => typeof d === 'number')

      const request: Iobject = { 
        datetime: getNowSecods(), 
        callbacks: [],
      }
      if (last > 0) {
        request.callbacks.push({
          table, order: 'id', desc: 'desc', limit: last, 
        }) 
      }
      if (filterAddedIds.length > 0) {
        request.callbacks.push({
          table, nameId: 'id', id: `(${filterAddedIds})`, compare: 'IN', 
        }) 
      }
      console.log('request', request)
      return crmApi.sendAndGetData(request).then((result: Iobject) => {
        try {
          console.log('result', result)
          const r: any = []
          const companiesOfConracts: any = new Set()
          const ids: Iobject = {}
          result.data.forEach((d: Iobject) => {
            d[table].forEach((i: Iobject) => {
              if (ids[i.id]) return
              ids[i.id] = true
              if (table === tables.company) r.push(this.companyScheme(i))
              if (table === tables.contacts) {
                r.push(this.contactsScheme(i, company))
                companiesOfConracts.add(i.company)
              }
            })
          })
          return { data: r, companiesOfConracts: Array.from(companiesOfConracts), result }
        } catch (error) { throw new Error(JSON.stringify(error)) }
      }).catch((error: string) => {
        throw new Error(error)
      })
    },
    async loadCompanies(item: string, addedIds: Iobject = {}): Promise<Iobject> {
      console.log('addedIds', addedIds)
      if (!this.loadData[item]) return {}
      const res: Iobject = {}
      const f = this.loadData[item]

      this.$store.dispatch('app/setLoader', { data: true })
      const { table } = this.loadData['deal.company']
      const aIds = addedIds ? addedIds['deal.company'] : []
      return this.getCompanyContacts(table, {}, aIds).then((result: Iobject) => {
        res[this.loadData['deal.company'].scheme] = result.data
      }).catch((error: Error) => {
        console.warn('error ', f.scheme, f.table, error)
      })
        .then(() => {
          this.$store.dispatch('app/setLoader', { data: false })
          return res
        })
    },
    async loadCompanyContacts(item: string, addedIds: number[], lastNumber = 0): Promise<Iobject> {
      console.log('addedIds', addedIds)
      if (!this.loadData[item]) return {}

      const { table } = this.loadData[item]
      return this.getCompanyContacts(table, {}, addedIds, lastNumber)
        .then((result: Iobject) => result)
        .catch((error: any) => { throw new Error(error) })
    },
    validation(d: Iobject, valid: Iobject, allInvalid: boolean): void {
      Object.keys(d).forEach((key: string) => {
        if (Array.isArray(d[key])) {
          if (this.scheme[key].saveValidation) {
            if (d[key].length > 0) {
              d[key].forEach((a: Iobject) => {
                this.validation(a, valid, allInvalid)
              })
            } else valid.status = false
          } else {
            d[key].forEach((a: Iobject) => {
              this.validation(a, valid, allInvalid)
            })
          }
        } else {
          const r = this.scheme[key] ? d[key] : true
          const sv = this.scheme[key] ? this.scheme[key].saveValidation : false
          d[key] = validItem(d, this.scheme, key, allInvalid)
          if (sv === true && (d[key] === true || (r === null && allInvalid === false))) {
            if (valid.status !== false) valid.status = false
          }
        }
      })
    },
  },
} as Iobject
